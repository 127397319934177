<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-material-card>
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          Edit Profile
        </div>

        <div class="text-subtitle-1 font-weight-light">
          Complete your profile
        </div>
      </template>

      <v-form>
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="firstName"
                :rules="[rules.required]"
                label="First Name"
                class="purple-input"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="lastName"
                :rules="[rules.required]"
                label="Last Name"
                class="purple-input"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="Email Address"
                class="purple-input"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="currentPassword.value"
                :append-icon="currentPassword.show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="currentPassword.show ? 'text' : 'password'"
                class="purple-input"
                label="Current password"
                hint="At least 8 characters"
                counter
                @click:append="currentPassword.show = !currentPassword.show"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newPassword.value"
                :append-icon="newPassword.show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="newPassword.show ? 'text' : 'password'"
                class="purple-input"
                label="New password"
                hint="At least 8 characters"
                counter
                @click:append="newPassword.show = !newPassword.show"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newPasswordRepeat.value"
                :append-icon="newPasswordRepeat.show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, rules.passwordsEqual]"
                :type="newPasswordRepeat.show ? 'text' : 'password'"
                class="purple-input"
                label="New password Repeat"
                hint="At least 8 characters"
                counter
                @click:append="newPasswordRepeat.show = !newPasswordRepeat.show"
              />
            </v-col>

            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="success"
                class="mr-0"
              >
                Update Profile
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        firstName: '',
        lastName: '',
        email: '',
        currentPassword: {
          value: '',
          show: false,
        },
        newPassword: {
          value: '',
          show: false,
        },
        newPasswordRepeat: {
          value: '',
          show: false,
        },
        rules: {
          required: value => !!value || 'Field is required',
          min: v => v.length >= 8 || 'Min 8 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail'
          },
          passwordsEqual: () => {
            return (this.newPassword.value === this.newPasswordRepeat.value) || 'Password must match'
          },
        },
      }
    },
  }
</script>
