import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import users from './modules/users'
import categories from './modules/categories'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

const store = new Vuex.Store({
  modules: {
    auth,
    users,
    categories,
  },
  state: {
    barColor: '#1c1f23',
    barImage: require('@/assets/images/backgrounds/sidebar-1.jpg'),
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {

  },
  getters: {

  },
  plugins: [vuexLocal.plugin],
})

export default store
// export default new Vuex.Store({
//   modules: {
//     auth,
//     users,
//   },
//   state: {
//     barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
//     barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
//     drawer: null,
//   },
//   mutations: {
//     SET_BAR_IMAGE (state, payload) {
//       state.barImage = payload
//     },
//     SET_DRAWER (state, payload) {
//       state.drawer = payload
//     },
//   },
//   actions: {

//   },
//   getters: {

//   },
// })
