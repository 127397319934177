<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-row>
      <v-col
        cols="4"
      >
        <v-card
          class="ma-0 overflow-x-auto"
        >
          <template>
            <v-treeview
              :active.sync.prop="selectedId"
              hoverable
              activatable
              dense
              color="grey"
              :items="items"
            />
          </template>
        </v-card>
      </v-col>
      <v-col
        cols="8"
      >
        <v-card class="py-5 px-8">
          <div
            v-if="selectedId"
            class="d-flex justify-space-between items-center"
          >
            <h2>Edit #{{ activeItem.id }}</h2>
            <v-btn
              color="red"
              @click="deleteCategory"
            >
              Delete
            </v-btn>
          </div>
          <v-text-field
            v-model="form.name"
            label="Name"
            type="text"
          />
          <v-text-field
            v-model="form.urlKey"
            label="Url Key"
            type="text"
          />
          <v-select
            v-model="form.parentId"
            :items="getItemsList()"
            searchable
            label="Parent Category"
            type="text"
          />
          <div>
            <v-btn
              color="teal lighten-2"
              class="ma-0"
              @click="uploadImage"
            >
              <v-icon
                left
                color="#fff"
              >
                mdi-plus
              </v-icon>
              Upload a new image
            </v-btn>
            <input
              ref="fileInput"
              type="file"
              style="display: none"
              @change="onFileSelected"
            >
            <v-img
              :lazy-src="selectedFileUrl ? selectedFileUrl : 'https://picsum.photos/id/11/500/300'"
              max-height="300"
              max-width="300"
              :src="selectedFileUrl ? selectedFileUrl : 'https://picsum.photos/id/11/500/300'"
              class="mt-3"
            />
          </div>

          <div class="mt-4 d-flex justify-end items-center">
            <v-btn
              color="primary"
              @click="saveCategory"
            >
              Save
            </v-btn>
            <v-btn
              @click="formReset"
            >
              Cancel
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showAlertMessage"
      :color="alertColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'

  export default {
    data: () => ({
      selectedId: null,
      activeItem: {},
      items: [],
      form: {
        id: null,
        parentId: null,
        name: '',
        urlKey: '',
        image: '',
      },

      selectedFileUrl: null,

      showAlertMessage: false,
      message: '',
      alertColor: 'success',
    }),
    watch: {
      selectedId (val) {
        if (val) {
          this.activeItem = this.selectedMenu(this.items, val[0])
          this.form = this.activeItem
        } else {
          this.activeItem = {}
          this.form = {
            id: null,
            parentId: null,
            name: '',
            urlKey: '',
            image: '',
          }
        }
      },
      'form.image': function (newVal) {
        if (newVal instanceof File) {
          this.selectedFileUrl = URL.createObjectURL(newVal)
        } else {
          this.selectedFileUrl = newVal
        }
      },
    },
    created () {
      this.loadCategories()
    },
    methods: {
      selectedMenu (items, id) {
        for (const item of items) {
          if (item.id === id) {
            return { ...item }
          }
          if (item.children) {
            const result = this.selectedMenu(item.children, id)
            if (result) {
              return result
            }
          }
        }
        return null
      },
      getItemsList () {
        const itemsList = []
        const getItems = (items) => {
          for (const item of items) {
            itemsList.push({ text: item.name, value: item.id })
            if (item.children) {
              getItems(item.children)
            }
          }
        }
        getItems(this.items)
        return itemsList
      },
      formReset () {
        this.selectedId = null
        this.form = {
          id: null,
          parentId: null,
          name: '',
          urlKey: '',
          image: '',
        }
      },
      uploadImage () {
        this.$refs.fileInput.click()
      },
      onFileSelected (e) {
        this.form.image = e.target.files[0]
      },
      loadCategories () {
        const _this = this
        axios.get('/admin/categories')
          .then((response) => {
            _this.items = response.data
          }).catch((err) => {
            _this.message = err
            _this.alertColor = 'error'
            _this.showAlertMessage = true
          })
      },
      saveCategory () {
        const formData = new FormData()
        formData.append('id', this.form.id)
        formData.append('name', this.form.name)
        formData.append('urlKey', this.form.urlKey)
        formData.append('parentId', this.form.parentId)
        if (this.form.image instanceof File) {
          formData.append('image', this.form.image)
        } else {
          formData.append('image', null)
        }

        axios.post('/admin/categories', formData, {
          enctype: 'multipart/form-data',
        })
          .then((response) => {
            if (response.status === 201) {
              this.message = 'Category saved successfully'
              this.alertColor = 'success'
              this.showAlertMessage = true
            }
            this.loadCategories()
            this.formReset()
          }).catch((err) => {
            this.message = err
            this.alertColor = 'error'
            this.showAlertMessage = true
          })
      },
      deleteCategory () {
        if (!confirm('Are you sure you want to delete this category?')) return

        axios.get(`/admin/categories/${this.form.id}/delete`)
          .then((response) => {
            if (response.status === 204) {
              this.message = 'Category deleted successfully'
              this.alertColor = 'success'
              this.showAlertMessage = true
            }
            this.loadCategories()
            this.formReset()
          }).catch((err) => {
            this.message = err
            this.alertColor = 'error'
            this.showAlertMessage = true
          })
      },
    },
  }
</script>

<style lang="scss">
</style>
