<template>
  <div>
    <div class="row item-details-page">
      <div class="col-md-8">
        <div class="input-wrap">
          <v-subheader class="input-label">
            Fixed Price
          </v-subheader>
          <v-text-field
            v-model="object.price.fixPrice"
            type="number"
            single-line
            outlined
            dense
            hide-details
          />
        </div>
      </div>
    </div>
    <h4 class="text-h4 px-4 py-5">
      Variable Price
    </h4>
    <div class="row item-details-page">
      <div class="col-md-8">
        <div class="input-wrap">
          <v-subheader class="input-label">
            Variable Price
          </v-subheader>
          <v-text-field
            v-model="object.price.variablePrice"
            type="number"
            single-line
            outlined
            dense
            hide-details
          />
        </div>
      </div>
    </div>
    <div class="input-wrap">
      <v-subheader class="input-label">
        Renting Days Min
      </v-subheader>
      <v-text-field
        v-model="object.price.rentingDayMin"
        type="number"
        single-line
        readonly
        outlined
        dense
        hide-details
      />
    </div>
    <div class="input-wrap">
      <v-subheader class="input-label">
        Renting Days Max
      </v-subheader>
      <v-text-field
        v-model="object.price.rentingDayMax"
        type="number"
        single-line
        readonly
        outlined
        dense
        hide-details
      />
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'

  export default {
    mixins: [validationMixin],
    props: {
      objectData: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        object: {
          price: {
            fixPrice: 0,
            variablePrice: 0,
            rentingDayMin: 0,
            rentingDayMax: 0,
          },
        },
      }
    },
    created () {
      if (this.objectData) {
        this.object = this.objectData
      }
    },
  }
</script>
