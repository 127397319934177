<template>
  <div class="row">
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Message
        </v-subheader>
        <v-textarea
          outlined
          hide-details
        />
        <div class="text-caption">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </div>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          color="teal lighten-2"
          class="ma-0"
        >
          Send
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="sass">

</style>
