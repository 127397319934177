<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :mini-variant.sync="mini"
    :mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="0"
    app
    width="265"
    v-bind="$attrs"
  >
    <v-list
      class="drawer-header"
      dense
      nav
    >
      <v-list-item
        class="drawer-nav"
      >
        <img
          src="@/assets/logo-aside.svg"
          alt="Sharely logo"
          class="drawer-logo"
        >
        <v-btn
          class="transparent primary--text expand-button x-small"
          elevation="0"
          small
          @click="mini = !mini"
        >
          <!-- <v-icon>
            mdi-view-quilt
          </v-icon> -->

          <v-icon>
            mdi-chevron-double-left
          </v-icon>
        </v-btn>
      </v-list-item>
    </v-list>

    <div
      v-if="this.$store.state.auth.isAuthenticated"
      class="drawer-menu"
    >
      <template v-for="item in items">
        <v-list-group
          v-if="!item.to"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template
            v-for="subItem in item.items"
          >
            <v-list-group
              v-if="!subItem.to"
              :key="subItem.title"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subItem.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="subSubItem in subItem.items"
                :key="subSubItem.title"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link :to="subSubItem.to">
                      {{ subSubItem.title }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="subItem.title"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="subItem.to">
                    {{ subItem.title }}
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-item
          v-else
          :key="item.title"
          :class="item.to === $route.path ? 'v-list-item--active' : ''"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <router-link :to="item.to">
                {{ item.title }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
    },

    data: () => ({
      openedMenu: null,
      expandOnHover: false,
      items: [
        {
          title: 'Cockpit',
          to: '/',
          action: 'mdi-monitor-dashboard',
        },
        {
          title: 'User',
          active: false,
          action: 'mdi-account',
          items: [
            {
              title: 'Users',
              to: '/users/user-accounts',
            },
            {
              title: 'Risky Leasers',
              to: '/users/risky-leasers',
            },
            {
              title: 'Subscribers',
              to: '/users/user-subscribers',
            },
            {
              title: 'User Ratings',
              to: '/users/user-ratings',
            },
            {
              title: 'User Export',
              to: '/users/user-export',
            },
            {
              title: 'Undefined Addresses',
              to: '/users/undefined-addresses',
            },
          ],
        },
        {
          title: 'Object',
          active: false,
          action: 'mdi-view-list',
          items: [
            {
              title: 'Objects',
              to: '/objects',
            },
            {
              title: 'Object Keywords',
              to: '/object-keywords',
            },
            {
              title: 'Object Ratings',
              to: '/object-ratings',
            },
            {
              title: 'Type Settings',
              to: '/type-settings',
            },
            {
              title: 'Object Types',
              to: '/object-types',
            },
            {
              title: 'Object Favourites',
              to: '/object-favourites',
            },
            {
              title: 'Collections',
              to: '/collections',
            },
          ],
        },
        {
          title: 'Requests',
          active: false,
          action: 'mdi-cart-outline',
          items: [
            {
              title: 'Rentals',
              to: '/rentals',
            },
            {
              title: 'Requests',
              to: '/requests',
            },
          ],
        },
        {
          title: 'Conversations',
          active: false,
          action: 'mdi-forum-outline',
          items: [
            {
              title: 'Conversations',
              to: '/conversations',
            },
          ],
        },
        {
          title: 'Financials',
          active: false,
          action: 'mdi-finance',
          items: [
            {
              title: 'Financials',
              to: '/financials',
            },
            {
              title: 'Credit Payments',
              active: false,
              items: [
                {
                  title: 'Credit Payment Upload',
                  to: '/credit-payment-upload',
                },
                {
                  title: 'Credit Payment Transactions',
                  to: '/credit-payment-transactions',
                },
                {
                  title: 'Declined Credit Payments',
                  to: '/declined-credit-payments',
                },
                {
                  title: 'Reconcile Credit Cancellations',
                  to: '/reconcile-credit-cancellations',
                },
                {
                  title: 'Unmatched Credit Payments',
                  to: '/unmatched-credit-payments',
                },
              ],
            },
            {
              title: 'Debit Payments',
              active: false,
              items: [
                {
                  title: 'Debit Payment Export',
                  to: '/debit-payment-export',
                },
                {
                  title: 'Debit Payment Export Transactions',
                  to: '/debit-payment-export-transactions',
                },
                {
                  title: 'Debit Payment Transactions',
                  to: '/debit-payment-transactions',
                },
                {
                  title: 'Debit Payment Missing IBAN',
                  to: '/debit-payment-missing-IBAN',
                },
              ],
            },
          ],
        },
        {
          title: 'Marketing',
          active: false,
          action: 'mdi-shopping-outline',
          items: [
            {
              title: 'Discounts',
              to: '/discounts',
            },
          ],
        },
        {
          title: 'CMS',
          active: false,
          action: 'mdi-post-outline',
          items: [
            {
              title: 'Post',
              active: false,
              items: [
                {
                  title: 'Blog Posts',
                  to: '/blog-posts',
                },
                {
                  title: 'Post Categories',
                  to: '/blog-categories',
                },
                {
                  title: 'Post Comments',
                  to: '/blog-comments',
                },
              ],
            },
            {
              title: 'News',
              to: '/news',
            },
          ],
        },
        {
          title: 'Administration',
          active: false,
          action: 'mdi-cog-outline',
          items: [
            {
              title: 'Notifications',
              to: 'components/notifications',
            },
            {
              title: 'Categories',
              to: '/categories',
            },
            {
              title: 'Settings',
              to: '/settings',
            },
          ],
        },
      ],
      mini: false,
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
      breakpoint () { return this.$vuetify.breakpoint.name },
    },

    watch: {
      breakpoint () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': { this.mini = true; break }
          case 'sm': { this.mini = true; break }
          case 'md': { this.mini = true; break }
          case 'lg': { this.mini = false; break }
          case 'xl': { this.mini = false; break }
          default: { this.mini = false; break }
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
