<template>
  <div>Section under development</div>
</template>

<script>
  export default {
    props: {
      object: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="sass">

</style>
