<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Simple Tables"
      link="components/simple-tables"
    />

    <base-material-card
      icon="mdi-clipboard-text"
      title="Data Table"
      class="px-5 py-3"
    >
      <v-data-table
        v-if="tableData"
        :headers="tableHeaders"
        :items="tableData"
        item-key="id"
        class="elevation-1"
      >
        <template
          v-slot:item="{ headers, item }"
        >
          <tr>
            <td
              v-for="i in headers"
              :key="i.value"
              class="popup-cell"
            >
              <v-tooltip
                right
                :offset-overflow="true"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="popup-toggler"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item[i.text] }}
                  </span>
                </template>
                {{ item.title }}
              </v-tooltip>
              <!-- <span class="table-popup">
                {{ item.title }}
              </span> -->
            </td>
          </tr>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'

  export default {
    data () {
      return {
        expanded: [],
        tableData: null,
        expandedColumn: '',
      }
    },
    computed: {
      tableHeaders () {
        return Object.keys(this.tableData[0]).map(el => ({ text: el, value: el })).filter(el => el.text !== 'title')
      },
    },
    mounted () {
      axios.get('https://jsonplaceholder.typicode.com/users/1/posts')
        .then(data => {
          this.tableData = data.data
        })
    },
    methods: {
      expandRow (item, content) {
        this.expanded[0] = item
        this.expandedColumn = content
      },
    },
  }
</script>

<style lang="scss">
.popup-cell {
  position: relative;
  padding: 0 !important;

  &:hover .table-popup {
    display: block;
  }
}
.popup-toggler {
  padding: 0 16px;
}
.table-popup {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  border-radius: 5px;
  background: rgba(0,0,0,.8);
  color: #fff;
  z-index: 9;
  padding: 10px;
  width: 350px;
  max-height: 200px;
  overflow: auto;
}
.v-tooltip__content {
  pointer-events: auto !important;

  &:hover {
    display: block !important;
    opacity: 1 !important;
  }
}
</style>
