<template>
  <div
    class="row user-data-tab"
  >
    <div class="col-md-6">
      <h4 class="text-h4 px-4 py-5">
        Information about User
      </h4>
      <div class="input-wrap">
        <v-subheader class="input-label">
          User ID
        </v-subheader>
        <v-text-field
          v-model="userId"
          type="number"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Title
        </v-subheader>
        <v-text-field
          v-model="gender"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div
        class="input-wrap"
        :class="{ 'input-error': $v.firstname.$invalid }"
      >
        <v-subheader class="input-label">
          First Name
        </v-subheader>
        <v-text-field
          v-model="firstname"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('firstname')"
        />
        <div
          v-if="$v.firstname.$invalid"
          class="input-error-container"
        >
          <p
            v-if="!$v.firstname.required"
            class="error-message"
          >
            This field is required
          </p>
        </div>
      </div>
      <div
        class="input-wrap"
        :class="{ 'input-error': $v.lastname.$invalid }"
      >
        <v-subheader class="input-label">
          Last Name
        </v-subheader>
        <v-text-field
          v-model="lastname"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('lastname')"
        />
        <div
          v-if="$v.lastname.$invalid"
          class="input-error-container"
        >
          <p
            v-if="!$v.lastname.required"
            class="error-message"
          >
            This field is required
          </p>
        </div>
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Company / Additional
        </v-subheader>
        <v-text-field
          v-model="company"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('company')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Role
        </v-subheader>
        <v-select
          v-model="role"
          :items="[{ text: 'User', value: 'ROLE_USER' }, { text: 'Administrator', value: 'ROLE_ADMIN' }, { text: 'SuperAdmin', value: 'ROLE_SUPER_ADMIN' }]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          @change="emitChanges('role')"
        />
      </div>
      <h4 class="text-h4 px-4 py-5">
        User Contacts
      </h4>
      <div class="input-wrap">
        <v-subheader class="input-label">
          E-mail
        </v-subheader>
        <v-text-field
          v-model="email"
          type="email"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div
        class="input-wrap"
        :class="{ 'input-error': $v.phone.$invalid }"
      >
        <v-subheader class="input-label">
          Phone
        </v-subheader>
        <div class="icon-input-wrap">
          <phone-input
            v-model="phone"
            :size="`lg`"
            :default-country-code="selectedCountry"
            :only-countries="preferredCountries"
            :options="{ showDialCode: true }"
            :border-radius="8"
            :no-example="true"
            :no-flags="true"
            :color="`#ee7d6e`"
            @update="updatePhone($event)"
          />
          <span class="input-icon-wrap input-icon-left input-icon-wrap-lg">
            <span
              class="phone-flag"
              :class="phoneParams ? `country-${phoneParams.country}` : null"
            />
          </span>
          <p class="form-item-note">
            Allowed formats:
            <br>
            Australia: <span class="word-nowrap">+61 4XX XXX XXX</span> and <span class="word-nowrap">04XX XXX XXX</span>
            <br>
            Hungary: <span class="word-nowrap">+36 XX XXX XXXX</span> and <span class="word-nowrap">06 XX XXX XXXX</span>
            <br>
            Switzerland: <span class="word-nowrap">+41 XX XXX XX XX</span> and <span class="word-nowrap">07XX XXX XXX</span>
          </p>
        </div>
        <div
          v-if="$v.phone.$invalid"
          class="input-error-container"
        >
          <p
            v-if="!$v.phone.required"
            class="error-message"
          >
            This field is required
          </p>
          <p
            v-if="!$v.phone.phoneFormat"
            class="error-message"
          >
            Phone format is invalid
          </p>
        </div>
      </div>
      <h4 class="text-h4 px-4 py-5">
        User Address
      </h4>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Street
        </v-subheader>
        <v-text-field
          v-model="street"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('street')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Street Number
        </v-subheader>
        <v-text-field
          v-model="number"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('number')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Town/City
        </v-subheader>
        <v-text-field
          v-model="city"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('city')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Zip Code
        </v-subheader>
        <v-text-field
          v-model="zip"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('zip')"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-md-12 row px-0">
        <div class="col-md-6">
          <div class="input-wrap">
            <v-subheader class="input-label">
              User Status
            </v-subheader>
            <v-select
              v-model="status"
              :items="['unconfirmed', 'active', 'idle', 'blocked', { text: 'disabled', value: 'deleted' }]"
              outlined
              dense
              hide-details
              @change="emitChanges('status')"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-wrap">
            <v-subheader class="input-label">
              KYC Status
            </v-subheader>
            <v-select
              v-model="identityVerificationStatus"
              :items="['Not started', 'Pending', 'Verified', 'Failed']"
              outlined
              dense
              hide-details
              @change="emitChanges('identityVerificationStatus')"
            />
          </div>
        </div>
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Disabled
        </v-subheader>
        <v-text-field
          v-model="disabled"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          User Flag
        </v-subheader>
        <v-select
          v-model="flag"
          :items="[{ text: 'Incentive Accepted', value: 'incentiveAccepted' }, { text: 'Incentive Conversion', value: 'incentiveConversion' }, { text: 'None', value: 'none' }]"
          outlined
          dense
          hide-details
          @change="emitChanges('flag')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Idle Count
        </v-subheader>
        <v-text-field
          v-model="idleCount"
          type="number"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Last Idle
        </v-subheader>
        <v-text-field
          v-model="idleLastCheck"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          E-mail Invalid
        </v-subheader>
        <v-select
          v-model="isEmailValid"
          :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          @change="emitChanges('isEmailValid')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          User Identified
        </v-subheader>
        <v-select
          v-model="identified"
          :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          @change="emitChanges('identified')"
        />
      </div>
      <h4 class="text-h4 px-4 py-5">
        Other
      </h4>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Created
        </v-subheader>
        <v-text-field
          v-model="created"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Last Login
        </v-subheader>
        <v-text-field
          v-model="lastLogin"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Paused Period
        </v-subheader>
        <v-text-field
          v-model="pausePeriod"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          MwSt Flag
        </v-subheader>
        <v-select
          v-model="vatSubject"
          :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          @change="emitChanges('vatSubject')"
        />
      </div>
      <div
        v-if="vatSubject"
        class="input-wrap"
      >
        <v-subheader class="input-label">
          MwSt Identifier
        </v-subheader>
        <v-text-field
          v-model="vat"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('vat')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Newsletter
        </v-subheader>
        <v-select
          v-model="newsletter"
          :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          @change="emitChanges('newsletter')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Promo Mails
        </v-subheader>
        <v-select
          v-model="subscribeEmail"
          :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          @change="emitChanges('subscribeEmail')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Insurance Factor
        </v-subheader>
        <v-text-field
          v-model="personalInsurance"
          type="number"
          min="0"
          max="1"
          step="0.01"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('personalInsurance')"
        />
      </div>
      <div
        v-if="idDocuments1 || idDocuments2"
        class="input-wrap"
      >
        <v-subheader class="input-label">
          ID Documents
        </v-subheader>
        <div class="documents-wrap">
          <a
            v-if="idDocuments1"
            :href="idDocuments1"
            target="_blank"
          >Passport front side</a>
          <a
            v-if="idDocuments2"
            :href="idDocuments2"
            target="_blank"
          >Passport back side</a>
        </div>
      </div>
      <h4 class="text-h4 px-4 py-5">
        App Use
      </h4>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Installed App OS
        </v-subheader>
        <v-text-field
          v-model="installedAppOs"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Installed App Version
        </v-subheader>
        <v-text-field
          v-model="installedAppVersion"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Installed App Build
        </v-subheader>
        <v-text-field
          v-model="installedAppBuild"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div
        v-if="installedAppOs"
        class="input-wrap"
      >
        <v-subheader class="input-label">
          Push Notifications Enabled
        </v-subheader>
        <v-select
          v-model="pushNotificationsEnabled"
          readonly
          :items="[{ text: 'Yes', value: true }, { text: 'No', value: false }]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

  // const phoneRegex = /^(\+36\d{9}|06\d{9}|\+614\d{8}|04\d{8}|\+41\d{9}|07\d{8})$/
  // const phoneFormat = (value) => phoneRegex.test(value.replaceAll(' ', ''))

  export default {
    mixins: [validationMixin],
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        userId: 0,
        gender: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        unformattedPhone: '',
        street: '',
        number: '',
        city: '',
        zip: '',
        created: '',
        lastLogin: '',
        disabled: '',
        vatSubject: 0,
        vat: '',
        status: null,
        identityVerificationStatus: null,
        flag: '',
        idleCount: 0,
        role: null,
        newsletter: 0,
        subscribeEmail: null,
        pausePeriod: '',
        company: '',
        personalInsurance: 1,
        idDocuments1: '',
        idDocuments2: '',
        isEmailValid: 0,
        identified: 0,
        installedAppOs: '',
        installedAppVersion: '',
        installedAppBuild: '',
        pushNotificationsEnabled: 0,
        idleLastCheck: '',
        preferredCountries: ['HU', 'AU', 'CH'],
      }
    },
    computed: {
      selectedCountry () {
        if (this.unformattedPhone.startsWith('+36') || this.unformattedPhone.startsWith('06')) return 'HU'
        if (this.unformattedPhone.startsWith('+61') || this.unformattedPhone.startsWith('04')) return 'AU'
        if (this.unformattedPhone.startsWith('+41') || this.unformattedPhone.startsWith('07')) return 'CH'
        return 'HU'
      },

      /* eslint-disable */
    phoneParams() {
      if (this.phone.startsWith('+36')) {
        return {
          country: 'hungary',
          mask: '+36 ## ### ####',
        };
      } else if (this.phone.startsWith('06')) {
        return {
          country: 'hungary',
          mask: '06 ## ### ####',
        };
      } else if (this.phone.startsWith('+61')) {
        return {
          country: 'australia',
          mask: '+61 4## ### ###',
        };
      } else if (this.phone.startsWith('04')) {
        return {
          country: 'australia',
          mask: '04## ### ###',
        };
      } else if (this.phone.startsWith('+41')) {
        return {
          country: 'switzerland',
          mask: '+41 ## ### ## ##',
        };
      } else if (this.phone.startsWith('07')) {
        return {
          country: 'switzerland',
          mask: '07## ### ###',
        };
      }
      else {
        return {
          mask: undefined,
          country: 'default',
        };
      }
    }
    /* eslint-enable */
    },
    validations () {
      return {
        phone: { required },
        firstname: { required },
        lastname: { required },
      }
    },
    created () {
      if (this.user) {
        this.userId = this.user.id || ''
        this.gender = this.user.gender || ''
        this.firstname = this.user.firstname || ''
        this.lastname = this.user.lastname || ''
        this.email = this.user.email || ''
        this.phone = this.user.phone || ''
        this.unformattedPhone = this.user.phone || ''
        if (this.phone.startsWith('36')) this.phone = this.phone.replace('36', '+36')
        else if (this.phone.startsWith('61')) this.phone = this.phone.replace('61', '+61')
        this.street = this.user.addressUser?.street || ''
        this.number = this.user.addressUser?.number || ''
        this.city = this.user.addressUser?.city || ''
        this.zip = this.user.addressUser?.zip || ''
        this.created = this.$options.filters.dateFormat(this.user.createdAt) || ''
        this.lastLogin = this.$options.filters.dateFormat(this.user.lastLogin) || ''
        this.disabled = this.$options.filters.dateFormat(this.user.deletedAt) || ''
        this.vat = this.user.vat || null
        this.vatSubject = this.user.vat ? 1 : 0
        this.status = this.user.status || null
        this.identityVerificationStatus = this.user.identityVerificationStatus || null
        this.flag = this.user.flag || 'none'
        this.idleCount = this.user.idleCount || 0
        this.role = typeof this.user.roles === 'string' ? this.user.roles : this.user.roles[0]
        this.subscribeEmail = this.user.subscriber?.emailSubscriber ? 1 : 0
        this.pausePeriod = this.user.userPaused.dateFrom ? `${this.$options.filters.dateFormatOnly(this.user.userPaused.dateFrom)} - ${this.$options.filters.dateFormatOnly(this.user.userPaused.dateTo)}` : ''
        this.company = this.user.company || ''
        this.personalInsurance = this.user.personalInsurance || 1
        this.idleLastCheck = this.$options.filters.dateFormat(this.user.idleLastCheck) || ''
        this.idDocuments1 = this.user.passport?.passportFrontSide || null
        this.idDocuments2 = this.user.passport?.passportBackSide || null
        this.identified = this.user.identified ? 1 : 0
        this.installedAppOs = this.user.userDevice?.deviceType
        this.installedAppVersion = this.user.userDevice?.appVersion
        this.installedAppBuild = this.user.userDevice?.appBuild
        this.pushNotificationsEnabled = this.user.userDevice?.pushEnabled
        this.isEmailValid = this.user.isEmailValid ? 1 : 0

        this.emitChanges('subscribeEmail')
      }
    },
    methods: {
      emitChanges (field) {
        const data = {
          tabName: 'user',
          data: {},
        }
        if (this.$v[field] && this.$v[field].$invalid) {
          data.remove = field
        } else {
          if (field === 'phone') {
            data.data[field] = this[field].replaceAll(' ', '')
          } else if (field === 'zip' || field === 'city' || field === 'street' || field === 'number') {
            data.data.addressUser = {}
            data.data.addressUser[field] = this[field]
          } else {
            data.data[field] = this[field]
          }
        }
        this.$emit('emitChanges', data)
      },
      updatePhone (data) {
        this.phone = data.formattedNumber
        this.emitChanges('phone')
      },
    },
  }
</script>
