<template>
  <v-container
    fluid
    class="pa-0 item-details-page"
  >
    <div class="pt-6 pb-4 px-6">
      <div class="row">
        <div class="col-md-6">
          <div class="input-wrap">
            <v-subheader class="input-label">
              Title
            </v-subheader>
            <v-text-field
              v-model="post.title"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="input-wrap">
            <v-subheader class="input-label">
              slug
            </v-subheader>
            <v-text-field
              v-model="post.slug"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="input-wrap">
            <v-subheader class="input-label">
              Teaser text
            </v-subheader>
            <v-text-field
              v-model="post.teaserText"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div
            v-if="post.authorId"
            class="input-wrap"
          >
            <v-subheader class="input-label">
              Author
            </v-subheader>
            <v-text-field
              v-model="post.author"
              single-line
              outlined
              dense
              hide-details
              disabled
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-wrap">
            <v-subheader class="input-label">
              Publish At
            </v-subheader>
            <date-picker
              v-model="post.publishedAt"
              type="datetime"
              value-type="timestamp"
            />
          </div>
          <div class="input-wrap">
            <v-subheader class="input-label">
              status
            </v-subheader>
            <v-select
              v-model="post.status"
              :items="postStatuses"
              item-text="name"
              item-value="value"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="input-wrap">
            <v-subheader class="input-label">
              Categories
            </v-subheader>
            <v-select
              v-model="post.categories"
              :items="categories"
              chips
              item-text="name"
              item-value="id"
              label="Select Categories"
              multiple
              outlined
              solo
              dense
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="input-wrap">
            <v-subheader class="input-label">
              Content
            </v-subheader>
            <editor
              v-model="post.content"
              style="width: 100%;"
              api-key="aepzykghwcl2b4jyauuzppgd0qo6673ki54oumo8ovg7hpjs"
              :init="{
                height: 500,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'

  import Editor from '@tinymce/tinymce-vue'
  import swal from 'sweetalert2'

  export default {
    name: 'DetailsTab',
    components: {
      Editor,
      DatePicker,
    },
    props: {
      postData: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        post: {
          id: null,
          title: '',
          slug: '',
          content: '',
          teaserText: '',
          authorId: null,
          publishDate: '',
          status: 0,
          categories: [],
          images: [],
          selectedImages: [],
          error: null,
        },
        categories: [], // This is used for the available categories, the post.categories is used for the selected categories
        postStatuses: [
          { name: 'Draft', value: 1 },
          { name: 'Inactive', value: 2 },
          { name: 'Active', value: 3 },
        ],
      }
    },
    watch: {
      'post.title' (val) {
        this.post.slug = val.trim().split(' ').join('-')
      },
      post: {
        deep: true,
        handler () {
          this.$emit('updatePost', this.post)
        },
      },
    },
    mounted () {
      this.post.id = this.$route.params.id
      this.loadCategories()
      if (this.post.id) {
        this.loadPostData()
      }
    },
    methods: {
      updateContent (content) {
        this.post.content = content
      },
      loadCategories () {
        const _this = this
        axios.get('/admin/blog/posts/categories/all').then((response) => {
          const categories = response.data

          _this.categories = categories.map((category) => {
            return {
              id: category.id,
              name: category.title,
            }
          })
        }).catch((error) => {
          this.post.error = error
        })
      },
      loadPostData () {
        axios.get('/admin/blog/posts/show/' + this.post.id).then((response) => {
          const post = response.data
          const status = this.postStatuses.find((status) => status.name === post.status)

          this.post = {
            id: post.id,
            title: post.title,
            slug: post.slug,
            content: post.content,
            teaserText: post.teaserText,
            authorId: post.authorId,
            publishedAt: new Date(post.publishedAt).getTime(),
            status: status.value,
            categories: post.categories,
            images: post.images,
            error: null,
          }
        }).catch((error) => {
          this.post.error = error
        })
      },
      save () {
        this.post.publishedAt = new Date(this.post.publishedAt).toISOString()
        if (this.post.id) {
          this.update()
        } else {
          this.create()
        }
      },
      update () {
        const _this = this
        axios.post('/admin/blog/posts/edit', {
          ...this.post,
        }).then(function (response) {
          swal.fire({
            title: 'Success',
            text: 'Post updated successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          })

          _this.loadPostData()
        }).catch(function (error) {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
      },
      create () {
        axios.post('/admin/blog/posts/create', {
          ...this.post,
        }).then(function (response) {
          swal.fire({
            title: 'Success',
            text: 'Post created successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          })
        }).catch(function (error) {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
      },
    },
  }
</script>
