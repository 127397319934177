import api from '@/plugins/api'

export default {
  state: {
    isAuthenticated: false,
    token: '',
    refreshToken: '',
    myData: {},
    expirationDate: 0,
    authFailedError: '',
  },
  mutations: {
    SET_TOKENS (state, newTokens) {
      state.token = newTokens.token
      state.refreshToken = newTokens.refresh_token
      state.expirationDate = new Date().getTime() + 2700000
      state.isAuthenticated = true
    },
    LOG_OUT (state) {
      state.token = ''
      state.refreshToken = ''
      state.expirationDate = 0
      state.isAuthenticated = false
      state.myData = {}
      state.authFailedError = ''
    },
    SET_USER_DATA (state, user) {
      state.myData = user
    },
    SET_AUTH_ERROR (state, error) {
      state.authFailedError = error
    },
  },
  actions: {
    async authenticate ({ commit, dispatch, state }, authData) {
      await api.post('login', authData)
        .then(res => {
          dispatch('fetchUserData', res.data.token)
            .then(() => {
              if ((typeof state.myData.roles === 'string' && state.myData.roles.includes('admin')) || state.myData.roles.some(role => role.toLowerCase().includes('admin'))) {
                commit('SET_TOKENS', res.data)
                dispatch('autoRefresh')
              } else {
                throw new Error('This user is not admin')
              }
            })
            .catch(err => {
              if (err.message === 'This user is not admin') {
                commit('SET_AUTH_ERROR', err)
              }
            })
        })
    },
    setToken ({ commit }, tokens) {
      commit('SET_TOKENS', tokens)
    },
    logout ({ commit }) {
      window.localStorage.clear()
      commit('LOG_OUT')
    },
    fetchUserData ({ commit, state }, token) {
      return api.get('user/me', {
        headers: {
          Authorization: `Bearer ${token || state.token}`,
        },
      })
        .then(res => {
          commit('SET_USER_DATA', res.data)
        })
    },
    autoRefresh ({ dispatch, state }) {
      const refreshTime = 2700000
      setTimeout(() => {
        if (state.refreshToken) {
          dispatch('refreshToken')
        }
      }, refreshTime)
    },
    refreshToken ({ commit, state, dispatch }) {
      api.post('login/refresh', { refreshToken: state.refreshToken })
        .then(res => {
          commit('SET_TOKENS', res.data)
          dispatch('autoRefresh')
        })
    },
  },
}
