<template>
  <div
    class="row item-details-page"
  >
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Year of Purchase
        </v-subheader>
        <v-text-field
          v-model="object.insuranceYear"
          type="number"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Catalogue Price
        </v-subheader>
        <v-text-field
          v-model="object.insurancePrice"
          type="number"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'

  export default {
    mixins: [validationMixin],
    props: {
      objectData: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        object: {
          insuranceYear: '',
          insurancePrice: '',
        },
      }
    },
    watch: {
      object: {
        deep: true,
        handler () {
          this.$emit('update:objectData', this.object)
        },
      },
    },
    created () {
      if (this.objectData) {
        this.object = this.objectData || ''
      }
    },
  }
</script>
