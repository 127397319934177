var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 container--fluid"},[_c('v-card',{staticClass:"ma-0 user_manage-accounts"},[_c('div',{staticClass:"table-filters"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg col-md-6"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Leaser ID ")]),_c('v-text-field',{attrs:{"type":"number","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterId),callback:function ($$v) {_vm.filterId=$$v},expression:"filterId"}})],1),_c('div',{staticClass:"col-lg col-md-6"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Catalogue Price From ")]),_c('v-text-field',{attrs:{"type":"number","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterCataloguePriceFrom),callback:function ($$v) {_vm.filterCataloguePriceFrom=$$v},expression:"filterCataloguePriceFrom"}})],1),_c('div',{staticClass:"col-lg col-md-6"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Start Date From ")]),_c('v-text-field',{attrs:{"single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterStartDateFrom),callback:function ($$v) {_vm.filterStartDateFrom=$$v},expression:"filterStartDateFrom"}})],1),_c('div',{staticClass:"col-lg col-md-6"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Leaser Identified ")]),_c('v-select',{attrs:{"items":[
              { name: 'All', value: '' },
              { name: 'Yes', value: 'yes' },
              { name: 'No', value: 'no' },
            ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterLeaserIdentified),callback:function ($$v) {_vm.filterLeaserIdentified=$$v},expression:"filterLeaserIdentified"}})],1)]),_c('div',{staticClass:"table-filters__controls"},[_c('v-btn',{attrs:{"color":"teal lighten-2","medium":""}},[_vm._v(" Apply ")]),_c('v-btn',{attrs:{"color":"red","medium":""},on:{"click":_vm.clearFilters}},[_vm._v(" Reset ")])],1)]),_c('div',{staticClass:"table-wrapper px-6 py-3",on:{"click":_vm.rewriteWidths}},[(_vm.tableUsers)?_c('v-data-table',{directives:[{name:"columns-resizable",rawName:"v-columns-resizable"}],ref:"usertable",staticClass:"fixed-columns-table",attrs:{"page":_vm.currentPage,"headers":_vm.userHeaders,"items":_vm.tableUsers,"item-key":"id","footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 20, 50, 100]
        },"fixed-header":true},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No data ")]},proxy:true},{key:"item.id",fn:function({ item }){return [_c('span',{staticClass:"break-content"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.leaserName",fn:function({ item }){return [_c('span',{staticClass:"break-content"},[_vm._v(" "+_vm._s(item.leaserName)+" ")])]}},{key:"item.action",fn:function(){return [_c('div',{staticClass:"table-actions"},[_c('v-btn',{staticClass:"action-btn",attrs:{"color":"teal lighten-2","elevation":"0"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-comment-eye-outline ")])],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"table-pagination",attrs:{"length":_vm.pageCount,"total-visible":"7"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})]},proxy:true}],null,false,694764801),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }