<template>
  <div class="table-wrapper">
    <v-data-table
      :headers="notesHeaders"
      :items="notes"
      item-key="id"
      hide-default-footer
    >
      <template v-slot:body.prepend>
        <tr>
          <td
            colspan="6"
            class="py-4 text-right"
          >
            <v-btn
              color="teal lighten-2"
              class="ma-0"
              @click="addNewInfo"
            >
              <v-icon
                left
                color="#fff"
              >
                mdi-plus
              </v-icon>
              Add Note
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:item.info="{ item }">
        <v-textarea
          v-if="isEditing.includes(item.infoId)"
          v-model="item.info"
          single-line
          outlined
          dense
          hide-details
        />
        <p
          v-else
          class="ma-0"
        >
          {{ item.info }}
        </p>
      </template>
      <template v-slot:item.reminderDate="{ item }">
        <v-menu
          v-if="isEditing.includes(item.infoId)"
          :ref="`menu-${item.id}-date`"
          v-model="item.datePickerOpened"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.reminderDate"
              single-line
              outlined
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="item.reminderDate"
          />
        </v-menu>
        <span
          v-else
        >
          {{ item.reminderDate }}
        </span>
      </template>
      <template v-slot:item.reminderTime="{ item }">
        <v-menu
          v-if="isEditing.includes(item.infoId)"
          :ref="`menu-${item.id}-time`"
          v-model="item.timePickerOpened"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.reminderTime"
              single-line
              outlined
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-model="item.reminderTime"
            format="24hr"
            @click:minute="$refs[`menu-${item.id}-time`].save(item.reminderTime)"
          />
        </v-menu>
        <span
          v-else
        >
          {{ item.reminderTime }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="table-actions">
          <v-btn
            v-if="isEditing.includes(item.infoId)"
            color="teal lighten-2"
            elevation="0"
            class="action-edit"
            @click="saveItem(item.infoId)"
          >
            <v-icon left>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            color="teal lighten-2"
            elevation="0"
            class="action-edit"
            @click="editItem(item.infoId)"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            color="red"
            elevation="0"
            class="action-btn"
            @click="deleteItem(item.id || item.infoId)"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      user: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        menu: false,
        notes: [],
        isEditing: [],
        notesHeaders: [
          { text: 'Note', value: 'info', align: 'center' },
          { text: 'Created', value: 'createdAt', align: 'center' },
          { text: 'Created By', value: 'createdBy', align: 'center' },
          { text: 'Reminder Date', value: 'reminderDate', align: 'center' },
          { text: 'Reminder Time', value: 'reminderTime', align: 'center' },
          { text: 'Action', value: 'action', align: 'center', sortable: false },
        ],
      }
    },

    created () {
      this.notes = this.user.userInfo?.length ? this.user.userInfo.map((el, i) => {
        el.infoId = i + 1
        el.datePickerOpened = false
        el.timePickerOpened = false
        el.reminderDate = el.reminderDueDate.split('T')[0]
        el.reminderTime = el.reminderDueDate.split('T')[1].split('.')[0]
        return el
      }) : []
    },

    methods: {
      ...mapActions({
        createUserInfo: 'createUserInfo',
        editUserInfo: 'editUserInfo',
        deleteUserInfo: 'deleteUserInfo',
      }),
      addNewInfo () {
        const newInfo = {
          info: '',
          createdAt: this.createdDate(new Date()),
          createdBy: `${this.$store.state.auth.myData.firstname} ${this.$store.state.auth.myData.lastname}`,
          reminderDate: '',
          reminderTime: '',
          infoId: this.notes.length ? this.notes[this.notes.length - 1].infoId + 1 : 1,
          isNew: true,
        }
        this.notes.push(newInfo)
        this.isEditing.push(newInfo.infoId)
      },
      createdDate (date) {
        return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
      },
      editItem (infoId) {
        if (!this.isEditing.includes(infoId)) {
          this.isEditing.push(infoId)
        }
      },
      saveItem (infoId) {
        const index = this.isEditing.findIndex(el => el === infoId)
        const info = this.notes.find(note => note.infoId === infoId)
        const reminder = new Date(info.reminderDate)
        reminder.setHours(info.reminderTime.split(':')[0])
        reminder.setMinutes(info.reminderTime.split(':')[1])
        info.reminderDueDate = reminder.toISOString().split('T').join(' ').split('.')[0]
        info.userId = this.user.id
        if (info.isNew) {
          info.id = this.user.id
          this.createUserInfo(info)
        } else {
          this.editUserInfo(info)
        }
        this.isEditing.splice(index, 1)
      },
      deleteItem (id) {
        let index
        if (this.notes.find(el => el.id === id)) {
          this.deleteUserInfo({ infoId: id, userId: this.user.id })
          index = this.notes.findIndex(el => el.id === id)
        } else {
          index = this.notes.findIndex(el => el.infoId === id)
        }
        this.notes.splice(index, 1)
      },
    },
  }
</script>

<style lang="sass">

</style>
