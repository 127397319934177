<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Rater ID
            </v-subheader>
            <v-text-field
              v-model="filterRaterId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Ratee ID
            </v-subheader>
            <v-text-field
              v-model="filterRateeId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Rental ID
            </v-subheader>
            <v-text-field
              v-model="filterRentalId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Object ID
            </v-subheader>
            <v-text-field
              v-model="filterObjectId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Rating Status
            </v-subheader>
            <v-select
              v-model="filterRatingStatus"
              :items="[
                { name: 'Open', value: 'Open' },
                { name: 'Closed', value: 'Closed' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Rating
            </v-subheader>
            <v-select
              v-model="filterRating"
              :items="[
                { name: 'Rating 5', value: '5' },
                { name: 'Rating 4', value: '4' },
                { name: 'Rating 3', value: '3' },
                { name: 'Rating 2', value: '2' },
                { name: 'Rating 1', value: '1' },
              ]"
              multiple
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
          >
            Apply
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="tableObjects"
          ref="objecttable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="objectHeaders"
          :items="tableObjects"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-edit"
                @click="objectAction('edit object', item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                elevation="0"
                class="action-btn"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        objects: [
          {
            id: '200',
            raterId: '201',
            rateeId: '202',
            rentalId: '203',
            objectId: '204',
            rating: '4',
            comment: 'Optional comment',
            ratingStatus: 'Closed',
            created: '2018 Jul 30 13:00',
            ratingDate: '2018 Jul 30 13:00',
            modified: '2020 Aug 2, 18:02',
            modifiedBy: 'Andreas Amstutz',
          },
          {
            id: '300',
            raterId: '301',
            rateeId: '302',
            rentalId: '303',
            objectId: '304',
            rating: '5',
            comment: 'Optional comment',
            ratingStatus: 'Open',
            created: '2018 Jul 30 13:00',
            ratingDate: '2018 Jul 30 13:00',
            modified: '2020 Aug 2, 18:02',
            modifiedBy: 'Andreas Amstutz',
          },
          {
            id: '400',
            raterId: '401',
            rateeId: '402',
            rentalId: '403',
            objectId: '404',
            rating: '3',
            comment: 'Optional comment',
            ratingStatus: 'Closed',
            created: '2018 Jul 30 13:00',
            ratingDate: '2018 Jul 30 13:00',
            modified: '2020 Aug 2, 18:02',
            modifiedBy: 'Andreas Amstutz',
          },
        ],
        tableObjects: null,
        selected: [],
        filterRaterId: '',
        filterRateeId: '',
        filterRentalId: '',
        filterObjectId: '',
        filterRatingStatus: '',
        filterRating: '',
        appliedFilters: {},
        objectsTotal: 0,
        objectHeaders: [
          { text: 'Rating ID', value: 'id', align: 'center', width: '110px' },
          { text: 'Rater ID', value: 'raterId', align: 'center', width: '110px' },
          { text: 'Ratee ID', value: 'rateeId', align: 'center', width: '110px' },
          { text: 'Rental ID', value: 'rentalId', align: 'center', width: '110px' },
          { text: 'Object ID', value: 'objectId', align: 'center', width: '110px' },
          { text: 'Rating', value: 'rating', align: 'center', width: '90px' },
          { text: 'Comment', value: 'comment', width: '300px' },
          { text: 'Rating Status', value: 'ratingStatus', align: 'center', width: '150px' },
          { text: 'Created', value: 'created', width: '200px' },
          { text: 'Rating Date', value: 'ratingDate', width: '200px' },
          { text: 'Modified', value: 'modified', align: 'center', width: '150px' },
          { text: 'Modified By', value: 'modifiedBy', align: 'center', width: '150px' },
          { text: 'Action', value: 'action', align: 'center', width: '80px' },
        ],
        localWidths: [],
      }
    },

    watch: {
      filterRaterId: {
        handler () { this.filterObjects('filterRaterId', 'id', 'text') },
      },
      filterRateeId: {
        handler () { this.filterObjects('filterRateeId', 'rateeId', 'text') },
      },
      filterRentalId: {
        handler () { this.filterObjects('filterRentalId', 'rentalId', 'text') },
      },
      filterObjectId: {
        handler () { this.filterObjects('filterObjectId', 'objectId', 'text') },
      },
      filterRatingStatus: {
        handler () { this.filterObjects('filterRatingStatus', 'ratingStatus', 'text') },
      },
      filterRating: {
        handler () { this.filterObjects('filterRating', 'rating', 'select') },
      },
    },

    created () {
      this.tableObjects = this.objects
    },

    methods: {
      objectAction (val, object) {
        if (val.toLowerCase() === 'edit object') {
          this.$router.push(`object-ratings/${object.id}`)
        }
      },
      clearFilters () {
        this.filterRaterId = ''
        this.filterRateeId = ''
        this.filterRentalId = ''
        this.filterObjectId = ''
        this.filterRatingStatus = ''
        this.filterRating = ''
        this.tableObjects = this.objects
      },
      filterObjects (filterName, tableField, type) {
        if (this[filterName]) {
          if (this.appliedFilters[filterName]) {
            this.appliedFilters[filterName].applied = true
          } else {
            this.appliedFilters[filterName] = {
              applied: true,
              tableField: tableField,
              type: type,
            }
          }
        } else {
          this.appliedFilters[filterName].applied = false
        }

        const trigger = Object.keys(this.appliedFilters).filter(el => this.appliedFilters[el].applied)

        if (trigger.length) {
          let filteredObjects = [...this.objects]

          if (this.filterRating.length && filterName === 'filterRating') {
            filteredObjects = filteredObjects.filter(el => {
              return this.filterRating.toString().toLowerCase().includes(el.rating)
            })
          }

          Object.keys(this.appliedFilters).forEach(obj => {
            if (obj !== 'filterRating' && this.appliedFilters[obj].applied && this[obj]) {
              filteredObjects = this.filterData(filteredObjects, obj, this.appliedFilters[obj].tableField, this.appliedFilters[obj].type)
            }
          })

          this.tableObjects = filteredObjects
        } else {
          this.tableObjects = this.objects
        }
      },
      filterRatings (val) {
        let filteredObjects = [...this.objects]

        filteredObjects = filteredObjects.filter(el => {
          return val.toString().toLowerCase().includes(el.rating)
        })

        this.tableObjects = filteredObjects
      },

      filterData (data, filterName, tableField, type) {
        let filteredData = data
        switch (type) {
          case 'text':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase().includes(this[filterName].toString().toLowerCase()))
            break
          case 'select':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase() === this[filterName].toString().toLowerCase())
            break
          default:
            break
        }
        return filteredData
      },
    },
  }
</script>

<style lang="scss">
</style>
