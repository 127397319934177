import api from '@/plugins/api'

export default {
  state: {
    categories: [],
    categoriesCount: null,
  },

  mutations: {
    SET_CATEGORIES (state, categories) {
      state.categories = categories.categories
      if (categories.categoriesTotal) {
        state.categoriesTotal = categories.categoriesTotal
      }
    },
  },

  actions: {
    getCategories ({ commit }) {
      api.get('admin/categories?page=1&perPage=10000')
        .then(res => {
          commit('SET_CATEGORIES', res.data)
        })
    },
  },
}
