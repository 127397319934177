<template>
  <v-container
    fluid
    class="pa-0 item-details-page"
  >
    <v-card
      v-if="user"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit Selection #${user.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />
      <div class="pt-6 pb-4 px-6">
        <div class="row">
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Title
              </v-subheader>
              <v-text-field
                v-model="user.title"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Description
              </v-subheader>
              <v-text-field
                v-model="user.description"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                User Creation Date
              </v-subheader>
              <v-menu
                ref="menu"
                v-model="user.userCreationDate.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="userCreationDate"
                    readonly
                    single-line
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="user.userCreationDate.value"
                  readonly
                  range
                />
              </v-menu>
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Last Login Date
              </v-subheader>
              <v-menu
                ref="menu"
                v-model="user.lastLoginDate.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="lastLoginDate"
                    readonly
                    single-line
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="user.lastLoginDate.value"
                  readonly
                  range
                />
              </v-menu>
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Number of Objects
              </v-subheader>
              <v-text-field
                v-model="user.numberofObjects"
                readonly
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Number of Leasings
              </v-subheader>
              <v-text-field
                v-model="user.numberofLeasings"
                readonly
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Number of Rentings
              </v-subheader>
              <v-text-field
                v-model="user.numberofRentings"
                readonly
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Leasings (Main Keyword)
              </v-subheader>
              <v-autocomplete
                v-model="user.leasings.values"
                :items="user.leasings.items"
                outlined
                dense
                multiple
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                User Promotion Option
              </v-subheader>
              <v-select
                v-model="user.userPromotionOption"
                :items="[{ text: 'Yes', value: 'Yes' }, { text: 'No', value: 'No' }]"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                User Status
              </v-subheader>
              <v-select
                v-model="user.userStatus"
                :items="[{ text: 'Active', value: 'Yes' }, { text: 'Not Active', value: 'No' }]"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Address Picker
              </v-subheader>
              <v-text-field
                v-model="user.addressPicker"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Perimeter Radius
              </v-subheader>
              <v-text-field
                v-model="user.perimeterRadius"
                type="number"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                App Usage
              </v-subheader>
              <v-select
                v-model="user.appUsage"
                :items="[{ text: 'iOS', value: 'ios' }, { text: 'Android', value: 'android' }, { text: 'None', value: 'none' }]"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
              />
            </div>
            <div
              v-if="user.appUsage && user.appUsage !== 'none'"
              class="input-wrap"
            >
              <v-subheader class="input-label">
                App Notifications
              </v-subheader>
              <v-text-field
                v-model="user.appNotifications"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="user_manage-accounts__footer px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        user: {
          id: '',
          title: 'Alle neuen User vom 3.9.17 bis 8.11.17 ohne Objekte',
          description: '20.- Franken für drei Objekte',
          userCreationDate: {
            menu: false,
            value: ['2018-09-15', '2018-09-20'],
          },
          lastLoginDate: {
            menu: false,
            value: ['2018-09-15', '2018-09-20'],
          },
          numberofObjects: '0 / 10',
          numberofLeasings: '3 / 10',
          numberofRentings: '0 / 0',
          leasings: {
            items: ['Bohrmaschine', 'Bohrmaschine 2', 'Bohrmaschine 3', 'Bohrmaschine 4'],
            values: [],
          },
          userPromotionOption: '',
          userStatus: '',
          addressPicker: 'Industriestrasse 12, Dietlikon',
          perimeterRadius: '10',
          appUsage: '',
          appNotifications: '',
        },
      }
    },
    computed: {
      userCreationDate () {
        return this.user.userCreationDate.value.join(' / ')
      },
      lastLoginDate () {
        return this.user.lastLoginDate.value.join(' / ')
      },
    },
    created () {
      if (this.$route.params.id) {
        this.user.id = this.$route.params.id
      }
    },
  }
</script>

<style lang="scss">

</style>
