<template>
  <v-container
    fluid
    class="pa-0 item-details-page"
  >
    <v-card
      v-if="user"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit Rating #${user.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="updateRating"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
            @click="$router.push('/users/user-ratings')"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />
      <div class="pt-6 pb-4 px-6">
        <div class="row">
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rating ID
              </v-subheader>
              <v-text-field
                v-model="user.id"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rater ID
              </v-subheader>
              <v-text-field
                v-model="user.raterID"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Ratee ID
              </v-subheader>
              <v-text-field
                v-model="user.rateeID"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Modified By ID
              </v-subheader>
              <v-text-field
                v-model="user.modifiedById"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Created
              </v-subheader>
              <v-text-field
                v-model="user.created"
                readonly
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rating Date
              </v-subheader>
              <v-text-field
                v-model="user.ratingDate"
                readonly
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rating
              </v-subheader>
              <v-text-field
                v-model="user.rating"
                :rules="ratingRules"
                single-line
                type="number"
                min="0"
                max="5"
                outlined
                dense
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rating Status
              </v-subheader>
              <v-select
                v-model="user.ratingStatus"
                :items="[{ text: 'Open', value: 'open' }, { text: 'Closed', value: 'closed' }]"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Comment
              </v-subheader>
              <v-textarea
                v-model="user.comment"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="user_manage-accounts__footer px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="updateRating"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
            @click="$router.push('/users/user-ratings')"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-snackbar
      v-model="showMessage"
      :color="messageColor"
      top
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  import moment from 'moment'
  export default {
    data () {
      return {
        ratingRules: [
          v => (v && v >= 1) || 'Rating should be above 1',
          v => (v && v <= 5) || 'Max should not be above 5',
        ],
        message: '',
        messageColor: 'success',
        showMessage: false,
        user: {
          id: null,
          raterID: null,
          rateeID: null,
          rating: null,
          comment: '',
          ratingStatus: '',
          created: '2021 Mar 02, 11:09',
          ratingDate: '2021 Mar 02, 11:09',
        },
      }
    },
    mounted () {
      const id = this.$route.params.id
      axios.get(`/admin/ratings/users/${id}`)
        .then(response => {
          const rating = response.data
          this.user = {
            id: rating.id,
            raterID: rating.ratedBy.id,
            rateeID: rating.user.id,
            requestID: rating.rentingRequestId,
            modifiedById: rating.modifiedById,
            objectID: rating.objectId,
            rating: rating.rating,
            comment: rating.comment,
            ratingStatus: rating.status,
            // Format created and ratingDate like this 2021 Mar 02, 11:09 using moment.js
            created: moment(rating.createdAt).format('YYYY MMM DD, HH:mm'),
            ratingDate: moment(rating.ratingDate).format('YYYY MMM DD, HH:mm'),
          }
        })
    },
    methods: {
      updateRating () {
        const data = {
          rating: this.user.rating,
          comment: this.user.comment,
          status: this.user.ratingStatus,
        }
        const _this = this
        axios.put(`/admin/ratings/users/${this.user.id}`, data)
          .then(() => {
            _this.showMessage = true
            _this.messageColor = 'success'
            _this.message = 'Rating updated successfully'
          }).catch((error) => {
            _this.message = error.response.data.message
            _this.messageColor = 'error'
            _this.showMessage = true
          })
      },
    },
  }
</script>

<style lang="scss">

</style>
