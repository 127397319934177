<template>
  <v-container
    fluid
    class="pa-0 item-details-page"
  >
    <v-card
      v-if="post"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          Post Details
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="save"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-tabs
        v-model="tab"
        class="px-6 py-4"
      >
        <v-tab
          v-for="item in tabs"
          :key="item.name"
        >
          {{ item.name }}
        </v-tab>
        <v-tabs-items
          v-model="tab"
          class="pt-6 pb-2"
        >
          <v-tab-item
            v-for="item in tabs"
            :key="item.name"
          >
            <component
              :is="item.component"
              :post="post"
              :post-data="post"
              @updatePost="handleUpdatePost"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-divider />
      <div class="pt-6 pb-4 px-6" />
      <div class="user_manage-accounts__footer px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="save"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'

  import Editor from '@tinymce/tinymce-vue'
  import swal from 'sweetalert2'

  import PostDetailsTab from '../../../../components/post-details/PostDetailsTab.vue'
  import PostImagesTab from '@/components/post-details/PostImagesTab.vue'
  export default {
    name: 'CreatePost',
    components: {
      Editor,
      DatePicker,
      PostDetailsTab,
      PostImagesTab,
    },
    data () {
      return {
        tabs: [
          { name: 'Post Details', component: 'PostDetailsTab' },
          { name: 'Images', component: 'PostImagesTab' },
        ],
        tab: null,
        post: {
          id: null,
          title: '',
          slug: '',
          content: '',
          teaserText: '',
          authorId: null,
          publishDate: '',
          status: 0,
          categories: [],
          images: [],
          error: null,
          selectedImages: [],
        },
        postStatuses: [
          { name: 'Draft', value: 1 },
          { name: 'Inactive', value: 2 },
          { name: 'Active', value: 3 },
        ],
      }
    },
    watch: {
      'post.title' (val) {
        this.post.slug = val.trim().split(' ').join('-')
      },
    },
    mounted () {
      this.post.id = this.$route.params.id
      if (this.post.id) {
        this.loadPostData()
      }
    },
    created () {
      if (this.$route.query.activeTab) {
        this.tab = this.tabs.findIndex(el => el.name.toLowerCase() === this.$route.query.activeTab.toLowerCase())
      }
    },
    methods: {
      updateContent (content) {
        this.post.content = content
      },
      loadPostData () {
        axios.get('/admin/blog/posts/show/' + this.post.id).then((response) => {
          const post = response.data
          const status = this.postStatuses.find((status) => status.name === post.status)

          this.post = {
            id: post.id,
            title: post.title,
            slug: post.slug,
            content: post.content,
            teaserText: post.teaserText,
            authorId: post.authorId,
            publishedAt: new Date(post.publishedAt).getTime(),
            status: status.value,
            categories: post.categories,
            images: post.images,
            error: null,
          }
        }).catch((error) => {
          this.post.error = error
        })
      },
      save () {
        this.post.publishedAt = new Date(this.post.publishedAt).toISOString()
        if (this.post.id) {
          this.update()
        } else {
          this.create()
        }
      },
      update () {
        const _this = this

        const formData = new FormData()
        const post = _this.post

        formData.append('id', post.id)
        formData.append('title', post.title)
        formData.append('slug', post.slug)
        formData.append('content', post.content)
        formData.append('teaserText', post.teaserText)
        formData.append('authorId', post.authorId)
        formData.append('publishedAt', post.publishedAt)
        formData.append('status', post.status)

        if (post.categories) {
          post.categories.forEach((category, index) => {
            formData.append(`categories[${index}]`, category)
          })
        }

        if (post.images) {
          post.images.forEach((image, index) => {
            formData.append(`images[${index}][id]`, image.id)
            formData.append(`images[${index}][path]`, image.path)
            formData.append(`images[${index}][isDeleted]`, image.isDeleted)
          })
        }

        if (post.selectedImages) {
          for (let i = 0; i < post.selectedImages.length; i++) {
            formData.append('photos[]', post.selectedImages[i].file)
          }
        }

        axios.post('/admin/blog/posts/edit', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          swal.fire({
            title: 'Success',
            text: 'Post updated successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          })

          _this.loadPostData()
        }).catch(function (error) {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
      },
      create () {
        const _this = this

        const formData = new FormData()
        const post = _this.post

        formData.append('id', post.id)
        formData.append('title', post.title)
        formData.append('slug', post.slug)
        formData.append('content', post.content)
        formData.append('teaserText', post.teaserText)
        formData.append('authorId', post.authorId)
        formData.append('publishedAt', post.publishedAt)
        formData.append('status', post.status)

        if (post.categories) {
          post.categories.forEach((category, index) => {
            formData.append(`categories[${index}]`, category)
          })
        }

        if (post.images) {
          post.images.forEach((image, index) => {
            formData.append(`images[${index}]`, image)
          })
        }

        if (post.selectedImages) {
          for (let i = 0; i < post.selectedImages.length; i++) {
            formData.append('photos[]', post.selectedImages[i].file)
          }
        }

        axios.post('/admin/blog/posts/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          swal.fire({
            title: 'Success',
            text: 'Post created successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          })
        }).catch(function (error) {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
      },
      handleUpdatePost (post) {
        this.post = { ...this.post, ...post }
      },
    },
  }
</script>
