<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0 user_manage-accounts"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-6">
            <v-btn
              color="teal lighten-2"
              medium
            >
              Delete
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Street Name
            </v-subheader>
            <v-text-field
              v-model="filterStreetName"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Town/City
            </v-subheader>
            <v-text-field
              v-model="filterTownCity"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Zip Code
            </v-subheader>
            <v-text-field
              v-model="filterZipCode"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
          >
            Apply
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="tableUsers"
          ref="usertable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="userHeaders"
          :items="tableUsers"
          show-select
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.id="{ item }">
            <span class="break-content">
              {{ item.id }}
            </span>
          </template>
          <template v-slot:item.leaserName="{ item }">
            <span class="break-content">
              {{ item.leaserName }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-btn"
                @click="userAction('edit address', item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              </v-btn>

              <v-btn
                color="red"
                elevation="0"
                class="action-btn"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        tableUsers: null,
        selected: [],
        filterStreetName: '',
        filterTownCity: '',
        filterZipCode: '',
        appliedFilters: {},
        usersTotal: 0,
        userHeaders: [
          { text: 'User ID', value: 'id', align: 'center', width: '80px' },
          { text: 'Street Name', value: 'streetName', width: '200px' },
          { text: 'Street Number', value: 'streetNumber', align: 'center', width: '80px' },
          { text: 'Town/City', value: 'townCity', width: '80px' },
          { text: 'Zip Code', value: 'zipCode', align: 'center', width: '80px' },
          { text: 'Latitude', value: 'latitude', width: '80px' },
          { text: 'Longitude', value: 'longitude', width: '80px' },
          { text: 'Created', value: 'created', width: '140px' },
          { text: 'Modified', value: 'modified', width: '140px' },
          { text: 'Total Users', value: 'totalUsers', align: 'center', width: '80px' },
          { text: 'Total Objects', value: 'totalObjects', align: 'center', width: '80px' },
          { text: 'Action', value: 'action', align: 'center', width: '80px' },
        ],
        users: [
          {
            id: 10650,
            streetName: 'Donald Trump Circus',
            streetNumber: '5a',
            townCity: 'Albisrieden',
            zipCode: '8947',
            latitude: '47.372033',
            longitude: '8.491753',
            created: '2021 Jan 07, 10:04',
            modified: '2021 Jan 07, 10:04',
            totalUsers: 1,
            totalObjects: 3,
          },
          {
            id: 10651,
            streetName: 'Donald Trump Circus',
            streetNumber: '5a',
            townCity: 'Albisrieden',
            zipCode: '8247',
            latitude: '47.372033',
            longitude: '8.491753',
            created: '2021 Jan 07, 10:04',
            modified: '2021 Jan 07, 10:04',
            totalUsers: 2,
            totalObjects: 4,
          },
          {
            id: 10652,
            streetName: 'Donald Trump Circus',
            streetNumber: '5a',
            townCity: 'Albisrieden',
            zipCode: '2047',
            latitude: '47.372033',
            longitude: '8.491753',
            created: '2021 Jan 07, 10:04',
            modified: '2021 Jan 07, 10:04',
            totalUsers: 1,
            totalObjects: 3,
          },
        ],
        localWidths: [],
      }
    },

    watch: {
      filterStreetName: {
        handler () { this.filterUsers('filterStreetName', 'streetName', 'text') },
      },
      filterTownCity: {
        handler () { this.filterUsers('filterTownCity', 'townCity', 'text') },
      },
      filterZipCode: {
        handler () { this.filterUsers('filterZipCode', 'zipCode', 'text') },
      },
      users: {
        immediate: true,
        handler () {
          this.tableUsers = this.users
        },
      },
    },

    created () {
      if (!this.users.length) {
        this.fetchUsers()
      }
    },

    methods: {
      userAction (val, user) {
        if (val.toLowerCase() === 'edit address') {
          this.$router.push(`/users/undefined-addresses/${user.id}`)
        }
      },

      clearFilters () {
        this.filterStreetName = ''
        this.filterTownCity = ''
        this.filterZipCode = ''
        this.tableUsers = this.users
      },

      filterUsers (filterName, tableField, type) {
        if (this[filterName]) {
          if (this.appliedFilters[filterName]) {
            this.appliedFilters[filterName].applied = true
          } else {
            this.appliedFilters[filterName] = {
              applied: true,
              tableField: tableField,
              type: type,
            }
          }
        } else {
          this.appliedFilters[filterName].applied = false
        }

        const trigger = Object.keys(this.appliedFilters).filter(el => this.appliedFilters[el].applied)

        if (trigger.length) {
          let filteredUsers = [...this.users]

          Object.keys(this.appliedFilters).forEach(obj => {
            if (this.appliedFilters[obj].applied && this[obj]) {
              filteredUsers = this.filterData(filteredUsers, obj, this.appliedFilters[obj].tableField, this.appliedFilters[obj].type)
            }
          })

          this.tableUsers = filteredUsers
        } else {
          this.tableUsers = this.users
        }
      },

      filterData (data, filterName, tableField, type) {
        let filteredData = data
        switch (type) {
          case 'text':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase().includes(this[filterName].toString().toLowerCase()))
            break
          case 'select':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase() === this[filterName].toString().toLowerCase())
            break
          default:
            break
        }
        return filteredData
      },
    },
  }
</script>
