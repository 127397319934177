<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              User ID
            </v-subheader>
            <v-text-field
              v-model="filterUserId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Object ID
            </v-subheader>
            <v-text-field
              v-model="filterObjectId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls d-flex justify-space-between">
          <v-btn
            color="teal lighten-2"
            medium
            @click="createDialog = true"
          >
            Create
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="objects"
          ref="objecttable"
          v-model="selected"
          :page.sync="currentPage"
          :headers="objectHeaders"
          :items="objects"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          :items-per-page.sync="itemsPerPage"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-btn
                color="red"
                elevation="0"
                class="action-btn"
                @click="deleteFavorite(item.id)"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
      <v-row justify="center">
        <v-dialog
          v-model="createDialog"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Add a new favorite</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-text-field
                    v-model="form.userId"
                    label="User ID"
                    type="number"
                  />
                  <v-text-field
                    v-model="form.productId"
                    label="Product ID"
                    type="number"
                  />
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="createDialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="createFavorite"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
    <v-snackbar
      v-model="showAlertMessage"
      :color="alertColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 1,
        itemsPerPage: 10,
        objects: [],
        tableObjects: null,
        selected: [],
        filterUserId: '',
        filterObjectId: '',
        appliedFilters: {},
        objectsTotal: 0,
        objectHeaders: [
          { text: 'Favourite ID', value: 'id', align: 'center' },
          { text: 'Product ID', value: 'productId', align: 'center' },
          { text: 'Product Title', value: 'productTitle', align: 'center' },
          { text: 'User ID', value: 'userId', align: 'center' },
          { text: 'First Name', value: 'firstName', align: 'center' },
          { text: 'Last Name', value: 'lastName', align: 'center' },
          { text: 'Created', value: 'created', align: 'center' },
          { text: 'Action', value: 'action', align: 'center', width: '110px' },
        ],
        localWidths: [],
        createDialog: false,
        form: {
          userId: '',
          productId: '',
        },
        showAlertMessage: false,
        alertColor: 'success',
        message: '',
      }
    },

    watch: {
      filterUserId: {
        handler () {
          this.fetchPosts()
        },
      },
      filterObjectId: {
        handler () {
          this.fetchPosts()
        },
      },
      itemsPerPage: {
        handler () {
          this.fetchPosts()
        },
      },
      currentPage: {
        handler () {
          this.fetchPosts()
        },
      },
    },

    created () {
      this.fetchPosts()
    },

    methods: {
      fetchPosts () {
        const _this = this
        axios.get('/admin/product/favorites/list', {
          params: {
            perPage: this.itemsPerPage,
            page: this.currentPage,
            objectId: this.filterObjectId || null,
            userId: this.filterUserId || null,
          },
        })
          .then(response => {
            const data = response.data

            _this.objects = data.productFavorites.map(el => {
              return {
                id: el.id,
                productId: el.product.id,
                productTitle: el.product.title,
                userId: el.user.id,
                firstName: el.user.firstName,
                lastName: el.user.lastName,
                created: el.createdAt,
              }
            })

            const total = data.totalProductFavorites
            _this.pageCount = Math.ceil(total / _this.itemsPerPage)
            if (_this.currentPage > _this.pageCount) {
              _this.currentPage = _this.pageCount
            }
          })
          .catch(() => {
            _this.message = 'An error occurred while fetching data'
            _this.showAlertMessage = true
            _this.alertColor = 'error'
          })
      },
      createFavorite () {
        const _this = this
        axios.post('/admin/product/favorites/create', {
          userId: Number(this.form.userId),
          objectId: Number(this.form.productId),
        })
          .then(response => {
            if (response.status === 203) {
              _this.alertColor = 'success'
              _this.fetchPosts()
              _this.form = {
                userId: '',
                productId: '',
              }
            } else {
              _this.alertColor = 'grey darken-3'
            }
            _this.message = response.data.message
            _this.showAlertMessage = true

            _this.createDialog = false
          })
      },
      deleteFavorite (id) {
        if (!confirm('Are you sure you want to delete this favorite?')) return

        const _this = this
        axios.get(`/admin/product/favorites/delete/${id}`)
          .then(response => {
            _this.message = response.data.message
            _this.showAlertMessage = true
            _this.alertColor = 'success'
            _this.fetchPosts()
          }).catch(() => {
            _this.message = 'An error occurred while deleting the favorite'
            _this.showAlertMessage = true
            _this.alertColor = 'error'
          })
      },
      clearFilters () {
        this.filterUserId = ''
        this.filterObjectId = ''
      },
    },
  }
</script>

<style lang="scss">
</style>
