<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-6">
            <v-btn
              color="teal lighten-2"
              medium
            >
              Delete
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Object ID
            </v-subheader>
            <v-text-field
              v-model="filterId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Owner ID
            </v-subheader>
            <v-text-field
              v-model="filterOwnerId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Title
            </v-subheader>
            <v-text-field
              v-model="filterTitle"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Object Status
            </v-subheader>
            <v-select
              v-model="filterStatus"
              :items="[
                { name: 'All', value: '' },
                { name: 'Not Complete', value: 'notComplete' },
                { name: 'Pending', value: 'pending' },
                { name: 'Declined', value: 'declined' },
                { name: 'Active', value: 'active' },
                { name: 'Inactive', value: 'inactive' },
                { name: 'Disabled', value: 'disabled' },
                { name: 'Paused', value: 'paused' },
                { name: 'Blocked', value: 'blocked' },
                { name: 'Idle', value: 'idle'},
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Object Flag
            </v-subheader>
            <v-select
              v-model="filterFlag"
              :items="[
                { name: 'Edited', value: 'edited' },
                { name: 'None', value: 'none' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Object Type
            </v-subheader>
            <v-select
              v-model="filterType"
              :items="[
                { name: 'Default', value: 'default' },
                { name: 'Fan', value: 'fan' },
                { name: 'Promo', value: 'promo' },
                { name: 'Postal', value: 'postal' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Collection
            </v-subheader>
            <v-select
              v-model="filterCollection"
              :items="[
                { name: 'Yes', value: 'yes' },
                { name: 'No', value: 'no' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
            @click="filterObjects"
          >
            Apply
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="tableObjects"
          ref="objecttable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="objectHeaders"
          :items="tableObjects"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100],
          }"
          :items-per-page.sync="itemsPerPage"
          show-select
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount"
          @click:row="objectAction('edit object', $event)"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.id="{ item }">
            <router-link
              v-slot="{ href }"
              :to="{ name: 'Edit Object', params: { id: item.id } }"
              class="link__hidden"
              target="_blank"
            >
              <a
                :href="href"
                target="_blank"
              >
                <span>
                  {{ item.id }}
                </span>
              </a>
            </router-link>
          </template>
          <template v-slot:item.ownerId="{ item }">
            <router-link
              v-slot="{ href }"
              :to="{ name: 'Edit Object', params: { id: item.id } }"
              class="link__hidden"
              target="_blank"
            >
              <a
                :href="href"
                target="_blank"
              >
                <span>
                  {{ item.ownerId }}
                </span>
              </a>
            </router-link>
          </template>
          <template v-slot:item.title="{ item }">
            <router-link
              v-slot="{ href }"
              :to="{ name: 'Edit Object', params: { id: item.id } }"
              class="link__hidden"
              target="_blank"
            >
              <a
                :href="href"
                target="_blank"
              >
                <span>
                  {{ item.title }}
                </span>
              </a>
            </router-link>
          </template>
          <template v-slot:item.rentalRequests="{ item }">
            <router-link
              v-slot="{ href }"
              :to="{ name: 'Edit Object', params: { id: item.id } }"
              class="link__hidden"
              target="_blank"
            >
              <a
                :href="href"
                target="_blank"
              >
                <span>
                  {{ item.rentalRequests }}
                </span>
              </a>
            </router-link>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-select
                :ref="`select-${item.id}`"
                append-icon=""
                :items="[
                  { text: 'Activate Object', value: 'Activate Object', disabled: item.objectStatus.toLowerCase() !== 'pending' },
                  { text: 'Decline Object', value: 'Decline Object', disabled: item.objectStatus.toLowerCase() !== 'pending' },
                  { text: 'Delete Object', value: 'Delete Object', disabled: item.objectStatus.toLowerCase() === 'deleted' },
                  { text: 'Restore Object', value: 'Restore Object', disabled: item.objectStatus.toLowerCase() !== 'deleted' }
                ]"
                filled
                color="teal"
                light
                dense
                single-line
                class="action-select"
                @change="objectAction($event, item)"
              >
                <template v-slot:label>
                  <v-icon color="white">
                    mdi-cog-outline
                  </v-icon>
                </template>
              </v-select>
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-edit"
                @click="objectAction('edit object', item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'

  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 1,
        itemsPerPage: 20,
        nextPage: null,
        prevPage: null,
        objects: [
          // {
          //   id: 6502,
          //   ownerId: 10652,
          //   title: 'Drohne 3',
          //   objectStatus: 'Disabled',
          //   objectFlag: 'Edited',
          //   objectType: 'Postal',
          //   created: '2020 Jun 11, 13:54',
          //   objectCountAvailable: '5 / 4',
          //   fixedVariablePrice: '21.00 / 7.00',
          //   ratingCountAverage: '4 / 3.25',
          //   rentalRequests: 3,
          //   messageCount: 25,
          //   mainKeyword: 'Drohne',
          // },
        ],
        tableObjects: null,
        selected: [],
        filterId: '',
        filterOwnerId: '',
        filterTitle: '',
        filterStatus: '',
        filterFlag: '',
        filterType: '',
        filterCollection: '',
        appliedFilters: {},
        objectsTotal: 0,
        objectHeaders: [
          { text: 'Object ID', value: 'id', align: 'center', width: '100px' },
          { text: 'Owner ID', value: 'ownerId', align: 'center', width: '100px' },
          { text: 'Title', value: 'title', width: '140px' },
          { text: 'Object Status', value: 'objectStatus', align: 'center' },
          { text: 'Object Flag', value: 'objectFlag', align: 'center' },
          { text: 'Object Type', value: 'objectType', align: 'center' },
          { text: 'Created', value: 'created', align: 'center', width: '150px' },
          { text: 'Object Count / Available', value: 'objectCountAvailable', align: 'center' },
          { text: 'Fixed / Variable Price', value: 'fixedVariablePrice', align: 'center' },
          { text: 'Rating Count / Average', value: 'ratingCountAverage', align: 'center' },
          { text: 'Rental Requests', value: 'rentalRequests', align: 'center' },
          { text: 'Message Count', value: 'messageCount', align: 'center' },
          { text: 'Main Keyword', value: 'mainKeyword', align: 'center' },
          { text: 'Action', value: 'action', align: 'center', width: '110px' },
        ],
        localWidths: [],
      }
    },

    watch: {
      filterId: {
        handler () { this.filterObjects('filterId', 'id', 'text') },
      },
      filterOwnerId: {
        handler () { this.filterObjects('filterOwnerId', 'ownerId', 'text') },
      },
      filterTitle: {
        handler () { this.filterObjects('filterTitle', 'title', 'text') },
      },
      filterStatus: {
        handler () { this.filterObjects('filterStatus', 'objectStatus', 'select') },
      },
      filterFlag: {
        handler () { this.filterObjects('filterFlag', 'objectFlag', 'select') },
      },
      filterType: {
        handler () { this.filterObjects('filterType', 'objectType', 'select') },
      },
      filterCollection: {
        handler () { this.filterObjects('filterCollection', 'rentalRequests', 'select') },
      },
      itemsPerPage: {
        handler () { this.loadObjects() },
      },
      currentPage: {
        handler () { this.loadObjects() },
      },
    },

    created () {
      this.loadObjects()
    },

    methods: {
      loadObjects () {
        let filters = {
          id: this.filterId,
          ownerId: this.filterOwnerId,
          title: this.filterTitle,
          status: this.filterStatus,
          flag: this.filterFlag,
          type: this.filterType,
          collection: this.filterCollection,
          perPage: this.itemsPerPage,
          page: this.currentPage,
        }

        // Remove the filters properties that has no value
        filters = Object.keys(filters).reduce((acc, key) => {
          if (filters[key]) {
            acc[key] = filters[key]
          }
          return acc
        }, {})

        axios.get('/admin/products/list', { params: filters }).then(res => {
          const data = res.data
          const products = data.products.map((product) => {
            return {
              id: product.id,
              ownerId: product.user_id,
              title: product.title,
              objectStatus: product.status,
              objectFlag: product.flag,
              objectType: product.type.name,
              created: product.createdAt,
              objectCountAvailable: product.objectCountAvailable,
              fixedVariablePrice: product?.price?.fixPrice,
              ratingCountAverage: product?.ratingCountAverage,
              rentalRequests: product.rentalRequests,
              messageCount: product.messageCount,
              mainKeyword: product?.keyword?.metaKeywords,
            }
          })

          this.objects = products
          this.tableObjects = products
          const total = data.productsTotal
          this.pageCount = Math.ceil(total / this.itemsPerPage)
          if (this.currentPage > this.pageCount) {
            this.currentPage = this.pageCount
          }
        })

        this.tableObjects = this.objects
      },
      objectAction (val, object) {
        if (val.toLowerCase() === 'edit object') {
          this.$router.push(`objects/edit-object/${object.id}`)
        } else {
          axios.post(`/admin/products/action/${object.id}`, { action: val }).then(res => {
            this.loadObjects()
          })
        }

        this.$refs[`select-${object.id}`].internalValue = []
        this.$refs[`select-${object.id}`].blur()
      },
      clearFilters () {
        this.filterId = ''
        this.filterOwnerId = ''
        this.filterTitle = ''
        this.filterStatus = ''
        this.filterFlag = ''
        this.filterType = ''
        this.filterCollection = ''
        this.tableObjects = this.objects
      },
      filterObjects (filterName, tableField, type) {
        // if (this[filterName]) {
        //   if (this.appliedFilters[filterName]) {
        //     this.appliedFilters[filterName].applied = true
        //   } else {
        //     this.appliedFilters[filterName] = {
        //       applied: true,
        //       tableField: tableField,
        //       type: type,
        //     }
        //   }
        // } else {
        //   this.appliedFilters[filterName].applied = false
        // }
        //
        // const trigger = Object.keys(this.appliedFilters).filter(el => this.appliedFilters[el].applied)
        //
        // if (trigger.length) {
        //   let filteredObjects = [...this.objects]
        //
        //   Object.keys(this.appliedFilters).forEach(obj => {
        //     if (this.appliedFilters[obj].applied && this[obj]) {
        //       filteredObjects = this.filterData(filteredObjects, obj, this.appliedFilters[obj].tableField, this.appliedFilters[obj].type)
        //     }
        //   })
        //
        //   this.tableObjects = filteredObjects
        // } else {
        //   this.tableObjects = this.objects
        // }
        this.loadObjects()
      },

      filterData (data, filterName, tableField, type) {
        let filteredData = data
        switch (type) {
          case 'text':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase().includes(this[filterName].toString().toLowerCase()))
            break
          case 'select':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase() === this[filterName].toString().toLowerCase())
            break
          default:
            break
        }
        return filteredData
      },
    },
  }
</script>

<style lang="scss">
.fixed-columns-table .v-data-table__wrapper table th:nth-child(2),
.fixed-columns-table .v-data-table__wrapper table td:nth-child(2) {
  left: calc(73px) !important;
}
</style>
