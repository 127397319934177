<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Title
            </v-subheader>
            <v-text-field
              v-model="filterTitle"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Object ID
            </v-subheader>
            <v-text-field
              v-model="filterObjectId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Object Count
            </v-subheader>
            <v-text-field
              v-model="filterObjectCount"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
          >
            Apply
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="tableObjects"
          ref="objecttable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="objectHeaders"
          :items="tableObjects"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        objects: [
          {
            title: 'Kamera',
            objectId: 6500,
            renterId: 10650,
            objectCount: 4,
            availableObjects: 3,
            successfulRentings: 8,
            width: '200px',
          },
          {
            title: 'Kamera 2',
            objectId: 6501,
            renterId: 10650,
            objectCount: 5,
            availableObjects: 3,
            successfulRentings: 8,
          },
          {
            title: 'Kamera 3',
            objectId: 6502,
            renterId: 10650,
            objectCount: 6,
            availableObjects: 3,
            successfulRentings: 8,
          },
        ],
        tableObjects: null,
        selected: [],
        filterTitle: '',
        filterObjectId: '',
        filterObjectCount: '',
        appliedFilters: {},
        objectsTotal: 0,
        objectHeaders: [
          { text: 'Title', value: 'title', width: '100px' },
          { text: 'Object ID', value: 'objectId', align: 'center', width: '100px' },
          { text: 'Renter ID', value: 'renterId', align: 'center', width: '100px' },
          { text: 'Object Count', value: 'objectCount', align: 'center', width: '100px' },
          { text: 'Available Objects', value: 'availableObjects', align: 'center', width: '100px' },
          { text: 'Successful Rentings', value: 'successfulRentings', align: 'center', width: '100px' },
        ],
        localWidths: [],
      }
    },

    watch: {
      filterTitle: {
        handler () { this.filterObjects('filterTitle', 'title', 'text') },
      },
      filterObjectId: {
        handler () { this.filterObjects('filterObjectId', 'objectId', 'text') },
      },
      filterObjectCount: {
        handler () { this.filterObjects('filterObjectCount', 'objectCount', 'text') },
      },
    },

    created () {
      this.tableObjects = this.objects
    },

    methods: {
      objectAction (val, object) {
        if (val.toLowerCase() === 'edit object') {
          this.$router.push(`collections/${object.id}`)
        }
      },
      clearFilters () {
        this.filterTitle = ''
        this.filterObjectId = ''
        this.filterObjectCount = ''
        this.tableObjects = this.objects
      },
      filterObjects (filterName, tableField, type) {
        if (this[filterName]) {
          if (this.appliedFilters[filterName]) {
            this.appliedFilters[filterName].applied = true
          } else {
            this.appliedFilters[filterName] = {
              applied: true,
              tableField: tableField,
              type: type,
            }
          }
        } else {
          this.appliedFilters[filterName].applied = false
        }

        const trigger = Object.keys(this.appliedFilters).filter(el => this.appliedFilters[el].applied)

        if (trigger.length) {
          let filteredObjects = [...this.objects]

          Object.keys(this.appliedFilters).forEach(obj => {
            if (this.appliedFilters[obj].applied && this[obj]) {
              filteredObjects = this.filterData(filteredObjects, obj, this.appliedFilters[obj].tableField, this.appliedFilters[obj].type)
            }
          })

          this.tableObjects = filteredObjects
        } else {
          this.tableObjects = this.objects
        }
      },

      filterData (data, filterName, tableField, type) {
        let filteredData = data
        switch (type) {
          case 'text':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase().includes(this[filterName].toString().toLowerCase()))
            break
          case 'select':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase() === this[filterName].toString().toLowerCase())
            break
          default:
            break
        }
        return filteredData
      },
    },
  }
</script>

<style lang="scss">
.fixed-columns-table .v-data-table__wrapper table th:nth-child(2),
.fixed-columns-table .v-data-table__wrapper table td:nth-child(2) {
  left: calc(200px) !important;
}
</style>
