<template>
  <div class="table-wrapper">
    <v-data-table
      :headers="iamgesHeaders"
      :items="object.media"
      item-key="id"
      hide-default-footer
      :item-class="(item) => item.isNew ? 'green lighten-4' : ''"
    >
      <template
        v-slot:body.prepend
      >
        <tr>
          <td
            :colspan="5"
            class="py-4 text-right"
          >
            <v-btn
              color="teal lighten-2"
              class="ma-0"
              @click="uploadImage"
            >
              <v-icon
                left
                color="#fff"
              >
                mdi-plus
              </v-icon>
              Upload a new image
            </v-btn>
            <input
              ref="fileInput"
              type="file"
              style="display: none"
              @change="onFileSelected"
            >
          </td>
        </tr>
      </template>
      <template
        v-slot:item.image="{ item }"
      >
        <v-img
          v-if="!item.isDeleted"
          :lazy-src="item ? item.images.small : 'https://picsum.photos/id/11/500/300'"
          max-height="300"
          max-width="300"
          :src="item ? item.images.medium : 'https://picsum.photos/id/11/500/300'"
        />
      </template>
      <template v-slot:item.mainImage="{ item }">
        <v-radio-group
          v-if="!item.isDeleted"
          v-model="mainImageId"
        >
          <v-radio
            :key="item.id"
            label="Is main?"
            :value="item.id"
            class="center-radio"
          />
        </v-radio-group>
      </template>
      <template v-slot:item.action="{ item }">
        <div
          v-if="!item.isDeleted"
          class="table-actions"
        >
          <v-btn
            color="red"
            elevation="0"
            class="action-btn"
            @click="deleteImage(item)"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    props: {
      objectData: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        menu: false,
        object: {
          media: [
            {
              images: {
                medium: '',
              },
              isMain: '',
            },
          ],
        },
        //   [{
        //   image: require('@/assets/login.jpg'),
        //   order: '',
        //   mainImage: '',
        // }],
        mainImageId: null,
        tempId: -1,
        iamgesHeaders: [
          { text: 'Image', value: 'image', align: 'center', width: '300px', sortable: false },
          { text: 'Main Image', value: 'mainImage', align: 'center', sortable: false },
          { text: 'Action', value: 'action', align: 'center', sortable: false },
        ],
        fileInput: null,
      }
    },
    watch: {
      object: {
        deep: true,
        handler () {
          this.$emit('updateObject', this.object)
        },
      },
      mainImageId: {
        handler (val) {
          this.object.media = this.object.media.map(el => {
            el.isMain = el.id === val
            return el
          })
        },
      },
    },
    created () {
      this.object = this.objectData
      this.object.photos = []
    },
    methods: {
      imageAction (val, image) {
        this.$refs[`select-${image.id}`].blur()
        this.$refs[`select-${image.id}`].internalValue = []
      },
      uploadImage () {
        this.$refs.fileInput.click()
      },
      onFileSelected (e) {
        this.object.photos.push(e.target.files[0])
        const index = this.object.photos.length - 1
        const fileUrl = URL.createObjectURL(this.object.photos[index])
        this.object.media.unshift({
          images: {
            medium: fileUrl,
            small: fileUrl,
          },
          isMain: '',
          id: this.tempId,
          isNew: true,
          index: index, // Used to delete the file before it's saved to the server
        })

        this.tempId--
      },
      deleteImage (item) {
        if (item.isNew) {
          this.object.photos = this.object.photos.filter((photo, index) => item.index !== index)
          this.object.media = this.object.media.filter(el => el.id !== item.id)
        } else {
          this.object.media = this.object.media.map(el => {
            if (el.id === item.id) {
              el.isDeleted = true
            }
            return el
          })
        }
      },
    },
  }
</script>

<style lang="sass">
.center-radio
  max-width: 120px
  margin: 0 auto
</style>
