<template>
  <div
    class="row item-details-page"
  >
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Main Keyword
        </v-subheader>
        <v-text-field
          v-model="metaData.objectMainKeyword"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Meta Keywords
        </v-subheader>
        <v-text-field
          v-model="metaData.objectMetaKeywords"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Optional Keywords
        </v-subheader>
        <v-text-field
          v-model="metaData.objectOptionalKeywords"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Specific Keywords
        </v-subheader>
        <v-text-field
          v-model="metaData.objectSpecificKeywords"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Special Characters Words
        </v-subheader>
        <v-text-field
          v-model="metaData.objectSpecialCharactersWords"
          single-line
          readonly
          outlined
          dense
          hide-details
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'

  export default {
    mixins: [validationMixin],
    props: {
      object: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        metaData: {
          objectMainKeyword: '',
          objectMetaKeywords: '',
          objectOptionalKeywords: '',
          objectSpecificKeywords: '',
          objectSpecialCharactersWords: '',
        },
      }
    },
    watch: {
      metaData: {
        handler () {
          this.$emit('updateObject', {
            ...this.object,
            keywords: {
              objectMainKeyword: this.metaData.objectMainKeyword,
              objectMetaKeywords: this.metaData.objectMetaKeywords,
              objectOptionalKeyword: this.metaData.objectOptionalKeywords,
              objectSpecificKeywords: this.metaData.objectSpecificKeywords,
              objectSpecialCharactersWords: this.metaData.objectSpecialCharactersWords,
            },
          },
          )
        },
        deep: true,
      },
    },
    created () {
      if (this.object) {
        this.metaData.objectMainKeyword = this.object.keyword.name || ''
        this.metaData.objectMetaKeywords = this.object.keyword.metaKeywords || ''
        this.metaData.objectOptionalKeywords = this.object.keyword.optionalKey || ''
        this.metaData.objectSpecificKeywords = this.object.objectSpecificKeywords || ''
        this.metaData.objectSpecialCharactersWords = this.object.objectSpecialCharactersWords || ''
      }
    },
  }
</script>
