<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-6">
            <v-btn
              color="teal lighten-2"
              medium
            >
              Add
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Keyword ID
            </v-subheader>
            <v-text-field
              v-model="filterKeywordId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Keyword
            </v-subheader>
            <v-text-field
              v-model="filterKeyword"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
          >
            Apply
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="objects.length > 0"
          ref="objecttable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="objectHeaders"
          :items="objects"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          :items-per-page.sync="itemsPerPage"
          show-select
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-edit"
                @click="objectAction('edit object', item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                elevation="0"
                class="action-btn"
                @click="deleteKeyword(item.id)"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-snackbar
      v-model="showMessage"
      :color="messageColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        itemsPerPage: 10,
        objects: [],
        selected: [],
        filterKeywordId: '',
        filterKeyword: '',
        appliedFilters: {},
        objectsTotal: 0,
        objectHeaders: [
          { text: 'Keyword ID', value: 'id', align: 'center', width: '100px' },
          { text: 'Main Keyword', value: 'mainKeyword' },
          { text: 'Meta Keywords', value: 'metaKeywords' },
          { text: 'Optional Keywords', value: 'optionalKeywords' },
          { text: 'Object Count', value: 'objectCount', align: 'center', width: '100px' },
          { text: 'Request Count', value: 'requestCount', align: 'center', width: '100px' },
          { text: 'Rental Fees', value: 'rentalFees', align: 'center', width: '100px' },
          { text: 'Average Rental Fee', value: 'averageRentalFee', align: 'center', width: '100px' },
          { text: 'Action', value: 'action', align: 'center', width: '110px' },
        ],

        showMessage: false,
        message: '',
        messageColor: 'success',
      }
    },

    watch: {
      filterKeywordId: {
        handler () { this.loadData() },
      },
      filterKeyword: {
        handler () { this.loadData() },
      },
      currentPage: {
        handler () { this.loadData() },
      },
      itemsPerPage: {
        handler () { this.loadData() },
      },
    },

    created () {
      this.tableObjects = this.objects
      this.loadData()
    },

    methods: {
      deleteKeyword (id) {
        if (!confirm('Do you really want to delete this keyword')) {
          return
        }

        const _this = this
        axios.get(`/admin/product/keywords/delete/${id}`)
          .then((response) => {
            _this.message = response.data.message
            _this.messageColor = 'success'
            _this.showMessage = true

            _this.loadData()
          })
          .catch((error) => {
            _this.message = error
            _this.messageColor = 'error'
            _this.showMessage = true
          })
      },
      loadData () {
        let filterParams = {
          id: this.filterKeywordId,
          keyword: this.filterKeyword,
          page: this.currentPage,
          perPage: this.itemsPerPage,
        }

        filterParams = Object.keys(filterParams).reduce(function (acc, key) {
          if (filterParams[key]) {
            acc[key] = filterParams[key]
          }

          return acc
        }, {})

        const _this = this
        axios.get('/admin/product/keywords', { params: filterParams })
          .then((response) => {
            _this.objects = response.data.data

            const total = response.data.total
            _this.pageCount = Math.ceil(total / _this.itemsPerPage)
            if (_this.currentPage > _this.pageCount) {
              _this.currentPage = _this.pageCount
            }
          })
          .catch((error) => {
            _this.message = error.data
            _this.messageColor = 'error'
            _this.showMessage = true
          })
      },

      objectAction (val, object) {
        if (val.toLowerCase() === 'edit object') {
          this.$router.push(`object-keywords/${object.id}`)
        }
      },
      clearFilters () {
        this.filterKeywordId = ''
        this.filterKeyword = ''
      },
    },
  }
</script>

<style lang="scss">
.fixed-columns-table .v-data-table__wrapper table th:nth-child(2),
.fixed-columns-table .v-data-table__wrapper table td:nth-child(2) {
  left: calc(68px) !important;
}
</style>
