<template>
  <v-container
    fluid
    class="pa-0 item-details-page"
  >
    <v-card
      v-if="comment"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          Comment Details
        </h2>
      </v-card-actions>
      <v-divider />
      <div class="pt-6 pb-4 px-6">
        <div class="row">
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Status
              </v-subheader>
              <v-checkbox
                v-model="comment.status"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Content
              </v-subheader>
              <v-textarea
                v-model="comment.content"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="user_manage-accounts__footer px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="save"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  import swal from 'sweetalert2'

  export default {
    name: 'CommentDetails',
    data () {
      return {
        comment: {
          id: null,
          status: true,
          user: '',
          postId: 1,
          content: '',
          error: null,
        },
      }
    },
    watch: {
      'comment.title' (val) {
        this.comment.slug = val.trim().split(' ').join('-')
      },
    },
    mounted () {
      this.comment.id = this.$route.params.id
      if (this.comment.id) {
        this.loadCommentData()
      }
    },
    methods: {
      loadCommentData () {
        axios.get('/admin/blog/posts/comments/show/' + this.comment.id).then((response) => {
          const comment = response.data

          this.comment = {
            id: comment.id,
            status: comment.status,
            user: comment.user,
            post: comment.post,
            content: comment.content,
            error: null,
          }
        }).catch((error) => {
          this.comment.error = error
        })
      },
      save () {
        if (this.comment.id) {
          this.update()
        } else {
          this.create()
        }
      },
      update () {
        const _this = this
        axios.post('/admin/blog/posts/comments/edit', {
          ...this.comment,
        }).then(function (response) {
          swal.fire({
            title: 'Success',
            text: 'Comment updated successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          })

          _this.loadCommentData()
        }).catch(function (error) {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
      },
      create () {
        axios.post('/admin/blog/posts/comments/create', {
          ...this.comment,
        }).then(function (response) {
          swal.fire({
            title: 'Success',
            text: 'Comment created successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          })
        }).catch(function (error) {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
      },
    },
  }
</script>
