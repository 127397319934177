<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      v-if="object"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit Rental #${object.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />
      <div class="pt-6 pb-4 px-6">
        <div class="row">
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rental ID
              </v-subheader>
              <v-text-field
                v-model="object.id"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Leaser ID
              </v-subheader>
              <v-text-field
                v-model="object.leaserId"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Renter ID
              </v-subheader>
              <v-text-field
                v-model="object.renterId"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Object ID
              </v-subheader>
              <v-text-field
                v-model="object.objectId"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rental Status
              </v-subheader>
              <v-text-field
                v-model="object.rentalStatus"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Total Requests
              </v-subheader>
              <v-text-field
                v-model="object.totalRequests"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Start Date
              </v-subheader>
              <v-text-field
                v-model="object.startDate"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                End Date
              </v-subheader>
              <v-text-field
                v-model="object.endDate"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Total Rental Days
              </v-subheader>
              <v-text-field
                v-model="object.totalRentalDays"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rental Fee
              </v-subheader>
              <v-text-field
                v-model="object.rentalFee"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Insurance Fee
              </v-subheader>
              <v-text-field
                v-model="object.insuranceFee"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Gross Rental Fee
              </v-subheader>
              <v-text-field
                v-model="object.insuranceFee"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Net Rental Fee
              </v-subheader>
              <v-text-field
                v-model="object.netRentalFee"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Discount Code
              </v-subheader>
              <v-text-field
                v-model="object.discountCode"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Discount Calculated
              </v-subheader>
              <v-text-field
                v-model="object.discountCalculated"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  import moment from 'moment/moment'
  export default {
    data () {
      return {
        object: null,
      }
    },

    created () {
      axios.get(`/admin/rentals/${this.$route.params.id}`)
        .then(response => {
          const data = response.data
          this.object = {
            id: data.id,
            rentalStatus: data.rentalStatus,
            leaserId: data.leaser.id,
            renterId: data.renter.id,
            objectId: data.product.id,
            objectType: data.product.type.name,
            totalRequests: data.totalRequests,
            startDate: moment(data.startDate).format('YYYY MMM DD'),
            endDate: moment(data.endDate).format('YYYY MMM DD'),
            totalRentalDays: data.totalRentalDays,
            rentalFee: data.rentalFee,
            insuranceFee: data.insuranceFee,
            grossRentalFee: data.rentalGrossFee,
            netRentalFee: data.rentalFeeNet,
            discountCode: data.discountCode,
            discountCalculated: Number(data.discount) + '%',
          }
        })
    },
  }
</script>

<style lang="scss">

</style>
