<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0 user_manage-accounts"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Leaser ID
            </v-subheader>
            <v-text-field
              v-model="filterId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Catalogue Price From
            </v-subheader>
            <v-text-field
              v-model="filterCataloguePriceFrom"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Start Date From
            </v-subheader>
            <v-text-field
              v-model="filterStartDateFrom"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-6">
            <v-subheader class="input-label">
              Leaser Identified
            </v-subheader>
            <v-select
              v-model="filterLeaserIdentified"
              :items="[
                { name: 'All', value: '' },
                { name: 'Yes', value: 'yes' },
                { name: 'No', value: 'no' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
          >
            Apply
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
        @click="rewriteWidths"
      >
        <v-data-table
          v-if="tableUsers"
          ref="usertable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="userHeaders"
          :items="tableUsers"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.id="{ item }">
            <span class="break-content">
              {{ item.id }}
            </span>
          </template>
          <template v-slot:item.leaserName="{ item }">
            <span class="break-content">
              {{ item.leaserName }}
            </span>
          </template>
          <template v-slot:item.action>
            <div class="table-actions">
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-btn"
              >
                <v-icon left>
                  mdi-comment-eye-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        tableUsers: null,
        selected: [],
        filterId: '',
        filterCataloguePriceFrom: '',
        filterStartDateFrom: '',
        filterLeaserIdentified: '',
        appliedFilters: {},
        usersTotal: 0,
        userHeaders: [
          { text: 'Leaser ID', value: 'id', align: 'center', width: '80px' },
          { text: 'Leaser Name', value: 'leaserName', width: '400px' },
          { text: 'Leaser Closed Requests', value: 'leaserClosedRequests', align: 'center', width: '80px' },
          { text: 'Renter Closed Requests', value: 'renterClosedRequests', align: 'center', width: '80px' },
          { text: 'Total Requests', value: 'totalRequests', align: 'center', width: '80px' },
          { text: 'Total Net Rental Fees', value: 'totalNetRentalFees', align: 'center', width: '80px' },
          { text: 'Total Invoice Value', value: 'totalInvoiceValue', align: 'center', width: '80px' },
          { text: 'Total Catalogue Price', value: 'totalCataloguePrice', align: 'center', width: '80px' },
          { text: 'Start Date', value: 'startDate', width: '200px' },
          { text: 'Identification Type', value: 'identificationType', width: '200px' },
          { text: 'ID Due Date', value: 'idDueDate', width: '200px' },
          { text: 'Leaser Identified', value: 'leaserIdentified', align: 'center', width: '80px' },
          { text: 'Identification Link', value: 'identificationLink', width: '80px' },
          { text: 'Verification Tracker', value: 'verificationTracker', width: '80px' },
          { text: 'Action', value: 'action', align: 'center', width: '80px' },
        ],
        users: [
          {
            id: 10650,
            leaserName: 'Andreas Amstutz',
            leaserClosedRequests: 12,
            renterClosedRequests: 4,
            totalRequests: '4 / 2',
            totalNetRentalFees: 260.00,
            totalInvoiceValue: 38.00,
            totalCataloguePrice: 1120.00,
            startDate: '2019 Aug 2',
            identificationType: 'Verification Letter',
            idDueDate: '2019 Jul 30 16:00',
            leaserIdentified: 'No',
            identificationLink: '#',
            verificationTracker: '#',
          },
          {
            id: 10651,
            leaserName: 'Andreas Amstutz 2',
            leaserClosedRequests: 24,
            renterClosedRequests: 6,
            totalRequests: '4 / 2',
            totalNetRentalFees: 280.00,
            totalInvoiceValue: 42.00,
            totalCataloguePrice: 1220.00,
            startDate: '2020 Aug 3',
            identificationType: 'Identification Document',
            idDueDate: '2018 Jul 30 17:00',
            leaserIdentified: 'Yes',
            identificationLink: '#',
            verificationTracker: '#',
          },
          {
            id: 10652,
            leaserName: 'Andreas Amstutz 3',
            leaserClosedRequests: 24,
            renterClosedRequests: 6,
            totalRequests: '4 / 2',
            totalNetRentalFees: 280.00,
            totalInvoiceValue: 42.00,
            totalCataloguePrice: 1220.00,
            startDate: '2020 Aug 3',
            identificationType: 'Identification Document',
            idDueDate: '2018 Jul 30 17:00',
            leaserIdentified: 'Yes',
            identificationLink: '#',
            verificationTracker: '#',
          },
        ],
        localWidths: [],
      }
    },

    watch: {
      filterId: {
        handler () { this.filterUsers('filterId', 'id', 'text') },
      },
      filterCataloguePriceFrom: {
        handler () { this.filterUsers('filterCataloguePriceFrom', 'totalCataloguePrice', 'text') },
      },
      filterStartDateFrom: {
        handler () { this.filterUsers('filterStartDateFrom', 'startDate', 'text') },
      },
      filterLeaserIdentified: {
        handler () { this.filterUsers('filterLeaserIdentified', 'identificationType', 'select') },
      },
      users: {
        immediate: true,
        handler () {
          this.tableUsers = this.users
        },
      },
    },

    created () {
      if (!this.users.length) {
        this.fetchUsers()
      }
    },

    methods: {
      clearFilters () {
        this.filterId = ''
        this.filterCataloguePriceFrom = ''
        this.filterStartDateFrom = ''
        this.filterLeaserIdentified = ''
        this.tableUsers = this.users
      },

      filterUsers (filterName, tableField, type) {
        if (this[filterName]) {
          if (this.appliedFilters[filterName]) {
            this.appliedFilters[filterName].applied = true
          } else {
            this.appliedFilters[filterName] = {
              applied: true,
              tableField: tableField,
              type: type,
            }
          }
        } else {
          this.appliedFilters[filterName].applied = false
        }

        const trigger = Object.keys(this.appliedFilters).filter(el => this.appliedFilters[el].applied)

        if (trigger.length) {
          let filteredUsers = [...this.users]

          Object.keys(this.appliedFilters).forEach(obj => {
            if (this.appliedFilters[obj].applied && this[obj]) {
              filteredUsers = this.filterData(filteredUsers, obj, this.appliedFilters[obj].tableField, this.appliedFilters[obj].type)
            }
          })

          this.tableUsers = filteredUsers
        } else {
          this.tableUsers = this.users
        }
      },

      filterData (data, filterName, tableField, type) {
        let filteredData = data
        switch (type) {
          case 'text':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase().includes(this[filterName].toString().toLowerCase()))
            break
          case 'select':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase() === this[filterName].toString().toLowerCase())
            break
          default:
            break
        }
        return filteredData
      },

      rewriteWidths () {
        const headers = document.querySelectorAll('.v-data-table__wrapper th')
        const widths = []
        headers.forEach(el => {
          if (el.getAttribute('style').includes('width:')) {
            const name = el.querySelector('span').innerText
            const headerItem = this.userHeaders.find(item => item.text === name)
            const width = `${el.offsetWidth}px`
            widths.push({ name: headerItem.value, width })
          }
        })
        if (widths.length > 0) {
          localStorage.setItem('userColumnWidths', JSON.stringify(widths))
        }
      },
    },
  }
</script>
<style lang="scss">

.fixed-columns-table .v-data-table__wrapper table th:nth-child(2),
.fixed-columns-table .v-data-table__wrapper table td:nth-child(2) {
  left: calc(85px) !important;
}
</style>
