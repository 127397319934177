<template>
  <div class="table-wrapper">
    <v-data-table
      :headers="imagesHeaders"
      :items="tableImages"
      item-key="id"
      hide-default-footer
      :item-class="(item) => item.isNew ? 'green lighten-4' : ''"
    >
      <template
        v-slot:body.prepend
      >
        <tr>
          <td
            :colspan="5"
            class="py-4 text-right"
          >
            <v-btn
              color="teal lighten-2"
              class="ma-0"
              @click="uploadImage"
            >
              <v-icon
                left
                color="#fff"
              >
                mdi-plus
              </v-icon>
              Upload a new image
            </v-btn>
            <input
              ref="fileInput"
              type="file"
              style="display: none"
              @change="onFileSelected"
            >
          </td>
        </tr>
      </template>
      <template
        v-slot:item.image="{ item }"
      >
        <v-img
          v-if="!item.isDeleted"
          :lazy-src="item ? item.path : 'https://picsum.photos/id/11/500/300'"
          max-height="300"
          max-width="300"
          :src="item ? item.path : 'https://picsum.photos/id/11/500/300'"
        />
      </template>
      <template v-slot:item.action="{ item }">
        <div
          v-if="!item.isDeleted"
          class="table-actions"
        >
          <v-btn
            color="red"
            elevation="0"
            class="action-btn"
            @click="deleteImage(item)"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    props: {
      postData: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        menu: false,
        post: {
          images: [],
          selectedImages: [],
        },
        tableImages: [],

        tempId: -1,
        imagesHeaders: [
          { text: 'Image', value: 'image', align: 'center', width: '300px', sortable: false },
          { text: 'Action', value: 'action', align: 'center', sortable: false },
        ],
        fileInput: null,
      }
    },
    watch: {
      post: {
        deep: true,
        handler () {
          this.$emit('updatePost', this.post)
          this.tableImages = this.post.images.filter(function (el) {
            if (el instanceof File) {
              return false
            }
            return !el.isDeleted
          })
        },
      },
    },
    mounted () {
      if (this.postData) {
        this.post = this.postData
        this.post.images = this.post.images.map((el) => {
          return {
            id: el.id,
            path: el.path,
            isDeleted: false,
          }
        })
        this.post.selectedImages = []
      }

      if (this.post.images.length === 0) {
        this.post.images.push({
          id: 1,
          path: 'https://picsum.photos/id/11/500/300',
        })
      }

      this.tableImages = this.post.images
    },
    methods: {
      uploadImage () {
        this.$refs.fileInput.click()
      },
      onFileSelected (e) {
        this.post.selectedImages.push({
          id: this.tempId,
          file: e.target.files[0],
        })
        const index = this.post.images.length - 1
        const fileUrl = URL.createObjectURL(e.target.files[0])
        this.post.images.unshift({
          path: fileUrl,
          id: this.tempId,
          isNew: true,
          index: index,
        })

        this.tempId--
      },
      deleteImage (item) {
        if (item.isNew) {
          this.post.images = this.post.images.filter(el => el.id !== item.id)
          this.post.selectedImages = this.post.selectedImages.filter(el => el.id !== item.id)
        } else {
          this.post.images = this.post.images.map(el => {
            if (el.id === item.id) {
              el.isDeleted = true
            }
            return el
          })
        }
      },
    },
  }
</script>

<style lang="sass">
.center-radio
  max-width: 120px
  margin: 0 auto
</style>
