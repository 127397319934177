<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0 user_manage-accounts"
    >
      <div class="table-filters">
        <v-btn
          color="teal lighten-2"
          medium
        >
          Add User Selection
        </v-btn>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="tableUsers"
          ref="usertable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="userHeaders"
          :items="tableUsers"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-select
                :ref="`select-${item.id}`"
                append-icon=""
                :items="['Edit Selection', 'Delete Selection']"
                filled
                color="teal"
                light
                dense
                single-line
                class="action-select"
                @change="userAction($event, item)"
              >
                <template v-slot:label>
                  <v-icon color="white">
                    mdi-cog-outline
                  </v-icon>
                </template>
              </v-select>
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-btn"
              >
                <v-icon left>
                  mdi-database-arrow-right-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        tableUsers: null,
        selected: [],
        appliedFilters: {},
        usersTotal: 0,
        userHeaders: [
          { text: 'Selection ID', value: 'id', align: 'center', width: '80px' },
          { text: 'Title', value: 'title' },
          { text: 'Description', value: 'description' },
          { text: 'Total Users', value: 'totalUsers', align: 'center', width: '80px' },
          { text: 'Action', value: 'action', align: 'center', width: '80px' },
        ],
        users: [
          {
            id: 10651,
            title: 'The title of this selection',
            description: 'The description of this selection',
            totalUsers: 21,
          },
          {
            id: 10652,
            title: 'The title of this selection',
            description: 'The description of this selection',
            totalUsers: 22,
          },
          {
            id: 10653,
            title: 'The title of this selection',
            description: 'The description of this selection',
            totalUsers: 23,
          },
        ],
        localWidths: [],
      }
    },

    watch: {
      users: {
        immediate: true,
        handler () {
          this.tableUsers = this.users
        },
      },
    },

    created () {
      if (!this.users.length) {
        this.fetchUsers()
      }
    },

    methods: {
      userAction (val, user) {
        if (val.toLowerCase() === 'edit selection') {
          this.$router.push(`/users/user-export/${user.id}`)
        }

        this.$refs[`select-${user.id}`].internalValue = []
        this.$refs[`select-${user.id}`].blur()
      },
    },
  }
</script>
