<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0 user_manage-accounts"
    >
      <div class="table-filters">
        <v-btn
          color="teal lighten-2"
          medium
        >
          Add Subscriber
        </v-btn>
        <div class="pt-6">
          <div class="row">
            <div class="col-md-6">
              <v-subheader class="input-label">
                Export From
              </v-subheader>
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    single-line
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                />
              </v-menu>
            </div>
            <div class="col-md-6">
              <v-subheader class="input-label">
                Export To
              </v-subheader>
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    single-line
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                />
              </v-menu>
            </div>
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
          >
            Export XLS
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="tableUsers"
          ref="usertable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="userHeaders"
          :items="tableUsers"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-btn"
                @click="userAction('edit user', item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              </v-btn>

              <v-btn
                color="red"
                elevation="0"
                class="action-btn"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        tableUsers: null,
        selected: [],
        appliedFilters: {},
        usersTotal: 0,
        userHeaders: [
          { text: 'Subscriber ID', value: 'id', align: 'center', width: '80px' },
          { text: 'First Name', value: 'firstName' },
          { text: 'E-mail', value: 'email' },
          { text: 'Created', value: 'created', width: '150px' },
          { text: 'Action', value: 'action', align: 'center', width: '80px' },
        ],
        users: [
          {
            id: 10650,
            firstName: 'Andreas Amstutz',
            email: 'email@example.com',
            created: '2018 Jul 30 13:00',
          },
          {
            id: 10651,
            firstName: 'Andreas Amstutz',
            email: 'email@example.com',
            created: '2018 Jul 30 14:00',
          },
          {
            id: 10652,
            firstName: 'Andreas Amstutz',
            email: 'email@example.com',
            created: '2018 Jul 30 17:00',
          },
        ],
        localWidths: [],
      }
    },

    watch: {
      users: {
        immediate: true,
        handler () {
          this.tableUsers = this.users
        },
      },
    },

    created () {
      if (!this.users.length) {
        this.fetchUsers()
      }
    },

    methods: {
      userAction (val, user) {
        if (val.toLowerCase() === 'edit user') {
          this.$router.push(`/users/user-subscribers/${user.id}`)
        }
      },
    },
  }
</script>
