import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

instance.interceptors.request.use(
  (config) => {
    const storage = localStorage.getItem('vuex')
    let token

    if (storage) {
      token = JSON.parse(storage).auth?.token

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  },
)

export default instance
