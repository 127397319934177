<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      v-if="object"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit Request #${object.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="saveRequest"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
            @click="$router.push('/requests')"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />
      <div class="pt-6 pb-4 px-6">
        <div class="row">
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Request ID
              </v-subheader>
              <v-text-field
                v-model="object.requestId"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Leaser ID
              </v-subheader>
              <v-text-field
                v-model="object.leaserId"
                type="number"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Renter ID
              </v-subheader>
              <v-text-field
                v-model="object.renterId"
                type="number"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Object ID
              </v-subheader>
              <v-text-field
                v-model="object.objectId"
                type="number"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Object Title
              </v-subheader>
              <v-text-field
                v-model="object.objectTitle"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Object Type
              </v-subheader>
              <v-text-field
                v-model="object.objectType"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Start Date
              </v-subheader>
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="object.startDate"
                    single-line
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="object.startDate"
                />
              </v-menu>
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                End Date
              </v-subheader>
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="object.endDate"
                    single-line
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="object.endDate"
                />
              </v-menu>
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Expires In
              </v-subheader>
              <v-text-field
                v-model="object.expiresIn"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Total Rental Days
              </v-subheader>
              <v-text-field
                v-model="object.totalRentalDays"
                type="number"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Renter Activity
              </v-subheader>
              <div class="input-wrap__rows">
                <v-text-field
                  v-model="object.renterActivity1"
                  type="number"
                  single-line
                  outlined
                  dense
                  hide-details
                />
                <v-text-field
                  v-model="object.renterActivity2"
                  type="number"
                  single-line
                  readonly
                  outlined
                  dense
                  hide-details
                />
              </div>
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rental Status
              </v-subheader>
              <v-select
                v-model="object.rentalStatus"
                :items="[
                  { name: 'Open', value: 'Open' },
                  { name: 'Closed', value: 'Closed' },
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Request Status
              </v-subheader>
              <v-select
                v-model="object.requestStatus"
                :items="[
                  { name: 'Aborted', value: 'Aborted' },
                  { name: 'Pending', value: 'Pending' },
                  { name: 'Declined', value: 'Declined' },
                  { name: 'Expired', value: 'Expired' },
                  { name: 'Deleted', value: 'Deleted' },
                  { name: 'accepted', value: 'Cancelled by renter' },
                  { name: 'Cancelled by Leaser', value: 'Cancelled by leaser' },
                  { name: 'Closed', value: 'Closed' },
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Cancel/Decline Reason
              </v-subheader>
              <v-textarea
                outlined
                hide-details
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Payment Type
              </v-subheader>
              <v-text-field
                v-model="object.paymentType"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Credit Payment Status
              </v-subheader>
              <v-text-field
                v-model="object.creditPaymentStatus"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Debit Payment Status
              </v-subheader>
              <v-text-field
                v-model="object.debitPaymentStatus"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rental Fee
              </v-subheader>
              <v-text-field
                v-model="object.rentalFee"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Insurance Fee
              </v-subheader>
              <v-text-field
                v-model="object.insuranceFee"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Gross Rental Fee
              </v-subheader>
              <v-text-field
                v-model="object.grossRentalFee"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Net Rental Fee
              </v-subheader>
              <v-text-field
                v-model="object.netRentalFee"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Discount Calculated
              </v-subheader>
              <v-text-field
                v-model="object.discountCalculated"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Catalogue Price
              </v-subheader>
              <v-text-field
                v-model="object.productPrice"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Created
              </v-subheader>
              <v-text-field
                v-model="object.created"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Last Modified
              </v-subheader>
              <v-text-field
                v-model="object.lastModified"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Accepted
              </v-subheader>
              <v-text-field
                v-model="object.accepted"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                E-Banking Limit
              </v-subheader>
              <v-text-field
                v-model="object.eBankingLimit"
                single-line
                readonly
                outlined
                dense
                hide-details
              />
            </div>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="saveRequest"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
            @click="$router.push('/requests')"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-snackbar
      v-model="showAlertMessage"
      :color="alertColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  import moment from 'moment'

  export default {
    data () {
      return {
        object: null,
        showAlertMessage: false,
        alertColor: 'success',
        message: '',
      }
    },

    created () {
      this.fetchObject()
    },

    methods: {
      fetchObject () {
        const id = this.$route.params.id
        const _this = this
        axios.get('/admin/renting-requests/show/' + id)
          .then(function (response) {
            const o = response.data
            _this.object = {
              assignee: 'Admin',
              taskStatus: o.taskStatus,
              id: o.rentalId,
              requestId: o.id,
              leaserId: o.leaser.id,
              renterId: o.product.user.id,
              objectId: o.product.id,
              objectTitle: o.product.title,
              objectType: o.product.type.name,
              startDate: moment(o.dateFrom).format('YYYY-MM-DD'),
              endDate: moment(o.dateTo).format('YYYY-MM-DD'),
              totalRentalDays: moment(o.dateTo).diff(moment(o.dateFrom), 'days'),
              expiresIn: moment(o.expiredAt).format('YYYY MMM DD'),
              renterActivity1: '0/6',
              renterActivity2: '1/6',
              rentalStatus: o.rentalStatus,
              requestStatus: o.status,
              paymentType: 'Visa',
              creditPaymentStatus: 'Paid',
              debitPaymentStatus: 'Open',
              rentalFee: o.rentingFee,
              insuranceFee: o.insuranceFee,
              grossRentalFee: o.rentingFee,
              netRentalFee: o.rentingFeeNet,
              discountCalculated: o.discountCalculated,
              productPrice: o.product.price,
              created: moment(o.createdAt).format('YYYY-MM-DD H:mm'),
              lastModified: moment(o.updatedAt).format('YYYY-MM-DD H:mm'),
              eBankingLimit: 'None',
              accepted: 'N/A',
            }
          })
          .catch(() => {
            _this.message = 'Something went wrong!'
            _this.alertColor = 'error'
            _this.showAlertMessage = true
          })
      },
      saveRequest () {
        const _this = this
        axios.post('/admin/renting-requests/update/' + this.object.id, {
          status: this.object.requestStatus,
          rentalStatus: this.object.rentalStatus.toLowerCase(),
          startDate: this.object.startDate,
          endDate: this.object.endDate,
        })
          .then(function (response) {
            _this.message = response.data.message
            _this.showAlertMessage = true
            _this.alertColor = 'success'
          })
          .catch(function (error) {
            _this.message = error.response.data.message
            _this.alertColor = 'error'
            _this.showAlertMessage = true
          })
      },
    },
  }
</script>

<style lang="scss">

</style>
