<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      v-if="object"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit Keyword #${object.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="save"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />

      <div
        class="row item-details-page py-5"
      >
        <div class="col-md-8">
          <div class="input-wrap">
            <v-subheader class="input-label">
              Main Keyword
            </v-subheader>
            <v-text-field
              v-model="object.mainKeyword"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="input-wrap">
            <v-subheader class="input-label">
              Meta Keywords
            </v-subheader>
            <v-combobox
              v-model="object.metaKeywords"
              chips
              clearable
              multiple
              solo
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click:close="removeMetaKeywords(item)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </div>
          <div class="input-wrap">
            <v-subheader class="input-label">
              Optional Keywords
            </v-subheader>
            <div class="input-wrap__rows">
              <template v-for="(obj, index) in object.optionalKeywords">
                <v-combobox
                  :key="index"
                  v-model="object.optionalKeywords[index].value"
                  chips
                  clearable
                  multiple
                  solo
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click:close="removeOptionalKeywords(item, index)"
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </template>
            </div>
          </div>
          <v-btn
            color="teal lighten-2"
            class="ma-0 float-right"
            @click="addOptionalKeyword"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-plus
            </v-icon>
            Add Optional Keyword
          </v-btn>
        </div>
      </div>

      <v-divider />
      <div class="px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="save"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
        </div>
      </div>
    </v-card>

    <v-snackbar
      v-model="showMessage"
      :color="messageColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  export default {
    data () {
      return {
        object: {
          id: null,
          mainKeyword: '',
          metaKeywords: '',
          optionalKeywords: [{
            value: [],
          }],
          objectCount: 25,
          requestCount: 132,
          rentalFees: '5,940.00',
          averageRentalFee: '45.00',
        },
        tab: null,

        showMessage: false,
        message: '',
        messageColor: 'success',
      }
    },

    mounted () {
      if (this.$route.params.id) {
        this.object.id = this.$route.params.id
        this.loadKeyword()
      }
    },

    methods: {
      save () {
        if (this.object.id) {
          this.update()
        } else {
          this.create()
        }
      },

      update () {
        const keyword = {
          name: this.object.mainKeyword,
          metaKeywords: this.object.metaKeywords.join(' '),
          optionalKey: this.object.optionalKeywords[0].value.join(' '),
        }
        const _this = this
        axios.put('/admin/product/keywords/update/' + this.object.id, keyword)
          .then(function (response) {
            _this.message = response.data.message
            _this.messageColor = 'success'
            _this.showMessage = true
          }).catch(function (error) {
            _this.message = error
            _this.messageColor = 'error'
            _this.showMessage = true
          })
      },

      create () {
        const keyword = {
          name: this.object.mainKeyword,
          metaKeywords: this.object.metaKeywords.join(' '),
          optionalKey: this.object.optionalKeywords[0].value.join(' '),
        }
        const _this = this
        axios.post('/admin/product/keywords/create', keyword)
          .then(function (response) {
            _this.message = response.data.message
            _this.messageColor = 'success'
            _this.showMessage = true
          }).catch(function (error) {
            _this.message = error
            _this.messageColor = 'error'
            _this.showMessage = true
          })
      },

      loadKeyword () {
        const _this = this
        axios.get('/admin/product/keywords', {
          params: {
            id: this.object.id,
          },
        })
          .then((response) => {
            const data = response.data.data[0]

            _this.object.mainKeyword = data.mainKeyword || ''
            _this.object.metaKeywords = data.metaKeywords.split(' ') || []
            _this.object.optionalKeywords[0].value = data.optionalKey.split(' ') || []
          }).catch((error) => {
            _this.message = error
            _this.messageColor = 'error'
            _this.showMessage = true
          })
      },

      addOptionalKeyword () {
        this.objectOptionalKeywords.push({ value: '' })
      },
      removeMetaKeywords (item) {
        this.objectMetaKeywords.splice(this.objectMetaKeywords.indexOf(item), 1)
        this.objectMetaKeywords = [...this.objectMetaKeywords]
      },
      removeOptionalKeywords (item, index) {
        const obj = this.objectOptionalKeywords[index].value
        obj.splice(this.objectOptionalKeywords[index].value.indexOf(item), 1)
        this.objectOptionalKeywords[index].value = [...obj]
      },
    },
  }
</script>

<style lang="scss">

</style>
