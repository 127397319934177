<template>
  <v-container class="pa-0 container--fluid">
    <v-card class="ma-0">
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-3">
            <v-subheader class="input-label">
              Rental ID
            </v-subheader>
            <v-text-field
              v-model="filterRentalId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-3">
            <v-subheader class="input-label">
              Request ID
            </v-subheader>
            <v-text-field
              v-model="filterRequestId"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-3">
            <v-subheader class="input-label">
              Object Type
            </v-subheader>
            <v-select
              v-model="filterObjectType"
              :items="[
                { name: 'Default', value: 'default' },
                { name: 'Fan', value: 'fan' },
                { name: 'Promo', value: 'promo' },
                { name: 'Postal', value: 'postal' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-3">
            <v-subheader class="input-label">
              Rental Status
            </v-subheader>
            <v-select
              v-model="filterRentalStatus"
              :items="[
                { name: 'Open', value: 'open' },
                { name: 'Closed', value: 'closed' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-3">
            <v-subheader class="input-label">
              Request Status
            </v-subheader>
            <v-select
              v-model="filterRequestStatus"
              :items="[
                { name: 'Aborted', value: 'aborted' },
                { name: 'Pending', value: 'pending' },
                { name: 'Declined', value: 'declined' },
                { name: 'Expired', value: 'expired' },
                { name: 'Deleted', value: 'deleted' },
                { name: 'accepted', value: 'cancelled by renter' },
                { name: 'Cancelled by Leaser', value: 'cancelled by leaser' },
                { name: 'Closed', value: 'closed' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-3">
            <v-subheader class="input-label">
              Payment Type
            </v-subheader>
            <v-select
              v-model="filterPaymentType"
              :items="[
                { name: 'Credit Card', value: 'credit card' },
                { name: 'E-Banking', value: 'e-banking' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-3">
            <v-subheader class="input-label">
              Assignee
            </v-subheader>
            <v-select
              v-model="filterAssignee"
              :items="[
                { name: 'Admin', value: 'admin' },
                { name: 'Super Admin', value: 'super admin' },
                { name: 'System', value: 'system' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-3">
            <v-subheader class="input-label">
              Task Status
            </v-subheader>
            <v-select
              v-model="filterTaskStatus"
              :items="[
                { name: 'Pending', value: 'pending' },
                { name: 'Active', value: 'active' },
                { name: 'Done', value: 'done' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
          >
            Apply
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div class="table-wrapper px-6 py-3">
        <v-data-table
          v-if="tableObjects"
          ref="objecttable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="objectHeaders"
          :items="tableObjects"
          item-key="id"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100],
          }"
          :items-per-page.sync="itemsPerPage"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-select
                :ref="`select-${item.id}`"
                append-icon=""
                :items="[
                  { text: 'Show Info PDF', value: 'Show Info PDF' },
                  { text: 'Conversation', value: 'Conversation' },
                  { text: 'Decline Request', value: 'Decline Request' },
                  { text: 'Delete Request', value: 'Delete Request' },
                ]"
                filled
                color="teal"
                light
                dense
                single-line
                class="action-select"
                @change="objectAction($event, item)"
              >
                <template v-slot:label>
                  <v-icon color="white">
                    mdi-cog-outline
                  </v-icon>
                </template>
              </v-select>
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-edit"
                @click="objectAction('edit object', item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red lighten-1"
                elevation="0"
                class="action-edit"
                @click="deleteRequest(item.requestId)"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-snackbar
      v-model="showAlertMessage"
      :color="alertColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import axios from '@/plugins/api'

  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        itemsPerPage: 20,
        objects: [],
        tableObjects: null,
        selected: [],
        filterRentalId: '',
        filterRequestId: '',
        filterObjectType: '',
        filterRentalStatus: '',
        filterRequestStatus: '',
        filterPaymentType: '',
        filterAssignee: '',
        filterTaskStatus: '',
        appliedFilters: {},
        objectsTotal: 0,
        objectHeaders: [
          {
            text: 'Assignee',
            value: 'assignee',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Task Status',
            value: 'taskStatus',
            align: 'center',
            width: '100px',
          },
          { text: 'Rental ID', value: 'id', align: 'center', width: '100px' },
          {
            text: 'Request ID',
            value: 'requestId',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Leaser ID',
            value: 'leaserId',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Renter ID',
            value: 'renterId',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Object ID',
            value: 'objectId',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Object Title',
            value: 'objectTitle',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Object Type',
            value: 'objectType',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Start Date',
            value: 'startDate',
            align: 'center',
            width: '100px',
          },
          { text: 'End Date', value: 'endDate', align: 'center', width: '100px' },
          {
            text: 'Total Rental Days',
            value: 'totalRentalDays',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Expires In',
            value: 'expiresIn',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Renter Activity',
            value: 'renterActivity',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Rental Status',
            value: 'rentalStatus',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Request Status',
            value: 'requestStatus',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Payment Type',
            value: 'paymentType',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Credit Payment Status',
            value: 'creditPaymentStatus',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Debit Payment Status',
            value: 'debitPaymentStatus',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Rental Fee',
            value: 'rentalFee',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Insurance Fee',
            value: 'insuranceFee',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Gross Rental Fee',
            value: 'grossRentalFee',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Net Rental Fee',
            value: 'netRentalFee',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Discount Calculated',
            value: 'discountCalculated',
            align: 'center',
            width: '100px',
          },
          {
            text: 'Catalogue Price',
            value: 'cataloguePrice',
            align: 'center',
            width: '100px',
          },
          { text: 'Created', value: 'created', align: 'center', width: '100px' },
          {
            text: 'Last Modified',
            value: 'lastModified',
            align: 'center',
            width: '100px',
          },
          { text: 'Action', value: 'action', align: 'center', width: '110px' },
        ],
        localWidths: [],
        loading: false,

        showAlertMessage: false,
        message: '',
        alertColor: 'success',
      }
    },

    watch: {
      filterRentalId: {
        handler () {
          this.loadData()
        },
      },
      filterRequestId: {
        handler () {
          this.loadData()
        },
      },
      filterObjectType: {
        handler () {
          this.loadData()
        },
      },
      filterRentalStatus: {
        handler () {
          this.loadData()
        },
      },
      filterRequestStatus: {
        handler () {
          this.loadData()
        },
      },
      filterPaymentType: {
        handler () {
          this.loadData()
        },
      },
      filterAssignee: {
        handler () {
          this.loadData()
        },
      },
      filterTaskStatus: {
        handler () {
          this.loadData()
        },
      },
      currentPage: {
        handler () {
          this.loadData()
        },
      },
      itemsPerPage: {
        handler () {
          if (this.currentPage !== 1) {
            this.currentPage = 1
          }
          this.loadData()
        },
      },
    },

    created () {
      this.tableObjects = this.objects
      this.loadData()
    },

    methods: {
      objectAction (val, object) {
        if (val.toLowerCase() === 'edit object') {
          this.$router.push(`requests/${object.requestId}`)
        }

        this.$refs[`select-${object.id}`].internalValue = []
        this.$refs[`select-${object.id}`].blur()
      },
      clearFilters () {
        this.filterRentalId = ''
        this.filterRequestId = ''
        this.filterObjectType = ''
        this.filterRentalStatus = ''
        this.filterRequestStatus = ''
        this.filterPaymentType = ''
        this.filterAssignee = ''
        this.filterTaskStatus = ''
        this.tableObjects = this.objects
      },
      loadData () {
        this.loading = true

        const filters = {
          rentalId: this.filterRentalId,
          requestId: this.filterRequestId,
          rentalStatus: this.filterRentalStatus,
          objectType: this.filterObjectType,
          requestStatus: this.filterRequestStatus,
          paymentType: this.filterPaymentType,
          assignee: this.filterAssignee,
          taskStatus: this.filterTaskStatus,
          page: this.currentPage,
          perPage: this.itemsPerPage,
        }
        const _this = this
        axios
          .get('/admin/renting-requests', { params: filters })
          .then((response) => {
            const data = response.data.requests
            const total = response.data.total
            _this.objects = data.map((item) => {
              return {
                assignee: 'Admin',
                taskStatus: 'Task status pending',
                id: item.rentalId,
                requestId: item.id,
                leaserId: item.leaser.id,
                renterId: item.product.user.id,
                objectId: item.product.id,
                objectTitle: item.product.title,
                objectType: item.product.type.name,
                startDate: moment(item.dateFrom).format('YYYY MMM DD'),
                endDate: moment(item.dateTo).format('YYYY MMM DD'),
                totalRentalDays: 3,
                expiresIn: moment(item.expiredAt).format('YYYY MMM DD'),
                renterActivity: '0/6',
                rentalStatus: item.rentalStatus,
                requestStatus: item.status,
                paymentType: 'Visa',
                creditPaymentStatus: 'Paid',
                debitPaymentStatus: 'Open',
                rentalFee: item.rentingFee,
                insuranceFee: item.insuranceFee,
                grossRentalFee: item.rentingFee,
                netRentalFee: item.rentingFeeNet,
                discountCalculated: item.discount,
                cataloguePrice: item.product.price,
                created: moment(item.createdAt).format('YYYY MMM DD H:mm'),
                lastModified: item.updatedAt
                  ? moment(item.updatedAt).format('YYYY MMM DD H:mm')
                  : null,
              }
            })

            _this.pageCount = Math.round(total / _this.itemsPerPage)
            _this.tableObjects = _this.objects
            _this.loading = false
          })
          .catch(() => {
            _this.loading = false
          })
      },
      deleteRequest (id) {
        if (
          !confirm(`Are you sure you want to delete this request with id: ${id}?`)
        ) {
          return
        }
        const _this = this
        _this.loading = true
        axios
          .post('/admin/renting-requests/delete', { id })
          .then((response) => {
            _this.message = response.data.message
            _this.showAlertMessage = true
            _this.alertColor = 'success'
            _this.loadData()
          })
          .catch((err) => {
            _this.loading = false
            _this.message = err.response.data.message
            _this.showAlertMessage = true
            _this.alertColor = 'danger'
          })
      },
    },
  }
</script>
