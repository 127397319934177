<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Post ID
            </v-subheader>
            <v-text-field
              v-model="filterPostId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Title
            </v-subheader>
            <v-text-field
              v-model="filterTitle"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Post Status
            </v-subheader>
            <v-select
              v-model="filterStatus"
              :items="[
                { name: 'All', value: '' },
                { name: 'DRAFT', value: 1 },
                { name: 'Inactive', value: 2 },
                { name: 'Active', value: 3 },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
            @click="createPostPage"
          >
            Create a new Post
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="alert('hala')"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="posts"
          ref="posts"
          :page.sync="currentPage"
          :headers="postHeaders"
          :items="posts"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100],
          }"
          :items-per-page.sync="itemsPerPage"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              color="teal lighten-2"
              elevation="0"
              class="action-edit"
              small
              @click="$router.push('/blog-posts/posts/edit/' + item.id)"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              color="red"
              elevation="0"
              class="action-delete"
              small
              @click="deletePost(item.id)"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-snackbar
      v-model="showMessage"
      :color="mesageColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  import moment from 'moment'

  export default {
    name: 'Posts',
    data () {
      return {
        showMessage: false,
        message: '',
        mesageColor: 'success',
        posts: [
          {
            id: '',
            title: '',
            status: '',
            createdAt: '',
            publishedAt: '',
          },
        ],
        postHeaders: [
          { text: 'ID', value: 'id' },
          { text: 'Title', value: 'title' },
          { text: 'Status', value: 'status' },
          { text: 'Created At', value: 'createdAt' },
          { text: 'Published At', value: 'publishedAt' },
          { text: 'Action', value: 'action' },
        ],
        currentPage: 1,
        itemsPerPage: 10,
        pageCount: 1,
        nextPage: null,
        prevPage: null,

        filterPostId: null,
        filterStatus: null,
        filterTitle: null,
        filterCategory: null,
      }
    },
    watch: {
      filterPostId: {
        handler () { this.loadPosts() },
      },
      filterTitle: {
        handler () { this.loadPosts() },
      },
      filterStatus: {
        handler () { this.loadPosts() },
      },
      currentPage: {
        handler () { this.loadPosts() },
      },
      itemsPerPage: {
        handler () { this.loadPosts() },
      },
    },
    mounted () {
      this.filterCategory = this.$route.query.category

      this.loadPosts()
    },
    methods: {
      deletePost (id) {
        const _this = this
        axios.get('/admin/blog/posts/delete/' + id).then(function (response) {
          _this.message = response.data.message
          _this.mesageColor = 'success'
          _this.showMessage = true

          _this.loadPosts()
        }).catch(function (error) {
          _this.message = error.response.data
          _this.mesageColor = 'error'
          _this.showMessage = true
        })
      },
      loadPosts () {
        const _this = this
        const filters = {
          postId: this.filterPostId,
          status: this.filterStatus,
          title: this.filterTitle,
          page: this.currentPage,
          perPage: this.itemsPerPage,
          category: this.filterCategory,
        }

        Object.keys(filters).forEach(function (key) {
          if ((filters[key] === null || filters[key] === '') && filters[key] !== 0) {
            delete filters[key]
          }
        })

        axios.get('/admin/blog/posts', { params: filters }).then(function (response) {
          const data = response.data

          const total = data.total
          _this.pageCount = Math.ceil(total / _this.itemsPerPage)
          if (_this.currentPage > _this.pageCount) {
            _this.currentPage = _this.pageCount
          }

          _this.posts = data.posts.map(function (post) {
            return {
              id: post.id,
              title: post.title,
              status: post.status,
              createdAt: moment(post.createdAt.date).format('YYYY-MM-DD HH:mm:ss'),
              publishedAt: moment(post.publishedAt.date).format('YYYY-MM-DD HH:mm:ss'),
            }
          })
        }).catch(function (error) {
          _this.message = error.response.data
          _this.mesageColor = 'error'
          _this.showMessage = true
        })
      },
      createPostPage () {
        this.$router.push('/blog-posts/posts/create')
      },
    },
  }
</script>

<style scoped lang="scss">
</style>
