<template>
  <div class="test-page">
    <v-container
      fluid
      class="pa-0 item-details-page"
    >
      <v-card
        class="ma-0 pa-2"
      >
        <div
          v-if="categories.length"
          class="categories-wrap"
        >
          <draggable
            v-model="categories"
            :options="{ group: 'top-level' }"
          >
            <v-list-group
              v-for="cat in categories"
              :key="cat.id"
              no-action
              :sub-group="!!(cat.subcategories && cat.subcategories.length)"
            >
              <template
                v-slot:activator
              >
                <v-list-item-content>
                  <v-list-item-title>{{ cat.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <draggable
                v-if="cat.subcategories && cat.subcategories.length"
                v-model="cat.subcategories"
                :options="{ group: `sub-level-${cat.name.replace(' ', '_')}` }"
              >
                <v-list-item
                  v-for="subcat in cat.subcategories"
                  :key="subcat.id"
                >
                  <v-list-item-title>{{ subcat.name }}</v-list-item-title>
                  <v-select
                    :ref="`actions-${subcat.id}`"
                    append-icon=""
                    :items="['Edit category', 'Delete Category', 'Deactivate Category']"
                    filled
                    light
                    dense
                    single-line
                    class="action-select primary-select"
                    @change="userAction($event, subcat)"
                  >
                    <template v-slot:label>
                      <v-icon color="white">
                        mdi-cog-outline
                      </v-icon>
                    </template>
                  </v-select>
                </v-list-item>
              </draggable>
            </v-list-group>
          </draggable>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import draggable from 'vuedraggable'

  export default {
    components: {
      draggable,
    },
    data () {
      return {
        dragOptions: {},
        categories: [],
      }
    },
    computed: {
      // categories: {
      //   get () {
      //     return this.$store.state.categories.categories
      //   },
      //   set (val) {
      //     return this.$store.commit('SET_CATEGORIES', { categories: val })
      //   },
      // },
      ...mapState({
        mappedCategories: state => state.categories.categories,
      }),
    },
    watch: {
      mappedCategories: {
        immediate: true,
        deep: true,
        handler (val) {
          if (val.length) {
            this.categories = val.map(cat => {
              const res = {}
              if (cat.subcategories?.length) {
                res.subcategories = []
                cat.subcategories.forEach(subcat => {
                  res.subcategories.push(subcat)
                })
              }

              Object.keys(cat).forEach(key => {
                if (key !== 'subcategories') {
                  res[key] = cat[key]
                }
              })

              return res
            })
          }
        },
      },
    },
    created () {
      if (!this.categories?.length) {
        this.getCategories()
      }
    },

    methods: {
      ...mapActions({
        getCategories: 'getCategories',
      }),
      userAction (e, cat) {
        this.$refs[`actions-${cat.id}`][0].internalValue = []
        this.$refs[`actions-${cat.id}`][0].blur()
      },
    },
  }
</script>

<style lang="scss">

</style>
