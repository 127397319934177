<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      v-if="object"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit Rating #${object.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />
      <div class="pt-6 pb-4 px-6">
        <div class="row">
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rating ID
              </v-subheader>
              <v-text-field
                v-model="object.id"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rater ID
              </v-subheader>
              <v-text-field
                v-model="object.raterId"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Ratee ID
              </v-subheader>
              <v-text-field
                v-model="object.rateeId"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rental ID
              </v-subheader>
              <v-text-field
                v-model="object.rentalId"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Object ID
              </v-subheader>
              <v-text-field
                v-model="object.objectId"
                single-line
                readonly
                type="number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Created
              </v-subheader>
              <v-text-field
                v-model="object.created"
                readonly
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rating Date
              </v-subheader>
              <v-text-field
                v-model="object.ratingDate"
                readonly
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Modified
              </v-subheader>
              <v-text-field
                v-model="object.modified"
                readonly
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Modified By
              </v-subheader>
              <v-text-field
                v-model="object.modifiedBy"
                readonly
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rating
              </v-subheader>
              <v-text-field
                v-model="object.rating"
                :rules="ratingRules"
                single-line
                type="number"
                min="0"
                max="5"
                outlined
                dense
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Rating Status
              </v-subheader>
              <v-select
                v-model="object.ratingStatus"
                :items="[{ text: 'Open', value: 'open' }, { text: 'Closed', value: 'closed' }]"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Comment
              </v-subheader>
              <v-textarea
                v-model="object.comment"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        ratingRules: [
          v => (v && v >= 1) || 'Rating should be above 1',
          v => (v && v <= 5) || 'Max should not be above 5',
        ],
        objects: [
          {
            id: '200',
            raterId: '201',
            rateeId: '202',
            rentalId: '203',
            objectId: '204',
            rating: '4',
            comment: 'Optional comment',
            ratingStatus: 'Closed',
            created: '2018 Jul 30 13:00',
            ratingDate: '2018 Jul 30 13:00',
            modified: '2020 Aug 2, 18:02',
            modifiedBy: 'Andreas Amstutz',
          },
          {
            id: '300',
            raterId: '301',
            rateeId: '302',
            rentalId: '303',
            objectId: '304',
            rating: '5',
            comment: 'Optional comment',
            ratingStatus: 'Open',
            created: '2018 Jul 30 13:00',
            ratingDate: '2018 Jul 30 13:00',
            modified: '2020 Aug 2, 18:02',
            modifiedBy: 'Andreas Amstutz',
          },
          {
            id: '400',
            raterId: '401',
            rateeId: '402',
            rentalId: '403',
            objectId: '404',
            rating: '3',
            comment: 'Optional comment',
            ratingStatus: 'Closed',
            created: '2018 Jul 30 13:00',
            ratingDate: '2018 Jul 30 13:00',
            modified: '2020 Aug 2, 18:02',
            modifiedBy: 'Andreas Amstutz',
          },
        ],
        object: null,
      }
    },

    created () {
      if (this.$route.query.activeTab) {
        this.tab = this.tabs.findIndex(el => el.name.toLowerCase() === this.$route.query.activeTab.toLowerCase())
      }
      if (this.objects.length && this.objects.find(el => el.id == this.$route.params.id)) {
        this.object = this.objects.find(el => el.id == this.$route.params.id)
      }
    },
  }
</script>

<style lang="scss">

</style>
