<template>
  <div
    class="row item-details-page"
  >
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Street
        </v-subheader>
        <v-text-field
          v-model="object.user.address.street"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          House Number
        </v-subheader>
        <v-text-field
          v-model="object.user.address.number"
          type="number"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Zip Code
        </v-subheader>
        <v-text-field
          v-model="object.user.address.zip"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          City
        </v-subheader>
        <v-text-field
          v-model="object.user.address.city"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Minimum Rental Period (days)
        </v-subheader>
        <v-text-field
          v-model="object.price.rentingDayMin"
          type="number"
          min="0"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Maximum Rental Period (days)
        </v-subheader>
        <v-text-field
          v-model="object.price.rentingDayMax"
          type="number"
          :min="object.price.rentingDayMax"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Description
        </v-subheader>
        <tiptap-vuetify
          v-model="object.description"
          :extensions="extensions"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { TiptapVuetify, Bold, BulletList, ListItem } from 'tiptap-vuetify'
  import countries from '@/Utils/countries'
  export default {
    components: { TiptapVuetify },
    mixins: [validationMixin],
    props: {
      objectData: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        extensions: [
          Bold,
          BulletList,
          ListItem,
        ],
        object: {
          user: {
            address: {
              street: '',
              number: '',
              zip: '',
              city: '',
            },
          },
          price: {
            rentingDayMin: '',
            rentingDayMax: '',
          },
          description: '',
        },
        countries: this.countriesList(),
      }
    },
    watch: {
      object: {
        deep: true,
        handler () {
          this.$emit('updateObject', this.object)
        },
      },
    },
    created () {
      if (this.objectData) {
        this.object = this.objectData
      }
    },
    methods: {
      countriesList () {
        return countries
      },
    },
  }
</script>
