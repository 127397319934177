<template>
  <v-app
    :style="{ backgroundImage: 'url(' + background + ')' }"
  >
    <div
      class="login"
    >
      <v-container>
        <v-form
          ref="loginForm"
          v-model="formValid"
          class="login__form"
        >
          <div class="login__logo">
            <img
              :src="logo"
              alt="Sharely"
              title="Sharely"
            >
          </div>
          <div class="login__head">
            <h1 class="login__title">
              Welcome to Sharely Admin Panel
            </h1>
          </div>
          <div class="input-column ma-0">
            <v-text-field
              v-model="login"
              :rules="loginRules"
              filled
              label="Login"
              required
              @keyup.enter="loginUser"
            />
          </div>
          <div class="input-column ma-0">
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              type="password"
              filled
              label="Password"
              required
              @keyup.enter="loginUser"
            />
          </div>
          <p
            v-if="showError"
            class="form-error"
          >
            {{ errorMessage }}
          </p>
          <v-card-actions
            class="justify-end"
          >
            <v-btn
              class="ma-0"
              color="teal lighten-2"
              @click="loginUser"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </div>
  </v-app>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    data () {
      return {
        formValid: false,
        login: '',
        password: '',
        loginRules: [
          l => !!l || 'Login is required',
          l => l.length > 3 || 'Login must be at least 4 characters',
        ],
        passwordRules: [
          p => !!p || 'Password is required',
        ],
        showError: false,
        errorMessage: '',
        background: require('@/assets/images/backgrounds/login-page-bg.jpg'),
        logo: require('@/assets/images/site/logo.svg'),
      }
    },

    computed: {
      ...mapState({
        isAuth: state => state.auth.isAuthenticated,
        authError: state => state.auth.authFailedError,
      }),
    },

    watch: {
      isAuth (newVal) {
        if (newVal) {
          this.$router.push(this.$route.params.redirect || '/')
        }
      },
      authError (newVal) {
        if (newVal) {
          this.showError = true
          this.errorMessage = newVal
        }
      },
    },

    created () {
      window.localStorage.clear()
    },

    methods: {
      ...mapActions(['authenticate', 'setToken', 'fetchUserData']),
      async loginUser () {
        if (this.formValid) {
          await this.authenticate({ login: this.login, password: this.password })
          this.$router.push('/')
        } else {
          this.$refs.loginForm.validate()
        }
      },
    },
  }
</script>

<style lang="sass">
.login
  padding: 78px 26px 13px 26px
  margin: 0 auto 26px
  height: 100%
  width: 100%
  .v-input__slot
    margin: 0
  img
    max-height: 44px

  .v-text-field--filled .v-label
    top: 14px
.login__head
  margin-top: 13px
  margin-bottom: 39px
.login__title
  text-align: center
  font-size: 1.5rem
  font-weight: 500
  color: #595d6e
.login__logo
  margin: 0 auto 43px
  text-align: center
.login__form
  max-width: 450px
  margin: 0 auto

@media screen and (max-width: 767px)
  .login
    padding-left: 0
    padding-right: 0
</style>
