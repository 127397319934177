<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
            @click="createCategoryPage"
          >
            Create a new category
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="categories"
          ref="categories"
          :page.sync="currentPage"
          :headers="categoryHeaders"
          :items="categories"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100],
          }"
          :items-per-page.sync="itemsPerPage"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              color="teal lighten-2"
              elevation="0"
              class="action-edit"
              small
              @click="$router.push('/blog-categories/edit/' + item.id)"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              color="red"
              elevation="0"
              class="action-delete"
              small
              @click="deleteCategory(item.id)"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn
              color="blue"
              elevation="0"
              class="action-show"
              small
              @click="showPosts(item.id)"
            >
              <v-icon left>
                mdi-animation
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-snackbar
      v-model="showMessage"
      :color="mesageColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'

  export default {
    name: 'Categories',
    data () {
      return {
        showMessage: false,
        message: '',
        messageColor: 'success',
        categories: [
          {
            id: '',
            title: '',
            slug: '',
          },
        ],
        categoryHeaders: [
          { text: 'ID', value: 'id' },
          { text: 'Title', value: 'title' },
          { text: 'Slug', value: 'slug' },
          { text: 'Action', value: 'action' },
        ],
        currentPage: 1,
        itemsPerPage: 10,
        pageCount: 1,
        nextPage: null,
        prevPage: null,
      }
    },
    watch: {
      currentPage: {
        handler () { this.loadCategories() },
      },
      itemsPerPage: {
        handler () { this.loadCategories() },
      },
    },
    mounted () {
      this.loadCategories()
    },
    methods: {
      deleteCategory (id) {
        const _this = this
        axios.get('/admin/blog/posts/categories/delete/' + id).then(function (response) {
          _this.message = response.data.message
          _this.mesageColor = 'success'
          _this.showMessage = true

          _this.loadCategories()
        }).catch(function (error) {
          _this.message = error.response.data
          _this.mesageColor = 'error'
          _this.showMessage = true
        })
      },
      loadCategories () {
        const _this = this
        const filters = {
          page: this.currentPage,
          perPage: this.itemsPerPage,
        }

        axios.get('/admin/blog/posts/categories', { params: filters }).then(function (response) {
          const data = response.data

          const total = data.total
          _this.pageCount = Math.ceil(total / _this.itemsPerPage)
          if (_this.currentPage > _this.pageCount) {
            _this.currentPage = _this.pageCount
          }

          _this.categories = data.categories.map(function (category) {
            return {
              id: category.id,
              title: category.title,
              slug: category.slug,
            }
          })
        }).catch(function (error) {
          _this.message = error.response.data
          _this.mesageColor = 'error'
          _this.showMessage = true
        })
      },
      createCategoryPage () {
        this.$router.push('/blog-categories/create')
      },
      showPosts (id) {
        this.$router.push('/blog-posts?category=' + id)
      },
    },
  }
</script>

<style scoped lang="scss">
</style>
