<template>
  <div class="row">
    <div class="col-md-8">
      <div
        class="input-wrap"
        :class="{ 'input-error': $v.email.$invalid }"
      >
        <v-subheader class="input-label">
          User Name
        </v-subheader>
        <v-text-field
          v-model="email"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('email')"
        />
        <div
          v-if="$v.email.$invalid"
          class="input-error-container"
        >
          <p
            v-if="!$v.email.required"
            class="error-message"
          >
            This field is required
          </p>
          <p
            v-if="!$v.email.email"
            class="error-message"
          >
            Email format is invalid
          </p>
        </div>
      </div>
      <div
        class="input-wrap"
        :class="{ 'input-error': $v.userPassword.$invalid }"
      >
        <v-subheader class="input-label">
          Password
        </v-subheader>
        <v-text-field
          v-model="userPassword"
          :type="userPasswordHidden ? 'password' : 'text'"
          :append-icon="userPasswordHidden ? 'mdi-eye' : 'mdi-eye-off'"
          single-line
          outlined
          dense
          hide-details
          @click:append="() => (userPasswordHidden = !userPasswordHidden)"
          @input="checkPassword"
        />
        <div
          v-if="$v.userPassword.$invalid"
          class="input-error-container"
        >
          <p
            v-if="!$v.userPassword.minLength"
            class="error-message"
          >
            Should be at least 6 characters
          </p>
          <p
            v-if="!$v.userPassword.passwordRules"
            class="error-message"
          >
            Should include at least 1 uppercase character and 1 numeric value
          </p>
        </div>
      </div>
      <div
        class="input-wrap"
        :class="{ 'input-error': $v.userPasswordConfirm.$invalid }"
      >
        <v-subheader class="input-label">
          Password Confirm
        </v-subheader>
        <v-text-field
          v-model="userPasswordConfirm"
          :type="userPasswordConfirmHidden ? 'password' : 'text'"
          :append-icon="userPasswordConfirmHidden ? 'mdi-eye' : 'mdi-eye-off'"
          single-line
          outlined
          dense
          hide-details
          @click:append="() => (userPasswordConfirmHidden = !userPasswordConfirmHidden)"
          @input="checkPassword"
        />
        <div
          v-if="$v.userPasswordConfirm.$invalid"
          class="input-error-container"
        >
          <p
            v-if="!$v.userPasswordConfirm.required"
            class="error-message"
          >
            This field is required
          </p>
          <p
            v-if="!$v.userPasswordConfirm.sameAs"
            class="error-message"
          >
            Should be same as password
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, requiredIf, email } from 'vuelidate/lib/validators'
  const passwordRules = (value) => value.length ? /(?=.*[A-Z])(?=.*[0-9])/.test(value) : true

  export default {
    mixins: [validationMixin],
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        userPassword: '',
        userPasswordHidden: true,
        userPasswordConfirm: '',
        userPasswordConfirmHidden: true,
        email: '',
      }
    },
    created () {
      if (this.user) {
        this.email = this.user.email
      }
    },
    validations () {
      return {
        email: { required, email },
        userPassword: { minLength: v => v.length ? v.length > 5 : true, passwordRules },
        userPasswordConfirm: { required: requiredIf(() => this.userPassword.length), sameAsPassword: v => this.userPassword.length ? v === this.userPassword : true },
      }
    },
    methods: {
      emitChanges (field) {
        const data = {
          tabName: 'credentials',
          data: {},
        }
        if (this.$v[field] && this.$v[field].$invalid) {
          data.remove = field
        } else {
          if (field === 'password') {
            data.data.userCredentials = {
              password: this.userPassword,
            }
          }
          data.data[field] = this[field]
        }
        this.$emit('emitChanges', data)
      },
      checkPassword () {
        if (this.userPassword.length && !this.$v.userPassword.$invalid && !this.$v.userPasswordConfirm.$invalid) {
          this.emitChanges('password')
        }
      },
    },
  }
</script>
