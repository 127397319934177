var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 container--fluid"},[_c('v-card',{staticClass:"ma-0"},[_c('div',{staticClass:"table-filters"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg col-md-4"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Post ID ")]),_c('v-text-field',{attrs:{"type":"number","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterPostId),callback:function ($$v) {_vm.filterPostId=$$v},expression:"filterPostId"}})],1),_c('div',{staticClass:"col-lg col-md-4"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Title ")]),_c('v-text-field',{attrs:{"single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterTitle),callback:function ($$v) {_vm.filterTitle=$$v},expression:"filterTitle"}})],1),_c('div',{staticClass:"col-lg col-md-4"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Post Status ")]),_c('v-select',{attrs:{"items":[
              { name: 'All', value: '' },
              { name: 'DRAFT', value: 1 },
              { name: 'Inactive', value: 2 },
              { name: 'Active', value: 3 },
            ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1)]),_c('div',{staticClass:"table-filters__controls"},[_c('v-btn',{attrs:{"color":"teal lighten-2","medium":""},on:{"click":_vm.createPostPage}},[_vm._v(" Create a new Post ")]),_c('v-btn',{attrs:{"color":"red","medium":""},on:{"click":function($event){return _vm.alert('hala')}}},[_vm._v(" Reset ")])],1)]),_c('div',{staticClass:"table-wrapper px-6 py-3"},[(_vm.posts)?_c('v-data-table',{ref:"posts",staticClass:"fixed-columns-table",attrs:{"page":_vm.currentPage,"headers":_vm.postHeaders,"items":_vm.posts,"item-key":"id","footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 20, 50, 100],
        },"items-per-page":_vm.itemsPerPage,"fixed-header":true},on:{"update:page":function($event){_vm.currentPage=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"page-count":_vm.pageCount},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No data ")]},proxy:true},{key:"item.action",fn:function({ item }){return [_c('v-btn',{staticClass:"action-edit",attrs:{"color":"teal lighten-2","elevation":"0","small":""},on:{"click":function($event){return _vm.$router.push('/blog-posts/posts/edit/' + item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"action-delete",attrs:{"color":"red","elevation":"0","small":""},on:{"click":function($event){return _vm.deletePost(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"table-pagination",attrs:{"length":_vm.pageCount,"total-visible":"7"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})]},proxy:true}],null,false,3956351494)}):_vm._e()],1)]),_c('v-snackbar',{attrs:{"color":_vm.mesageColor,"timeout":"3500"},model:{value:(_vm.showMessage),callback:function ($$v) {_vm.showMessage=$$v},expression:"showMessage"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }