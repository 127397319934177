import api from '@/plugins/api'

export default {
  state: {
    users: [],
  },
  mutations: {
    SET_USERS (state, newUsers) {
      state.users = newUsers
    },
    ADD_USER (state, user) {
      const index = state.users.findIndex(u => u.id === user.id)
      if (index < 0) {
        state.users.push(user)
      } else {
        state.users.splice(index, 1, user)
      }
    },
    EDIT_USER (state, user) {
      const index = state.users.findIndex(item => item.id === user.id)
      state.users[index] = user
    },
    DISABLE_USER (state, id) {
      const user = state.users.find(el => el.id === id)
      user.status = 'deleted'
      user.deletedAt = new Date().toISOString()
    },
    RESTORE_USER (state, id) {
      const user = state.users.find(el => el.id === id)
      user.status = 'active'
      user.deletedAt = null
    },
  },
  actions: {
    fetchUsers ({ commit }) {
      return api.get('admin/users?page=1&perPage=100000')
        .then(res => {
          const tableUsers = res.data.users.map(el => {
            // if (!el.userInfo && Math.round(Math.random())) {
            //   el.userInfo = [
            //     { id: 100, reminderDate: '16.09.2021', reminderTime: '10:10:00', created: '02.06.2021', createdBy: 'Admin', info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod quis nostrud exercitation ullamco laboris nisi ut aliquip' },
            //     { id: 101, reminderDate: '15.08.2021', reminderTime: '12:30:00', created: '02.06.2021', createdBy: 'User', info: 'Lorem ipsum dolor sit amet tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip' },
            //     { id: 102, reminderDate: '13.08.2021', reminderTime: '15:45:00', created: '02.06.2021', createdBy: 'User-Admin', info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip' },
            //     { id: 103, reminderDate: '08.10.2021', reminderTime: '18:00:00', created: '02.06.2021', createdBy: 'Admin', info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
            //   ]
            // } else if (!el.userInfo) {
            //   el.userInfo = []
            // }
            // el.idleCount = el.idleCount || Math.round(Math.random() * 10)
            return el
          }).sort((a, b) => b.id - a.id)
          commit('SET_USERS', tableUsers)
        })
    },
    setUsers ({ commit }, users) {
      commit('SET_USERS', users)
    },
    editUser ({ commit }, user) {
      return api.put(`admin/users/${user.id}`, user)
        .then(() => {
          api.get(`admin/users/${user.id}`)
            .then(res => {
              commit('EDIT_USER', res.data)
            })
        })
    },
    addUser ({ commit }, user) {
      commit('ADD_USER', user)
    },
    disableUser ({ commit }, id) {
      api.delete(`admin/users/${id}`)
        .then(() => {
          commit('DISABLE_USER', id)
        })
        // .catch(err => {
        //   console.log(err)
        // })
    },
    restoreUser ({ commit }, id) {
      api.put(`admin/users/${id}/restore`, { id })
        .then(() => {
          commit('RESTORE_USER', id)
        })
        // .catch(err => {
        //   console.log(err)
        // })
    },
    fetchUserById ({ commit }, id) {
      api.get(`admin/users/${id}`)
        .then(res => {
          commit('ADD_USER', res.data)
        })
    },

    // UserInfo
    createUserInfo ({ dispatch }, info) {
      api.post(`admin/users/${info.userId}/info`, info)
        .then(() => {
          dispatch('fetchUserById', info.userId)
        })
    },
    editUserInfo ({ dispatch }, info) {
      api.put(`admin/users/info/${info.id}`, info)
        .then(() => {
          dispatch('fetchUserById', info.userId)
        })
    },
    deleteUserInfo ({ dispatch }, id) {
      api.delete(`admin/users/info/${id.infoId}`)
        .then(() => {
          dispatch('fetchUserById', id.userId)
        })
    },
  },
}
