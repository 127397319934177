<template>
  <div>
    <v-data-table
      :headers="requestsHeaders"
      :items="requestsObj"
      item-key="id"
      hide-default-footer
    >
      <template v-slot:item.rentings="{ item }">
        <a
          href="#"
          target="_blank"
          class="link__hidden"
        >
          {{ item.rentings }}
        </a>
      </template>
      <template v-slot:item.failed_rentings="{ item }">
        <a
          href="#"
          target="_blank"
          class="link__hidden"
        >
          {{ item.failed_rentings }}
        </a>
      </template>
      <template v-slot:item.leasings="{ item }">
        <a
          href="#"
          target="_blank"
          class="link__hidden"
        >
          {{ item.leasings }}
        </a>
      </template>
      <template v-slot:item.failed_leasings="{ item }">
        <a
          href="#"
          target="_blank"
          class="link__hidden"
        >
          {{ item.failed_leasings }}
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    props: {
      user: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        requestsObj: null,
        requestsHeaders: [
          { text: 'Successful Rentings', value: 'rentings', align: 'center', sortable: false },
          { text: 'Failed Rentings', value: 'failed_rentings', align: 'center', sortable: false },
          { text: 'Successful Leasings', value: 'leasings', align: 'center', sortable: false },
          { text: 'Failed Leasings', value: 'failed_leasings', align: 'center', sortable: false },
        ],
      }
    },

    created () {
      this.requestsObj = [{
        id: 1,
        rentings: this.user.rentings || Math.round(Math.random() * (this.user.requests * 0.7)),
        failed_rentings: this.user.failed_rentings || Math.round(this.user.requests * 0.1),
        leasings: this.user.leasings || Math.round(Math.random() * (this.user.requests * 0.3)),
        failed_leasings: this.user.failed_leasings || Math.round(this.user.requests * 0.1),
      }]
    },
  }
</script>

<style lang="sass">

</style>
