<template>
  <div
    class="row item-details-page"
  >
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Title
        </v-subheader>
        <v-text-field
          v-model="object.title"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Object Status
        </v-subheader>
        <v-select
          v-model="object.status"
          :items="[
            { text: 'Not Complete', value: 'notComplete' },
            { text: 'Pending', value: 'pending' },
            { text: 'Declined', value: 'declined' },
            { text: 'Active', value: 'active' },
            { text: 'Inactive', value: 'inactive' },
            { text: 'Disabled', value: 'disabled' },
            { text: 'Paused', value: 'paused' },
            { text: 'Blocked', value: 'blocked' },
            { text: 'Idle', value: 'idle'},
          ]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Object Flag
        </v-subheader>
        <v-select
          v-model="object.flag"
          :items="[
            { text: 'Edited', value: 'Edited' },
            { text: 'Paused', value: 'Paused' },
            { text: 'None', value: 'None' },
          ]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Object Type
        </v-subheader>
        <v-select
          v-model="object.type.name"
          :items="[
            { text: 'Default', value: 'TYPE_DEFAULT' },
            { text: 'Fan', value: 'TYPE_FAN' },
            { text: 'Promo', value: 'TYPE_PROMO' },
            { text: 'Postal', value: 'TYPE_POSTAL' },
          ]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Object Count / Available
        </v-subheader>
        <div class="documents-wrap">
          <v-text-field
            v-model="object.objectCountAvailable"
            type="number"
            single-line
            outlined
            dense
            hide-details
          />
        </div>
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Rating Count / Average
        </v-subheader>
        <div class="documents-wrap">
          <v-text-field
            v-model="object.rating.ratingAvg"
            single-line
            readonly
            outlined
            dense
            hide-details
          />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Owner ID
        </v-subheader>
        <v-text-field
          v-model="object.user.id"
          single-line
          outlined
          dense
          hide-details
          :readonly="object.status.toLowerCase() === 'pending' || object.status.toLowerCase() === 'accepted'"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Object Owner
        </v-subheader>
        <v-text-field
          :value="object?.user.firstname ? object.user.firstname + ' ' + object.user.lastname : 'N/A'"
          single-line
          readonly
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          E-mail
        </v-subheader>
        <v-text-field
          v-model="object.user.email"
          single-line
          readonly
          outlined
          dense
          hide-details
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'

  export default {
    mixins: [validationMixin],
    props: {
      objectData: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        object: {
          title: '',
          status: '',
          flag: '',
          type: {
            name: '',
          },
          objectCountAvailable: '',
          rating: {
            ratingTotal: '',
            ratingAvg: '',
          },
          user: {
            id: '',
            firstname: '',
            lastname: '',
            email: '',
          },
          id: '',
        },
      }
    },
    watch: {
      object: {
        deep: true,
        handler () {
          this.$emit('updateObject', this.object)
        },
      },
    },
    mounted () {
      if (this.objectData) {
        this.object = this.objectData
      }
    },
  }
</script>
