<template>
  <v-container
    fluid
    class="pa-0 item-details-page"
  >
    <v-card
      v-if="user"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit User #${user.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="triggerSave"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
            @click="$router.push('/users/user-accounts')"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />
      <v-tabs
        v-model="tab"
        class="px-6 py-4"
      >
        <v-tab
          v-for="item in tabs"
          :key="item.name"
        >
          {{ item.name }}
        </v-tab>
        <v-tabs-items
          v-model="tab"
          class="pt-6 pb-2"
        >
          <v-tab-item
            v-for="item in tabs"
            :key="item.name"
          >
            <component
              :is="item.component"
              :user="user"
              @emitChanges="getChanges"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-snackbar
        v-model="saveConfirmation.show"
        :color="saveConfirmation.type === 'success' ? '#4db6ac' : '#EF5350'"
        :timeout="2000"
        v-bind="{
          top: true,
          center: true
        }"
      >
        <p class="text-h3 ma-0">
          {{ saveConfirmation.message }}
        </p>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="saveConfirmation = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-divider />
      <div class="user_manage-accounts__footer px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="triggerSave"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
            @click="$router.push('/users/user-accounts')"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    components: {
      UserTab: () => import('@/components/user-details/UserTab'),
      CredentialsTab: () => import('@/components/user-details/CredentialsTab'),
      PaymentTab: () => import('@/components/user-details/PaymentTab'),
      RequestsTab: () => import('@/components/user-details/RequestsTab'),
      ObjectsTab: () => import('@/components/user-details/ObjectsTab'),
      NoteTab: () => import('@/components/user-details/NoteTab'),
      DiscountTab: () => import('@/components/user-details/DiscountTab'),
      ConversationsTab: () => import('@/components/user-details/ConversationsTab'),
      RatingsTab: () => import('@/components/user-details/RatingsTab'),
      WebSMSTab: () => import('@/components/user-details/WebSMSTab'),
    },

    data () {
      return {
        user: null,
        tabs: [
          { name: 'User', component: 'UserTab' },
          { name: 'Credentials', component: 'CredentialsTab' },
          { name: 'Payment', component: 'PaymentTab' },
          { name: 'Requests', component: 'RequestsTab' },
          { name: 'Objects', component: 'ObjectsTab' },
          { name: 'Notes', component: 'NoteTab' },
          { name: 'Discount', component: 'DiscountTab' },
          { name: 'Conversations', component: 'ConversationsTab' },
          { name: 'Rating', component: 'RatingsTab' },
          { name: 'WebSMS', component: 'WebSMSTab' },
        ],
        tab: null,
        toggleSave: false,
        tabsData: {},
        saveConfirmation: { show: false, type: '' },
      }
    },

    computed: {
      ...mapState({
        users: state => state.users.users,
      }),
    },

    watch: {
      users: {
        deep: true,
        handler (newVal) {
          const currentUser = newVal.find(el => el.id == this.$route.params.id)
          if (currentUser) {
            this.user = currentUser
          }
        },
      },
    },

    created () {
      if (this.$route.query.activeTab) {
        this.tab = this.tabs.findIndex(el => el.name.toLowerCase() === this.$route.query.activeTab.toLowerCase())
      }
      this.fetchUserById(this.$route.params.id)
    },

    methods: {
      ...mapActions({
        editUser: 'editUser',
        addUser: 'addUser',
        fetchUserById: 'fetchUserById',
      }),
      async triggerSave () {
        const editedUser = {}

        editedUser.id = this.user.id
        editedUser.firstname = this.user.firstname
        editedUser.lastname = this.user.lastname
        editedUser.email = this.user.email
        editedUser.phone = this.user.phone
        editedUser.status = this.user.status
        editedUser.idleCount = this.user.idleCount
        editedUser.role = this.user.role
        editedUser.personalInsurance = this.user.personalInsurance

        const editedTabs = Object.keys(this.tabsData)

        if (editedTabs.length) {
          editedTabs.forEach(tab => {
            const tabData = this.tabsData[tab]
            if (typeof tabData === 'object') {
              Object.keys(tabData).forEach(key => {
                if (typeof tabData[key] === 'object') {
                  editedUser[key] = {}
                  Object.keys(tabData[key]).forEach(el => {
                    editedUser[key][el] = tabData[key][el]
                  })
                } else {
                  editedUser[key] = tabData[key]
                }
              })
            } else {
              editedUser[tab] = tabData
            }
          })
        }

        if ('vatSubject' in editedUser && !editedUser.vatSubject) {
          editedUser.vat = ''
        }

        await this.editUser(editedUser)
          .then(() => {
            this.saveConfirmation = { show: true, type: 'success', message: 'Saved successfully' }
            this.tabsData = {
              user: {
                subscribeEmail: this.user.subscriber?.emailSubscriber ? 1 : 0,
              },
            }
          })
          .catch(err => {
            this.saveConfirmation = { show: true, type: 'error', message: err.message }
          })
        setTimeout(() => {
          this.saveConfirmation.show = false
        }, 2000)
      },
      getChanges (data) {
        if (!this.tabsData[data.tabName] && !data.remove) {
          this.tabsData[data.tabName] = data.data
        } else if (typeof this.tabsData[data.tabName] === 'object') {
          if (data.remove && this.tabsData[data.tabName][data.remove]) {
            delete this.tabsData[data.tabName][data.remove]
            return
          }
          Object.keys(data.data).forEach(key => {
            if (this.tabsData[data.tabName][key] && typeof this.tabsData[data.tabName][key] === 'object') {
              Object.keys(data.data[key]).forEach(el => {
                this.tabsData[data.tabName][key][el] = data.data[key][el]
              })
            } else {
              this.tabsData[data.tabName][key] = data.data[key]
            }
          })
        }
      },
    },
  }
</script>

<style lang="scss">

</style>
