<template>
  <div class="table-wrapper">
    <v-alert
      v-if="showAlert"
      :type="alertType"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="notesHeaders"
      :items="notesObj"
      item-key="id"
      hide-default-footer
    >
      <template v-slot:body.prepend>
        <tr>
          <td
            colspan="6"
            class="py-4 text-right"
          >
            <v-btn
              color="teal lighten-2"
              class="ma-0"
              @click="addNote"
            >
              <v-icon
                left
                color="#fff"
              >
                mdi-plus
              </v-icon>
              Add Note
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:item.note="{ item }">
        <v-text-field
          v-model="item.note"
          single-line
          outlined
          dense
          hide-details
        />
      </template>
      <template v-slot:item.created="{ item }">
        <v-menu
          ref="menu"
          v-model="item.created.menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.created.value"
              single-line
              outlined
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="item.created.value"
          />
        </v-menu>
      </template>
      <template
        v-for="(noteObj, index) in notesObj"
        v-slot:item.created_by
      >
        <v-text-field
          :key="index"
          v-model="notesObj[index].created_by"
          single-line
          outlined
          dense
          hide-details
        />
      </template>
      <template v-slot:item.reminder_date="{ item }">
        <v-menu
          ref="menu"
          v-model="item.reminder_date.menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.reminder_date.value"
              single-line
              outlined
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="item.reminder_date.value"
          />
        </v-menu>
      </template>
      <template v-slot:item.reminder_time="{ item }">
        <v-menu
          ref="menu"
          v-model="item.reminder_time.menu"
          :close-on-content-click="false"
          transition="scale-transition"
          left
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.reminder_time.value"
              single-line
              outlined
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-model="item.reminder_time.value"
            format="24hr"
            @click:minute="$refs.menu.save(item.reminder_time.value)"
          />
        </v-menu>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="table-actions">
          <v-btn
            color="teal lighten-2"
            elevation="0"
            class="action-edit"
            @click="save(item)"
          >
            <v-icon left>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn
            color="red"
            elevation="0"
            class="action-btn"
            @click="remove(item)"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import axios from '@/plugins/api'

  export default {
    props: {
      object: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        menu: false,
        notesObj: [],
        notesHeaders: [
          { text: 'Note', value: 'note', align: 'center', sortable: false },
          { text: 'Created', value: 'created', align: 'center', sortable: false },
          { text: 'Created By', value: 'created_by', align: 'center', sortable: false },
          { text: 'Reminder Date', value: 'reminder_date', align: 'center', sortable: false },
          { text: 'Reminder Time', value: 'reminder_time', align: 'center', sortable: false },
          { text: 'Action', value: 'action', align: 'center', sortable: false },
        ],

        alertMessage: '',
        alertType: 'success',
        showAlert: false,
      }
    },

    mounted () {
      this.loadNotes()
    },

    methods: {
      noteAction (val, note) {
        this.$refs[`select-${note.id}`].blur()
        this.$refs[`select-${note.id}`].internalValue = []
      },
      addNote () {
        this.notesObj.push(
          {
            object_id: this.object.id,
            id: null,
            note: '',
            created: {
              value: null,
              menu: false,
            },
            created_by: this.object.user.id,
            reminder_date: {
              value: null,
              menu: false,
            },
            reminder_time: {
              value: null,
              menu: false,
            },
          },
        )
      },
      loadNotes () {
        const _this = this
        axios.get(`admin/product/notes/${this.object.id}`)
          .then(response => {
            _this.notesObj = response.data.data.map(note => {
              return {
                object_id: note.product_id,
                id: note.id,
                note: note.note,
                created: {
                  value: note.created_at,
                  menu: false,
                },
                created_by: note.created_by,
                reminder_date: {
                  value: note.reminder_date,
                  menu: false,
                },
                reminder_time: {
                  value: note.reminder_time,
                  menu: false,
                },
              }
            })
          })
      },
      save (item) {
        const index = this.notesObj.indexOf(item)
        const note = {
          product_id: this.object.id,
          note: this.notesObj[index].note,
          created: this.notesObj[index].created.value,
          created_by: this.notesObj[index].created_by,
          reminder_date: this.notesObj[index].reminder_date.value,
          reminder_time: this.notesObj[index].reminder_time.value,
        }

        let url = '/admin/product/notes/create'
        if (this.notesObj[index].id) {
          url = `/admin/product/notes/update/${this.notesObj[index].id}`
        }

        const _this = this
        axios.post(url, note)
          .then(response => {
            _this.alertMessage = response.data.message
            _this.alertType = 'success'
            _this.showAlert = true
            setTimeout(() => {
              _this.showAlert = false
            }, 5000)
            _this.loadNotes()
          })
          .catch(error => {
            _this.alertMessage = error.response.data.message
            _this.alertType = 'error'
            _this.showAlert = true
            setTimeout(() => {
              _this.showAlert = false
            }, 5000)
          })
      },
      remove (item) {
        const index = this.notesObj.indexOf(item)
        if (!this.notesObj[index].id) {
          this.notesObj.splice(index, 1)
        } else {
          if (!confirm('Are you sure you want to delete this note?')) {
            return
          }

          axios.get('/admin/product/notes/delete/' + this.notesObj[index].id)
            .then(response => {
              this.notesObj.splice(index, 1)
              this.alertMessage = response.data.message
              this.alertType = 'success'
              this.showAlert = true
              setTimeout(() => {
                this.showAlert = false
              }, 5000)
            })
            .catch(error => {
              this.alertMessage = error.response.data.message
              this.alertType = 'error'
              this.showAlert = true
              setTimeout(() => {
                this.showAlert = false
              }, 5000)
            })
        }
      },
    },
  }
</script>

<style lang="sass">

</style>
