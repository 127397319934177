<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="#fff"
    height="65"
    class="header"
  >
    <v-toolbar-title
      class="font-weight-light"
      v-text="$route.name"
    />
    <v-spacer />
    <div
      v-if="this.$store.state.auth.isAuthenticated && myData"
      class="logout-toggler"
    >
      <v-dialog
        v-model="showNotifications"
        width="380"
        hide-overlay
        transition="slide-y-transition"
        content-class="logout-dialog user-menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="user-btn ml-2 v-size--large"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-bell
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <div
            class="user-menu__content"
          >
            <v-list
              v-for="notification in myData.notifications"
              :key="notification.id"
            >
              <router-link
                :to="{ name: 'Edit Object', params: { id: notification.object_id } }"
                target="_blank"
              >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-bell-outline
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ `${notification.object} #${notification.object_id}` }}</v-list-item-title>
                    <v-list-item-subtitle>{{ notification.message }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </v-list>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <div
      v-if="this.$store.state.auth.isAuthenticated && myData"
      class="logout-toggler"
    >
      <v-dialog
        v-model="showLogout"
        width="380"
        hide-overlay
        transition="slide-y-transition"
        content-class="logout-dialog user-menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="user-btn ml-2 v-size--large"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <span class="user-greeting">
              <span>Hi,</span>
              {{ myData.firstname }}
            </span>
            <span class="user-btn__icon">{{ myData.firstname.slice(0,1) }}</span>
          </v-btn>
        </template>
        <v-card>
          <div
            class="user-menu__header"
            :style="{ backgroundImage: 'url(' + require('@/assets/images/backgrounds/user-menu-header-bg.jpg') + ')' }"
          >
            <div
              v-if="myData.avatar"
              class="user-menu__avatar"
              :style="{ backgroundImage: 'url(' + myData.avatar + ')' }"
            />
            <div
              v-else
              class="user-menu__noimage"
            >
              {{ myData.firstname.slice(0,1) }}
            </div>
            <v-list-item-content>
              <v-list-item-title>
                {{ myData.firstname }}
              </v-list-item-title>
            </v-list-item-content>
          </div>
          <div class="user-menu__content">
            <v-list>
              <router-link :to="{ name: 'Edit User', params: { id: myData.id } }">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-calendar-account-outline
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>My Profile</v-list-item-title>
                    <v-list-item-subtitle>Account settings and more</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </router-link>
            </v-list>
          </div>
          <div class="user-menu__footer">
            <v-card-actions class="d-flex">
              <v-btn
                class="m-0"
                color="teal lighten-2"
                small
                @click="logoutUser"
              >
                Sign Out
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </v-app-bar>
</template>

<script>
  // Components

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      showLogout: false,
      showNotifications: false,
    }),

    computed: {
      ...mapState({
        drawer: 'drawer',
        myData: state => state.auth.myData,
      }),
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
        logout: 'LOG_OUT',
      }),
      logoutUser () {
        this.logout()
        this.showLogout = false
        this.$router.push('/login')
      },
    },
  }
</script>
