import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Main
        {
          name: 'Welcome',
          path: '',
          component: () => import('@/views/Welcome'),
        },
        // Dashboard
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/user/UserProfile'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Categories',
          path: 'categories',
          component: () => import('@/views/dashboard/pages/category/Index'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
        // Users block
        {
          name: 'Manage Users',
          path: 'users/user-accounts',
          component: () => import('@/views/dashboard/pages/user/UserAccounts'),
        },
        {
          name: 'Subscribers',
          path: 'users/user-subscribers',
          component: () => import('@/views/dashboard/pages/user/UserSubscribers'),
        },
        {
          name: 'Edit Subscriber',
          path: 'users/user-subscribers/:id',
          component: () => import('@/views/dashboard/pages/user/UserSubscribersDetails'),
        },
        {
          name: 'User Export',
          path: 'users/user-export',
          component: () => import('@/views/dashboard/pages/user/UserExport'),
        },
        {
          name: 'User Selection',
          path: 'users/user-export/:id',
          component: () => import('@/views/dashboard/pages/user/UserExportDetails'),
        },
        {
          name: 'Risky Leasers',
          path: 'users/risky-leasers',
          component: () => import('@/views/dashboard/pages/user/RiskyLeasers'),
        },
        {
          name: 'Ratings',
          path: 'users/user-ratings',
          component: () => import('@/views/dashboard/pages/user/UserRatings'),
        },
        {
          name: 'Edit Rating',
          path: 'users/user-ratings/:id',
          component: () => import('@/views/dashboard/pages/user/UserRatingsDetails'),
        },
        {
          name: 'Edit User',
          path: 'users/user-accounts/:id',
          component: () => import('@/views/dashboard/pages/user/UserDetails'),
        },
        {
          name: 'Undefined Addresses',
          path: 'users/undefined-addresses',
          component: () => import('@/views/dashboard/pages/user/UndefinedAddresses'),
        },
        {
          name: 'Undefined Address',
          path: 'users/undefined-addresses/:id',
          component: () => import('@/views/dashboard/pages/user/UndefinedAddressesDetails'),
        },
        // Objects block
        {
          name: 'Manage Objects',
          path: 'objects',
          component: () => import('@/views/dashboard/pages/object/ObjectListing'),
        },
        {
          name: 'Edit Object',
          path: 'objects/edit-object/:id',
          component: () => import('@/views/dashboard/pages/object/ObjectDetails'),
        },
        {
          name: 'Object Keywords',
          path: '/object-keywords',
          component: () => import('@/views/dashboard/pages/object/ObjectKeywords'),
        },
        {
          name: 'Create Keyword',
          path: '/object-keywords/create',
          component: () => import('@/views/dashboard/pages/object/ObjectKeywordsDetails'),
        },
        {
          name: 'Edit Keyword',
          path: '/object-keywords/:id',
          component: () => import('@/views/dashboard/pages/object/ObjectKeywordsDetails'),
        },
        {
          name: 'Object Ratings',
          path: '/object-ratings',
          component: () => import('@/views/dashboard/pages/object/ObjectRatings'),
        },
        {
          name: 'Edit Object Rating',
          path: '/object-ratings/:id',
          component: () => import('@/views/dashboard/pages/object/ObjectRatingsDetails'),
        },
        {
          name: 'Type Settings',
          path: '/type-settings',
          component: () => import('@/views/dashboard/pages/object/TypeSettings'),
        },
        {
          name: 'Object Types',
          path: '/object-types',
          component: () => import('@/views/dashboard/pages/object/ObjectTypes'),
        },
        {
          name: 'Object Favourites',
          path: '/object-favourites',
          component: () => import('@/views/dashboard/pages/object/ObjectFavourites'),
        },
        {
          name: 'Collections',
          path: '/collections',
          component: () => import('@/views/dashboard/pages/object/Collections'),
        },
        // Requests block
        {
          name: 'Rentals',
          path: '/rentals',
          component: () => import('@/views/dashboard/pages/request/Rentals'),
        },
        {
          name: 'Edit Rental',
          path: '/rentals/:id',
          component: () => import('@/views/dashboard/pages/request/RentalsDetails'),
        },
        {
          name: 'Requests',
          path: '/requests',
          component: () => import('@/views/dashboard/pages/request/Requests'),
        },
        {
          name: 'Edit Request',
          path: '/requests/:id',
          component: () => import('@/views/dashboard/pages/request/RequestsDetails'),
        },
        {
          name: 'Test',
          path: '/test',
          component: () => import('@/views/dashboard/Test'),
        },
        {
          name: 'Posts',
          path: '/blog-posts',
          component: () => import('@/views/dashboard/pages/blog/Posts.vue'),
        },
        {
          name: 'CreatePost',
          path: '/blog-posts/posts/create',
          component: () => import('@/views/dashboard/pages/blog/Details.vue'),
        },
        {
          name: 'EditPost',
          path: '/blog-posts/posts/edit/:id',
          component: () => import('@/views/dashboard/pages/blog/Details.vue'),
        },
        {
          name: 'BlogCategories',
          path: '/blog-categories',
          component: () => import('@/views/dashboard/pages/blog/Categories.vue'),
        },
        {
          name: 'CreateBlogCategory',
          path: '/blog-categories/create',
          component: () => import('@/views/dashboard/pages/blog/CategoryDetails.vue'),
        },
        {
          name: 'EditBlogCategory',
          path: '/blog-categories/edit/:id',
          component: () => import('@/views/dashboard/pages/blog/CategoryDetails.vue'),
        },
        {
          name: 'BlogComments',
          path: '/blog-comments',
          component: () => import('@/views/dashboard/pages/blog/Comments.vue'),
        },
        {
          name: 'CreateBlogComment',
          path: '/blog-comments/create',
          component: () => import('@/views/dashboard/pages/blog/CommentDetails.vue'),
        },
        {
          name: 'EditBlogComment',
          path: '/blog-comments/edit/:id',
          component: () => import('@/views/dashboard/pages/blog/CommentDetails.vue'),
        },

        // 404 Page - Must be last child
        {
          path: '*',
          component: () => import('@/views/dashboard/pages/404'),
        },
      ],
    },
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/Login'),
    },
  ],
})
