<template>
  <v-container
    fluid
    class="pa-0 item-details-page"
  >
    <v-card
      v-if="user"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit Subscriber #${user.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />
      <div class="pt-6 pb-4 px-6">
        <div class="row">
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                User ID
              </v-subheader>
              <v-text-field
                v-model="user.id"
                single-line
                type="number"
                readonly
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Street Name
              </v-subheader>
              <v-text-field
                v-model="user.streetName"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Street Number
              </v-subheader>
              <v-text-field
                v-model="user.streetNumber"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Town/City
              </v-subheader>
              <v-text-field
                v-model="user.townCity"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Zip Code
              </v-subheader>
              <v-text-field
                v-model="user.zipCode"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Latitude
              </v-subheader>
              <v-text-field
                v-model="user.latitude"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                Longitude
              </v-subheader>
              <v-text-field
                v-model="user.longitude"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="user_manage-accounts__footer px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        user: {
          id: '',
          streetName: 'Donald Trump Circus',
          streetNumber: '5a',
          townCity: 'Albisrieden',
          zipCode: '8047',
          latitude: '47.372033',
          longitude: '8.491753',
        },
      }
    },
    created () {
      if (this.$route.params.id) {
        this.user.id = this.$route.params.id
      }
    },
  }
</script>

<style lang="scss">

</style>
