<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    created () {
      if (this.$store.state.auth.expirationDate && this.$store.state.auth.expirationDate < new Date().getTime()) {
        this.$store.dispatch('logout')
        this.$router.push('/login')
      }
    },
  }
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400&display=swap');

.v-application * {
  font-family: 'Poppins', 'Roboto', sans-serif;
  letter-spacing: .3px;
}
</style>
