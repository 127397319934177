<template>
  <v-container
    fluid
    class="pa-0 item-details-page"
  >
    <v-card
      v-if="category"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          Category Details
        </h2>
      </v-card-actions>
      <v-divider />
      <div class="pt-6 pb-4 px-6">
        <div class="row">
          <div class="col-md-6">
            <div class="input-wrap">
              <v-subheader class="input-label">
                Title
              </v-subheader>
              <v-text-field
                v-model="category.title"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="input-wrap">
              <v-subheader class="input-label">
                slug
              </v-subheader>
              <v-text-field
                v-model="category.slug"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="user_manage-accounts__footer px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="save"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  import swal from 'sweetalert2'

  export default {
    name: 'CategoryDetails',
    data () {
      return {
        category: {
          id: null,
          title: '',
          slug: '',
          error: null,
        },
      }
    },
    watch: {
      'category.title' (val) {
        this.category.slug = val.trim().split(' ').join('-')
      },
    },
    mounted () {
      this.category.id = this.$route.params.id
      if (this.category.id) {
        this.loadCategoryData()
      }
    },
    methods: {
      loadCategoryData () {
        axios.get('/admin/blog/posts/categories/show/' + this.category.id).then((response) => {
          const category = response.data

          this.category = {
            id: category.id,
            title: category.title,
            slug: category.slug,
            error: null,
          }
        }).catch((error) => {
          this.category.error = error
        })
      },
      save () {
        if (this.category.id) {
          this.update()
        } else {
          this.create()
        }
      },
      update () {
        const _this = this
        axios.post('/admin/blog/posts/categories/edit', {
          ...this.category,
        }).then(function (response) {
          swal.fire({
            title: 'Success',
            text: 'Category updated successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          })

          _this.loadCategoryData()
        }).catch(function (error) {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
      },
      create () {
        axios.post('/admin/blog/posts/categories/create', {
          ...this.category,
        }).then(function (response) {
          swal.fire({
            title: 'Success',
            text: 'Category created successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          })
        }).catch(function (error) {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
      },
    },
  }
</script>
