<template>
  <div class="row">
    <div class="col-md-6">
      <div
        class="input-wrap"
        :class="{ 'input-error': ibanError }"
      >
        <v-subheader class="input-label">
          IBAN
        </v-subheader>
        <v-text-field
          v-model="ibanNumber"
          v-mask="ibanMask"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('ibanNumber')"
        />
        <div
          v-if="ibanError"
          class="input-error-container"
        >
          <p
            v-if="ibanError"
            class="error-message"
          >
            {{ ibanMask.length > 2 && ibanMask.length > 0 ? `Should contain ${ibanMask.replaceAll(' ', '').length} characters` : 'Not valid IBAN' }}
          </p>
        </div>
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Personal E-Banking Limit
        </v-subheader>
        <v-text-field
          v-model="persLimit"
          type="number"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('persLimit')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Personal Overdue Period
        </v-subheader>
        <v-text-field
          v-model="persOverdue"
          type="number"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('persOverdue')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Payment E-mail
        </v-subheader>
        <v-text-field
          v-model="paymentEmail"
          type="email"
          readonly
          single-line
          outlined
          dense
          hide-details
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Other Beneficiary First Name
        </v-subheader>
        <v-text-field
          v-model="ibanFirstName"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('ibanFirstName')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Other Beneficiary Last Name
        </v-subheader>
        <v-text-field
          v-model="ibanLastname"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('ibanLastname')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Other Beneficiary Company Name
        </v-subheader>
        <v-text-field
          v-model="ibanCorporatename"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('ibanCorporatename')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Other Beneficiary Street Name
        </v-subheader>
        <v-text-field
          v-model="ibanStreet"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('ibanStreet')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Other Beneficiary Street Number
        </v-subheader>
        <v-text-field
          v-model="ibanStreetnumber"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('ibanStreetnumber')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Other Beneficiary Zip
        </v-subheader>
        <v-text-field
          v-model="ibanZip"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('ibanZip')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Other Beneficiary Town/City
        </v-subheader>
        <v-text-field
          v-model="ibanCity"
          single-line
          outlined
          dense
          hide-details
          @input="emitChanges('ibanCity')"
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Other Beneficiary Country
        </v-subheader>
        <v-select
          v-model="ibanCountry"
          :items="[{ text: 'Switzerland', value: 'CH' }, { text: 'Liechtenstein', value: 'LI' }]"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          @change="emitChanges('ibanCountry')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        paymentEmail: '',
        ibanNumber: '',
        persLimit: '',
        persOverdue: '',
        ibanFirstName: '',
        ibanLastname: '',
        ibanStreet: '',
        ibanStreetnumber: '',
        ibanZip: '',
        ibanCity: '',
        ibanCountry: '',
        ibanError: false,
        ibanCorporatename: '',
      }
    },
    computed: {
      ibanMask () {
        if (this.ibanNumber.length < 2) {
          return 'AA'
        } else {
          switch (this.ibanNumber.slice(0, 2).toUpperCase()) {
            // Switzerland
            case 'CH':
              return 'AA## #### #### #### #### #'
            // Liechtenstein
            case 'LI':
              return 'AA## #### #### #### #### #'
            // Andorra
            case 'AD':
              return 'AA## #### #### #### #### ####'
            // Austria
            case 'AT':
              return 'AA## #### #### #### ####'
            // Belgium
            case 'BE':
              return 'AA## #### #### ####'
            // Bulgaria
            case 'BG':
              return 'AA## NNNN #### #### #### ##'
            // Croatia
            case 'HR':
              return 'AA## #### #### #### #### #'
            // Cyprus
            case 'CY':
              return 'AA## #### #### #### #### #### ####'
            // Czech
            case 'CZ':
              return 'AA## #### #### #### #### ####'
            // Denmark
            case 'DK':
              return 'AA## #### #### #### ##'
            // Estonia
            case 'EE':
              return 'AA## #### #### #### ####'
            // Finland
            case 'FI':
              return 'AA## #### #### #### ##'
            // France
            case 'FR':
              return 'AA## #### #### #### #### #### ###'
            // Germany
            case 'DE':
              return 'AA## #### #### #### #### ##'
            // Gibraltar
            case 'GI':
              return 'AA## NNNN #### #### #### ###'
            // Greece
            case 'GR':
              return 'AA## #### #### #### #### #### ###'
            // Hungary
            case 'HU':
              return 'AA## #### #### #### #### #### ####'
            // Iceland
            case 'IS':
              return 'AA## #### #### #### #### #### ##'
            // Ireland
            case 'IE':
              return 'AA## NNNN #### #### #### ##'
            // Italy
            case 'IT':
              return 'AA## N### #### #### #### #### ###'
            // Latvia
            case 'LV':
              return 'AA## NNNN #### #### #### #'
            // Lithuania
            case 'LT':
              return 'AA## #### #### #### ####'
            // Luxembourg
            case 'LU':
              return 'AA## #### #### #### ####'
            // Malta
            case 'MT':
              return 'AA## NNNN #### #### #### #### #### ###'
            // Monaco
            case 'MC':
              return 'AA## #### #### #### #### #### ###'
            // Netherlands
            case 'NL':
              return 'AA## NNNN #### #### ##'
            // Norway
            case 'NO':
              return 'AA## #### #### ###'
            // Poland
            case 'PL':
              return 'AA## #### #### #### #### #### ####'
            // Portugal
            case 'PT':
              return 'AA## #### #### #### #### ##'
            // Romania
            case 'RO':
              return 'AA## NNNN #### #### #### ####'
            // San Marino
            case 'SM':
              return 'AA## N### #### #### #### #### ###'
            // Slovakia
            case 'SK':
              return 'AA## #### #### #### #### ####'
            // Slovenia
            case 'SI':
              return 'AA## #### #### #### ###'
            // Spain
            case 'ES':
              return 'AA## #### #### #### #### ####'
            // Sweden
            case 'SE':
              return 'AA## #### #### #### #### ####'
            // United Kingdom
            case 'GB':
              return 'AA## NNNN #### #### #### ##'
            // Holy See
            case 'VA':
              return 'AA## #### #### #### #### ##'
            default:
              return 'AA'
          }
        }
      },
    },
    created () {
      if (this.user) {
        this.paymentEmail = this.user.iban?.paymentEmail || ''
        this.ibanNumber = this.user.iban?.ibanNumber || ''
        this.ibanFirstName = this.user.iban?.ibanFirstName || ''
        this.ibanLastname = this.user.iban?.ibanLastname || ''
        this.persLimit = this.user.iban?.persLimit || ''
        this.persOverdue = this.user.iban?.persOverdue || ''
        this.ibanStreet = this.user.iban?.ibanStreet || ''
        this.ibanStreetnumber = this.user.iban?.ibanStreetnumber || ''
        this.ibanZip = this.user.iban?.ibanZip || ''
        this.ibanCity = this.user.iban?.ibanCity || ''
        this.ibanCountry = this.user.iban?.ibanCountry || ''
        this.ibanCorporatename = this.user.iban?.ibanCorporatename || ''
      }
    },
    methods: {
      emitChanges (field) {
        const data = {
          tabName: 'payment',
          data: {
            iban: {},
          },
        }
        if (field === 'ibanNumber') {
          if (this.ibanNumber.length && this.ibanNumber.length === this.ibanMask.length) {
            data.data.iban.ibanNumber = this.ibanNumber.toUpperCase().replaceAll(' ', '')
            this.ibanError = false
          } else if (!this.ibanNumber.length) {
            this.ibanError = false
          } else {
            this.ibanError = true
          }
        } else {
          data.data.iban[field] = this[field]
        }
        this.$emit('emitChanges', data)
      },
    },
  }
</script>
