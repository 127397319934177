var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 container--fluid"},[_c('v-card',{staticClass:"ma-0"},[_c('div',{staticClass:"table-filters"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg col-md-3"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Rental ID ")]),_c('v-text-field',{attrs:{"type":"number","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterRentalId),callback:function ($$v) {_vm.filterRentalId=$$v},expression:"filterRentalId"}})],1),_c('div',{staticClass:"col-lg col-md-3"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Request ID ")]),_c('v-text-field',{attrs:{"single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterRequestId),callback:function ($$v) {_vm.filterRequestId=$$v},expression:"filterRequestId"}})],1),_c('div',{staticClass:"col-lg col-md-3"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Object Type ")]),_c('v-select',{attrs:{"items":[
              { name: 'Default', value: 'default' },
              { name: 'Fan', value: 'fan' },
              { name: 'Promo', value: 'promo' },
              { name: 'Postal', value: 'postal' },
            ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterObjectType),callback:function ($$v) {_vm.filterObjectType=$$v},expression:"filterObjectType"}})],1),_c('div',{staticClass:"col-lg col-md-3"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Rental Status ")]),_c('v-select',{attrs:{"items":[
              { name: 'Open', value: 'open' },
              { name: 'Closed', value: 'closed' },
            ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterRentalStatus),callback:function ($$v) {_vm.filterRentalStatus=$$v},expression:"filterRentalStatus"}})],1),_c('div',{staticClass:"col-lg col-md-3"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Request Status ")]),_c('v-select',{attrs:{"items":[
              { name: 'Aborted', value: 'aborted' },
              { name: 'Pending', value: 'pending' },
              { name: 'Declined', value: 'declined' },
              { name: 'Expired', value: 'expired' },
              { name: 'Deleted', value: 'deleted' },
              { name: 'accepted', value: 'cancelled by renter' },
              { name: 'Cancelled by Leaser', value: 'cancelled by leaser' },
              { name: 'Closed', value: 'closed' },
            ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterRequestStatus),callback:function ($$v) {_vm.filterRequestStatus=$$v},expression:"filterRequestStatus"}})],1),_c('div',{staticClass:"col-lg col-md-3"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Payment Type ")]),_c('v-select',{attrs:{"items":[
              { name: 'Credit Card', value: 'credit card' },
              { name: 'E-Banking', value: 'e-banking' },
            ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterPaymentType),callback:function ($$v) {_vm.filterPaymentType=$$v},expression:"filterPaymentType"}})],1),_c('div',{staticClass:"col-lg col-md-3"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Assignee ")]),_c('v-select',{attrs:{"items":[
              { name: 'Admin', value: 'admin' },
              { name: 'Super Admin', value: 'super admin' },
              { name: 'System', value: 'system' },
            ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterAssignee),callback:function ($$v) {_vm.filterAssignee=$$v},expression:"filterAssignee"}})],1),_c('div',{staticClass:"col-lg col-md-3"},[_c('v-subheader',{staticClass:"input-label"},[_vm._v(" Task Status ")]),_c('v-select',{attrs:{"items":[
              { name: 'Pending', value: 'pending' },
              { name: 'Active', value: 'active' },
              { name: 'Done', value: 'done' },
            ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filterTaskStatus),callback:function ($$v) {_vm.filterTaskStatus=$$v},expression:"filterTaskStatus"}})],1)]),_c('div',{staticClass:"table-filters__controls"},[_c('v-btn',{attrs:{"color":"teal lighten-2","medium":""}},[_vm._v(" Apply ")]),_c('v-btn',{attrs:{"color":"red","medium":""},on:{"click":_vm.clearFilters}},[_vm._v(" Reset ")])],1)]),_c('div',{staticClass:"table-wrapper px-6 py-3"},[(_vm.tableObjects)?_c('v-data-table',{directives:[{name:"columns-resizable",rawName:"v-columns-resizable"}],ref:"objecttable",staticClass:"fixed-columns-table",attrs:{"page":_vm.currentPage,"headers":_vm.objectHeaders,"items":_vm.tableObjects,"item-key":"id","loading":_vm.loading,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 20, 50, 100],
        },"items-per-page":_vm.itemsPerPage,"fixed-header":true},on:{"update:page":function($event){_vm.currentPage=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"page-count":_vm.pageCount},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No data ")]},proxy:true},{key:"item.action",fn:function({ item }){return [_c('div',{staticClass:"table-actions"},[_c('v-select',{ref:`select-${item.id}`,staticClass:"action-select",attrs:{"append-icon":"","items":[
                { text: 'Show Info PDF', value: 'Show Info PDF' },
                { text: 'Conversation', value: 'Conversation' },
                { text: 'Decline Request', value: 'Decline Request' },
                { text: 'Delete Request', value: 'Delete Request' },
              ],"filled":"","color":"teal","light":"","dense":"","single-line":""},on:{"change":function($event){return _vm.objectAction($event, item)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-cog-outline ")])]},proxy:true}],null,true)}),_c('v-btn',{staticClass:"action-edit",attrs:{"color":"teal lighten-2","elevation":"0"},on:{"click":function($event){return _vm.objectAction('edit object', item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"action-edit",attrs:{"color":"red lighten-1","elevation":"0"},on:{"click":function($event){return _vm.deleteRequest(item.requestId)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")])],1)],1)]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"table-pagination",attrs:{"length":_vm.pageCount,"total-visible":"7"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})]},proxy:true}],null,false,1750993535),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)]),_c('v-snackbar',{attrs:{"color":_vm.alertColor,"timeout":"3500"},model:{value:(_vm.showAlertMessage),callback:function ($$v) {_vm.showAlertMessage=$$v},expression:"showAlertMessage"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }