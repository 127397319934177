<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
            @click="createCommentPage"
          >
            Create a new comment
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="comments"
          ref="comments"
          :page.sync="currentPage"
          :headers="commentHeaders"
          :items="comments"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100],
          }"
          :items-per-page.sync="itemsPerPage"
          :fixed-header="true"
          @page-count="pageCount"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              color="teal lighten-2"
              elevation="0"
              class="action-edit"
              small
              @click="$router.push('/blog-comments/edit/' + item.id)"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              color="red"
              elevation="0"
              class="action-delete"
              small
              @click="deleteComment(item.id)"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn
              color="blue"
              elevation="0"
              class="action-show"
              small
              @click="showPost(item.post)"
            >
              <v-icon left>
                mdi-note
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-snackbar
      v-model="showMessage"
      :color="mesageColor"
      timeout="3500"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'
  import moment from 'moment'

  export default {
    name: 'Comments',
    data () {
      return {
        showMessage: false,
        message: '',
        mesageColor: 'success',
        comments: [
          {
            id: '',
            status: '',
            user: '',
            post: '',
            createdAt: '',
            content: '',
          },
        ],
        commentHeaders: [
          { text: 'ID', value: 'id' },
          { text: 'Status', value: 'status' },
          { text: 'User', value: 'user' },
          { text: 'Post', value: 'post' },
          { text: 'Created at', value: 'createdAt' },
          { text: 'Content', value: 'content' },
          { text: 'Action', value: 'action' },
        ],
        currentPage: 1,
        itemsPerPage: 10,
        pageCount: 1,
        nextPage: null,
        prevPage: null,
      }
    },
    watch: {
      currentPage: {
        handler () { this.loadComments() },
      },
      itemsPerPage: {
        handler () { this.loadComments() },
      },
    },
    mounted () {
      this.loadComments()
    },
    methods: {
      deleteComment (id) {
        const _this = this
        axios.get('/admin/blog/posts/comments/delete/' + id).then(function (response) {
          _this.message = response.data.message
          _this.mesageColor = 'success'
          _this.showMessage = true

          _this.loadComments()
        }).catch(function (error) {
          _this.message = error.response.data
          _this.mesageColor = 'error'
          _this.showMessage = true
        })
      },
      loadComments () {
        const _this = this
        const filters = {
          page: this.currentPage,
          perPage: this.itemsPerPage,
        }

        axios.get('/admin/blog/posts/comments', { params: filters }).then(function (response) {
          const data = response.data

          const total = data.total
          _this.pageCount = Math.ceil(total / _this.itemsPerPage)
          if (_this.currentPage > _this.pageCount) {
            _this.currentPage = _this.pageCount
          }

          _this.comments = data.comments.map(function (comment) {
            return {
              id: comment.id,
              status: comment.status,
              user: comment.user,
              post: comment.post,
              createdAt: moment(comment.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
              // Show only 50 characters of the content
              content: comment.content.length > 50 ? comment.content.substring(0, 50) + '...' : comment.content,
            }
          })
        }).catch(function (error) {
          _this.message = error.response.data
          _this.mesageColor = 'error'
          _this.showMessage = true
        })
      },
      createCommentPage () {
        this.$router.push('/blog-comments/create')
      },
      showPost (id) {
        this.$router.push('/blog-posts/posts/edit/' + id)
      },
    },
  }
</script>

<style scoped lang="scss">
</style>
